import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import { Messages } from "components/messages/Messages";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNotifications } from "store/notifications";
import { useIsMounted } from "helpers/useIsMounted";
import { LoadingButton } from "@mui/lab";
import { useUserProfile } from "store/userprofile";
import { isProduction } from "helpers/utils";

import Terms from "../pages/termsAndConditions/Terms";

const notificationsContext = "T&CDialog";

export default function TermsAndConditionsDialog({
  user,
  linkSx = { color: "common.white" },
  standalone = false,
}) {
  const { userProfile, updateProfile } = useUserProfile();
  const isMounted = useIsMounted();

  const [dialogOpen, setDialogOpen] = useState(false);
  const { clearByContext } = useNotifications();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { t } = useTranslation();

  const closeDialog = () => {
    // clear any lingering error messages
    clearByContext(notificationsContext);
    if (isMounted) {
      setDialogOpen(false);
    }
  };

  const updateProfileTermsAndConditions = async () => {
    setIsSubmitting(true);
    try {
      await updateProfile(user.uid, {
        ...userProfile,
        termsAndConditionsAccepted: true,
      });
    } catch (error) {
      if (!isProduction) console.log(error);
    } finally {
      setIsSubmitting(false);
      closeDialog();
    }
  };

  useEffect(() => {
    if (
      !!userProfile &&
      "termsAndConditionsAccepted" in userProfile &&
      !userProfile?.termsAndConditionsAccepted
    ) {
      setDialogOpen(true);
    }
  }, [userProfile]);

  return (
    <Dialog
      open={standalone || dialogOpen}
      onClose={() => null}
      maxWidth="md"
      fullWidth
    >
      <>
        <DialogContent>
          <Messages context={notificationsContext} />
          <Stack spacing={1} mt={2}>
            <Terms />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Stack
            direction="row"
            justifyContent="flex-end"
            display="flex"
            flex={1}
          >
            <LoadingButton
              onClick={updateProfileTermsAndConditions}
              loading={isSubmitting}
              type="submit"
              variant="contained"
            >
              {t("Offer.Accept")}
            </LoadingButton>
          </Stack>
        </DialogActions>
      </>
    </Dialog>
  );
}
