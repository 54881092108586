import { enUS, et, ru, fi } from "date-fns/locale";
import { format } from "date-fns";

export const datesMaskMap = {
    en: "__/__/____",
    et: "__.__.____",
    ru: "__.__.____",
    fi: "__.__.____",
};

export const timesMaskMap = {
    en: "__:__",
    et: "__:__",
    ru: "__:__",
    fi: "__:__",
};

export const dateTimesMaskMap = {
    en: `${datesMaskMap.en} ${timesMaskMap.en}`,
    et: `${datesMaskMap.et} ${timesMaskMap.et}`,
    ru: `${datesMaskMap.ru} ${timesMaskMap.ru}`,
    fi: `${datesMaskMap.fi} ${timesMaskMap.fi}`,
};

export const localeMap = {
    en: enUS,
    et,
    ru,
    fi,
};

export const languages = [
    { value: "en", label: "English" },
    { value: "et", label: "Eesti" },
    { value: "ru", label: "Русский" },
    { value: "fi", label: "Suomeksi" },
];

// see formats: https://date-fns.org/v2.21.3/docs/format
export const formatDateTime = (date, language, formatString = "PP") => {
    if (!date) {
        return;
    }
    if (typeof date === "string") {
        date = new Date(date);
    }

    if (typeof date === "object" && date.toDate) {
        date = date.toDate();
    }

    try {
        return format(date, formatString, {
            locale: localeMap[language],
        });
    } catch (err) {
        console.error(err);
    }
    return "-";
};

export const formatLongDate = (date, language = 'en', formatString = "PP") =>
    formatDateTime(date, language, formatString);
