import { quantityUnits } from "helpers/options";
import * as Yup from "yup";

export const QuantitySchema = Yup.object({
  amount: Yup.number()
    .positive("Validation.RequiredField")
    .required("Validation.RequiredField")
    .typeError("Validation.NumbersOnly")
    .default(0),
  unit: Yup.string().required().default(quantityUnits[0].value),
});
