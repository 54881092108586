import {
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Autocomplete,
} from "@mui/material";
import AppsIcon from "@mui/icons-material/Apps";
import ReorderIcon from "@mui/icons-material/Reorder";
import LocationField from "components/forms/LocationField";
import Select from "components/forms/Select";
import TextField from "components/forms/TextField";
import { getTranslatedOptions, locationFilterRadiuses } from "helpers/options";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNotifications } from "store/notifications";
import { AddressSchema } from "store/schemas/AddressSchema";
import { useWasteOffers } from "../store/wasteoffers";
import { useWasteTypes } from "../store/wastetypes";
import { useWasteViews, VIEW_TYPES } from "../store/wasteviews";
import { useLocation } from "react-router-dom";
import { fetchLocation } from "components/forms/LocationField.helpers";
import { readFromLocalStorage, saveToLocalStorage } from "helpers/localStorage";

export const WasteOffersFilter = ({ closed }) => {
  const { filter, setFilter } = useWasteOffers();
  const { view, setView } = useWasteViews();
  const { wasteTypes } = useWasteTypes();
  const { setError } = useNotifications();
  const [wasteTypesList, setWasteTypesList] = useState(() =>
    filter?.wasteType.length
      ? Array.from(wasteTypes.values()).filter((obj) =>
          filter.wasteType.includes(obj.id)
        )
      : []
  );
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const params = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    return {
      t: searchParams.get("t"),
      l: searchParams.get("l"),
      r: searchParams.get("r"),
    };
  }, [location]);

  useEffect(() => {
    if (wasteTypes.size) {
      if (params.t) {
        const typeFound = Array.from(wasteTypes.values()).find(
          (o) => o.id === params.t
        );
        if (typeFound) {
          setFilter("wasteType", [params.t]);
          setWasteTypesList([typeFound]);
        }
      }

      if (params.l) {
        try {
          const lat = parseFloat(params.l.split(",")[0]);
          const lon = parseFloat(params.l.split(",")[1]);
          if (lat >= -90 && lat <= 90 && lon >= -180 && lon <= 180) {
            (async () => {
              const res = await fetchLocation({
                lat,
                lon,
              });
              if (res.success) {
                setFilter("location", res.data);
              }
            })();
          }
        } catch (err) {
          console.error(err);
        }
      }

      if (params.l && params.r) {
        if (
          locationFilterRadiuses.find((r) => r.value === parseInt(params.r))
        ) {
          setFilter("radius", params.r);
        }
      }
    }
  }, [params, wasteTypes, setFilter]);

  const getFilterHandler = (name) => (e) => {
    setFilter(name, e.target.value);
  };

  const handleChangeLocation = (location) => {
    if (!location) {
      setFilter("location", "");
      return;
    }

    AddressSchema.validate(location)
      .then(() => setFilter("location", location))
      .catch((err) => setError(t(`Error.SearchLocation`)));
  };

  const wasteTypeOptions = [...Array.from(wasteTypes.values())];

  const radiusOptions = getTranslatedOptions(locationFilterRadiuses, t);

  useEffect(() => {
    if (wasteTypesList.length < 11) {
      setFilter(
        "wasteType",
        wasteTypesList.map((i) => i.id)
      );
    } else {
      setWasteTypesList((prev) =>
        [...prev].filter(
          (i) => i.id !== wasteTypesList[wasteTypesList.length - 1].id
        )
      );
    }
  }, [wasteTypesList, setFilter]);

  useEffect(() => {
    const localView = readFromLocalStorage("offerView");
    if (localView) {
      setView(localView);
    }
  }, [setView]);

  const offerTypeOptions = [
    {
      label: "OfferTypes.All",
      value: "all",
    },
    {
      label: "OfferTypes.Sell",
      value: "sell",
    },
    {
      label: "OfferTypes.Buy",
      value: "buy",
    },
  ];

  return (
    <>
      <Stack
        direction="row"
        spacing={2}
        sx={{
          flex: "1 0 auto",
          flexWrap: "wrap",
          alignItems: "center",
          mb: 2,
          justifyContent: "flex-end",
        }}
      >
        {!closed && (
          <ToggleButtonGroup
            value={view}
            exclusive
            sx={{ pt: 1 }}
            onChange={(e, v) => {
              if (v !== null) {
                setView(v);
                saveToLocalStorage("offerView", v);
              }
            }}
          >
            <ToggleButton value={VIEW_TYPES.CARDS}>
              <AppsIcon />
            </ToggleButton>
            <ToggleButton value={VIEW_TYPES.TABLE}>
              <ReorderIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        )}
        <Select
          label="Common.ByOfferType"
          value={filter.offerType}
          onChange={getFilterHandler("offerType")}
          options={offerTypeOptions}
          fullWidth={false}
          sx={{ minWidth: 160, mt: 1 }}
        />
        <Autocomplete
          multiple
          options={wasteTypeOptions}
          getOptionLabel={(option) => option[`${i18n.language}_label`]}
          filterSelectedOptions
          sx={{ minWidth: 280 }}
          value={wasteTypesList}
          onChange={(e, v) => setWasteTypesList(v)}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                variant="outlined"
                label="Common.ByWasteType"
                margin="normal"
                fullWidth
              />
            );
          }}
        />
        <LocationField
          label="Common.ByLocation"
          onChange={handleChangeLocation}
          value={filter.location}
          fullWidth={false}
          sx={{ minWidth: 280, pt: 1 }}
          disableClearable={!filter.location}
          t={t}
          language={i18n.language}
        />
        {filter.location ? (
          <Select
            label="Radiuses.Title"
            onChange={getFilterHandler("radius")}
            value={filter.radius}
            options={radiusOptions}
            fullWidth={false}
            sx={{ width: 120, mt: 1 }}
          />
        ) : null}
      </Stack>
    </>
  );
};
