import { MAvatar } from "minimal/components/@material-extend";
import createAvatar from "minimal/utils/createAvatar";
import { useUserProfile } from "store/userprofile";

export default function MyAvatar({ ...other }) {
  const { userProfile } = useUserProfile();

  if (!userProfile) {
    return null;
  }

  const { fullName, pictures } = userProfile;
  const photoURL = pictures?.src;

  return (
    <MAvatar
      src={photoURL}
      alt={fullName}
      color={photoURL ? "default" : createAvatar(fullName).color}
      {...other}
    >
      {createAvatar(fullName).name}
    </MAvatar>
  );
}
