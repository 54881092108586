import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import FormField from "components/forms/FormField";
import SwitchWithLabel from "components/forms/SwitchWithLabel";
import TextField from "components/forms/TextField";
import { Messages } from "components/messages/Messages";
import {
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
} from "helpers/auth";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNotifications } from "store/notifications";
import { SignInSchema } from "store/schemas/SignInSchema";
import { useIsMounted } from "helpers/useIsMounted";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import { PasswordResetSchema } from "store/schemas/PasswordReset";

const defaultValues = SignInSchema.cast();
const notificationsContext = "signInDialog";

export default function SignInDialog({
  linkSx = { color: "common.white" },
  standalone = false,
}) {
  const navigate = useNavigate();
  const isMounted = useIsMounted();

  const [dialogOpen, setDialogOpen] = useState(false);
  const { setInfo, setError, clearByContext } = useNotifications();
  const [isResetPassword, setResetPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { control, handleSubmit, reset } = useForm({
    defaultValues,
    resolver: yupResolver(
      isResetPassword ? PasswordResetSchema : SignInSchema,
      {
        stripUnknown: true,
        abortEarly: false,
      }
    ),
  });

  const { t } = useTranslation();

  const setErrorInDialog = (name, data) => {
    setError(t(name, data), { context: notificationsContext });
  };

  const openDialog = () => {
    setResetPassword(false);
    setDialogOpen(true);
  };

  const closeDialog = () => {
    // clear any lingering error messages
    clearByContext(notificationsContext);
    if (isMounted) {
      setDialogOpen(false);
    }
    reset();
  };

  const handleSignIn = async (data) => {
    try {
      const cred = await signInWithEmailAndPassword(
        data.email,
        data.password,
        data.rememberMe
      );
      if (cred.user.emailVerified) {
        setInfo(t("SignIn.Welcome"));
      }
      closeDialog();
      navigate("/offers");
    } catch (err) {
      setErrorInDialog("Error.SignIn", {
        message: t(`FirebaseErrors.${err.code}`),
      });
    }
  };

  const handleResetPassword = async (email) => {
    try {
      await sendPasswordResetEmail(email);
      setInfo(t("ResetPassword.Success"));
      closeDialog();
    } catch (err) {
      setErrorInDialog("ResetPassword.Error");
    }
  };

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    if (isResetPassword) {
      await handleResetPassword(data.email);
      setIsSubmitting(false);
      return;
    }
    if (data.password?.length) {
      await handleSignIn(data);
      setIsSubmitting(false);
      return;
    }
  };

  const handleToggleResetPassword = () => {
    setResetPassword(!isResetPassword);
  };

  return (
    <>
      {standalone ? null : (
        <Button variant="text" onClick={openDialog} sx={linkSx}>
          {t("SignIn.Login")}
        </Button>
      )}
      <Dialog
        open={standalone || dialogOpen}
        onClose={closeDialog}
        maxWidth="xs"
        fullWidth
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>
            <Typography>
              {t(isResetPassword ? "SignIn.ResetPassword" : "SignIn.Login")}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Messages context={notificationsContext} />
            <Stack spacing={1} mt={2}>
              <FormField
                name="email"
                control={control}
                label="Contact.Email"
                Component={TextField}
              />
              {!isResetPassword ? (
                <FormField
                  name="password"
                  control={control}
                  label="SignIn.Password"
                  type="password"
                  Component={TextField}
                />
              ) : null}
              {!isResetPassword ? (
                <FormField
                  name="rememberMe"
                  control={control}
                  label="SignIn.RememberMe"
                  Component={SwitchWithLabel}
                />
              ) : null}
            </Stack>
          </DialogContent>
          <DialogActions>
            <Stack
              direction="row"
              justifyContent={isResetPassword ? "flex-end" : "space-between"}
              display="flex"
              flex={1}
            >
              {!isResetPassword ? (
                <Button onClick={handleToggleResetPassword}>
                  {t(
                    isResetPassword
                      ? "SignIn.ResetPassword"
                      : "SignIn.ForgotPassword"
                  )}
                </Button>
              ) : null}
              <LoadingButton
                loading={isSubmitting}
                type="submit"
                variant="contained"
              >
                {t(isResetPassword ? "SignIn.ResetPassword" : "SignIn.Login")}
              </LoadingButton>
            </Stack>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
