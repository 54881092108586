import { createWithDevtools } from "./store.helpers";

const initialState = {
    loading: {},
    loaded: {},
    store: {},
    errors: {},
};

const dataStore = (set, get) => {
    return {
        ...initialState,
        getHandlers: (name) => ({
            startLoading: () =>
                set(function startLoading(draft) {
                    draft.loading[name] = true;
                }),
            endLoading: ({ data = null, error = null } = {}) =>
                set(function endLoading(draft) {
                    delete draft.loading[name];
                    draft.loaded[name] = true;
                    if (data) {
                        draft.store[name] = data;
                    }
                    if (error) {
                        draft.errors[name] = error;
                    }
                }),
            hasLoaded: () => !!get().loaded[name],
            isLoading: () => !!get().loading[name],
        }),
    };
};

export const isLoadingSelector = (state) =>
    Object.keys(state.loading).length > 0;

export const useDataStore = createWithDevtools(dataStore);
