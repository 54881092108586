import moment from "moment";
import { formatLongDate } from "./locale";

const formattedDate = (date) => formatLongDate(date, "en", "PPp");

const momentFormat = "LLL";

const isDateABeforeDateB = (dateA, dateB) => {
  const dateBDate = moment(moment(formattedDate(dateB), momentFormat));
  const dateADate = moment(moment(formattedDate(dateA), momentFormat));
  const utcOffset = dateBDate.utcOffset();
  const localTimeToUtcOffset = moment(
    dateADate.utc(false).utcOffset(utcOffset),
    momentFormat
  );
  return moment(localTimeToUtcOffset).isBefore(dateBDate);
};

const isLessThan5MinutesBefore = (dateA, dateB) => {
  const momentExpiresAt = moment(moment(formattedDate(dateB), momentFormat));
  const dateADate = moment(moment(formattedDate(dateA), momentFormat));
  const momentExpiresAtUTCOffset = momentExpiresAt.utcOffset();
  const momentBidCreatedAt = moment(
    dateADate.utc(false).utcOffset(momentExpiresAtUTCOffset),
    momentFormat
  );

  const diffInMinutes = momentExpiresAt.diff(momentBidCreatedAt, "minutes");

  if (diffInMinutes < 5) return true;
  return false;
};

export { isDateABeforeDateB, isLessThan5MinutesBefore };
