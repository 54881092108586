import { Box, Paper } from "@mui/material";
import { filterItemsByContext, useNotifications } from "store/notifications";
import { MessagesBanner } from "./Messages.Banner";

export const Messages = ({ context = "general", ...rest }) => {
  const items = useNotifications(filterItemsByContext(context));

  if (!items.length) {
    return null;
  }

  return (
    <Box pb={2} {...rest}>
      <Paper>
        {items.map((message) => {
          return <MessagesBanner key={message.text} message={message} />;
        })}
      </Paper>
    </Box>
  );
};
