import { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import { Card } from "@mui/material";
import { getComparator, stableSort } from "helpers/sort";
import { useEffect } from "react";
import BidsTableHead from "./BidsTableHead";
import BidsTableRow from "./BidsTableRow";
import BidsTableToolBar from "./BidsTableToolbar";
import { useTranslation } from "react-i18next";

export default function BidsTable({ title, bids }) {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("created");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [originalRows, setOriginalRows] = useState([]);
  const [rows, setRows] = useState([]);
  const [searched, setSearched] = useState("");
  const { t } = useTranslation();

  const getHighestBids = (list) =>
    list.reduce(function (prev, current) {
      return prev.amount > current.amount ? prev : current;
    });

  useEffect(() => {
    const rowsMap = {};

    for (const b of bids) {
      const rowObj = {
        id: b.id,
        offerId: b.offerId,
        offer: b.offerName,
        created: b.createdAt.toDateString(),
        status: b.status,
        amount: b.amount,
        currency: b.currencyCode,
      };

      if (b.offerName in rowsMap) {
        rowsMap[b.offerName].push(rowObj);
      } else {
        rowsMap[b.offerName] = [rowObj];
      }
    }

    let temp = [];

    for (const key in rowsMap) {
      const highest = getHighestBids(rowsMap[key]);
      highest["children"] = rowsMap[key];
      temp.push(highest);
    }

    setOriginalRows(temp);
    setRows(temp);
  }, [bids]);

  const requestSearch = (searchedVal) => {
    const filteredRows = originalRows.filter((row) => {
      return row.offer.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setRows(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Card>
      <BidsTableToolBar title={title} />
      <TableContainer>
        <Table size="medium">
          <BidsTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {stableSort(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <BidsTableRow key={`bidcell-${row.id}`} row={row} />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={rows.length > 50 ? [50, 100, 200] : []}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Card>
  );
}
