import * as Yup from "yup";
import {  } from "constants/priceInputFieldSettings";

export const PriceSchema = (currencyCode) => Yup.object({
  amount: Yup.number()
    .positive("Validation.RequiredField")
    .typeError("Validation.NumbersOnly")
    .required("Validation.RequiredField"),
  usePercentage: Yup.boolean().default(false),
  currencyCode: Yup.string().when("usePercentage", {
    is: true,
    then: Yup.string().required().default(currencyCode ?? "FOB RDAM"),
    otherwise: Yup.string().required().default("EUR"),
  }),
  delivery: Yup.string().required().default("DAP"),
  currencyValue: Yup.number().when("usePercentage", {
    is: true,
    then: Yup.number()
      .positive("Validation.RequiredField")
      .typeError("Validation.NumbersOnly")
      .required("Validation.RequiredField"),
  }),
});
