import { useMemo, useCallback, useState } from "react";
import { GridContainer, GridItem } from "../layout/Helpers";
import FormField from "./FormField";
import Select from "./Select";
import { useTranslation } from "react-i18next";
import TextField from "./TextField";
import { getOptionsMap, quantityUnits } from "helpers/options";
import { useTheme } from "@mui/material/styles";
import SwitchWithLabel from "components/forms/SwitchWithLabel";
import { Badge, Popover, Button, Typography } from "@mui/material";
import {
  PERCENTAGES,
  CURRENCIES,
  DELIVERY_OPTIONS,
} from "constants/priceInputFieldSettings";

export const PriceInputField = ({
  control,
  context = null,
  labelContext = "Price",
  noDelivery = false,
  sharedOffer = false,
  quantity = { quantity: 0, unit: "" },
  withPercentage = false,
  usePercentage = false,
  startingPrice,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const fieldContext = context ? `${context}.` : "";
  const quantityLabel = useMemo(() => {
    const units = getOptionsMap(quantityUnits, t);
    return `${t(units.get(quantity.unit)?.label) || ""} | max ${
      quantity.quantity
    }`;
  }, [quantity, t]);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const theCurrencyCode = useMemo(() => {
    if (usePercentage && startingPrice?.currencyCode) {
      return PERCENTAGES.filter(
        (value) => value.value === startingPrice?.currencyCode
      );
    }

    return usePercentage ? PERCENTAGES : CURRENCIES;
  }, [startingPrice?.currencyCode, usePercentage]);

  const hasStartingPrice = !!startingPrice;

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const getSize = useCallback(
    (size, toRemove) => {
      return withPercentage ? size - toRemove : size;
    },
    [withPercentage]
  );

  return (
    <GridContainer spacing={1} alignItems="center">
      {withPercentage && (
        <GridItem xs={4} sm={2}>
          <FormField
            label="UsePercentage"
            name={`${fieldContext}usePercentage`}
            Component={SwitchWithLabel}
            control={control}
          />
        </GridItem>
      )}
      <GridItem
        xs={
          sharedOffer && quantity.quantity && quantity.unit
            ? getSize(
                usePercentage && !hasStartingPrice ? 4 : 8,
                usePercentage && !hasStartingPrice ? 2 : 4
              )
            : getSize(
                usePercentage && !hasStartingPrice ? 6 : 12,
                usePercentage && !hasStartingPrice ? 2 : 4
              )
        }
        sm={
          sharedOffer && quantity.quantity && quantity.unit
            ? getSize(
                usePercentage && !hasStartingPrice ? 2 : 4,
                usePercentage && !hasStartingPrice ? 1 : 2
              )
            : getSize(
                usePercentage && !hasStartingPrice ? 4 : 8,
                usePercentage && !hasStartingPrice ? 1 : 2
              )
        }
      >
        <FormField
          name={`${fieldContext}amount`}
          control={control}
          label={`${labelContext}.${usePercentage ? "Percentage" : "Amount"}`}
          Component={TextField}
          focus={"true"}
          isNumeric
          inputPropsAdditional={
            usePercentage
              ? {
                  max: 100,
                  min: 0.001,
                  pattern: "([0-9]*[.])?[0-9]+",
                  step: "any",
                }
              : {}
          }
        />
      </GridItem>
      {usePercentage && !hasStartingPrice && (
        <GridItem
          xs={
            sharedOffer && quantity.quantity && quantity.unit
              ? getSize(usePercentage ? 4 : 8, usePercentage ? 2 : 4)
              : getSize(usePercentage ? 6 : 12, usePercentage ? 2 : 4)
          }
          sm={
            sharedOffer && quantity.quantity && quantity.unit
              ? getSize(usePercentage ? 2 : 4, usePercentage ? 1 : 2)
              : getSize(usePercentage ? 4 : 8, usePercentage ? 1 : 2)
          }
        >
          <Badge
            badgeContent={
              <Button color="error" onClick={handleClick}>
                {t("README")}
              </Button>
            }
            color="warning"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
          >
            <FormField
              name={`${fieldContext}currencyValue`}
              control={control}
              label={`${labelContext}.CurrentPrice`}
              Component={TextField}
              focus={"true"}
              isNumeric
            />
          </Badge>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Typography
              sx={{ p: 2 }}
              bgcolor={theme.palette.warning.main}
              color={theme.palette.secondary.dark}
            >
              {t("ContactFor%Price")}
            </Typography>
          </Popover>
        </GridItem>
      )}
      {sharedOffer && quantity.quantity && quantity.unit ? (
        <GridItem xs={4}>
          <FormField
            name={`${fieldContext}quantity`}
            control={control}
            translatedLabel={quantityLabel}
            Component={TextField}
            isNumeric
            inputPropsAdditional={
              quantity?.quantity
                ? {
                    max: quantity.quantity,
                    min: 0.001,
                    pattern: "([0-9]*[.])?[0-9]+",
                    step: "any",
                  }
                : {}
            }
          />
        </GridItem>
      ) : null}
      <GridItem sm={noDelivery ? 4 : 2} xs={6}>
        <FormField
          key={`${usePercentage}-currency-code`}
          name={`${fieldContext}currencyCode`}
          control={control}
          label={`${labelContext}.${
            usePercentage ? "CurrencyIndex" : "Currency"
          }`}
          options={theCurrencyCode}
          Component={Select}
        />
      </GridItem>
      {!noDelivery && (
        <GridItem xs={6} sm={2}>
          <FormField
            name={`${fieldContext}delivery`}
            control={control}
            label={`${labelContext}.Delivery`}
            options={DELIVERY_OPTIONS}
            Component={Select}
          />
        </GridItem>
      )}
    </GridContainer>
  );
};
