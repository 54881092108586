export const getShortAddress = (locationOption) => {
    return getNormalAddress(locationOption, true);
};

export const getNormalAddress = (option, short = false) => {
    const parts = [];
    const { address, type } = option;
    const { road, house_number, postcode, city, country, suburb } =
        address || {};

    let shortAddress = [];

    const title = type && address[type] ? address[type] : null;

    if (road) {
        parts.push(road);
        if (house_number) {
            parts.push(" ");
            parts.push(house_number);
        }
        parts.push(", ");
    }

    if (suburb) {
        parts.push(suburb);
        parts.push(", ");
    }

    if (city) {
        parts.push(city);
        if (postcode) {
            parts.push(" ");
            parts.push(postcode);
        }
        parts.push(", ");
        shortAddress.push(city);
    }

    if (country) {
        parts.push(country);
        shortAddress.push(country);
    }

    if (short) {
        return { label: shortAddress.join(", "), title };
    }

    return { label: parts.join(""), title };
};
