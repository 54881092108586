import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useUserProfile } from "store/userprofile";
import { useAuth } from "../../../helpers/auth";
import { Sidebar } from "../../components/Sidebar";
import TermsAndConditionsDialog from "../../components/TermsAndConditionsDialog";
import { MainStyle, RootStyle } from "./DashBoard.styles";
import { useWasteTypes } from "../../store/wastetypes";
import { WasteNav } from "../../WasteNav";
import { WasteOffersRouter } from "./WasteOffersRouter";

export default function Dashboard() {
  const [open, setOpen] = useState(false);
  const { user, role } = useAuth();
  const { fetchProfile } = useUserProfile();
  const { fetchTypes } = useWasteTypes();
  const { i18n } = useTranslation();

  useEffect(() => {
    if (user) {
      fetchTypes(i18n.language);
      fetchProfile(user.uid);
    }
  }, [fetchProfile, fetchTypes, i18n.language, user]);

  if (!user) {
    return null;
  }

  return (
    <RootStyle>
      <WasteNav handleOpenSidebar={() => setOpen(true)} />
      <Sidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
      <TermsAndConditionsDialog user={user} />
      <MainStyle>
        <WasteOffersRouter user={user} role={role} />
      </MainStyle>
    </RootStyle>
  );
}
