import { db } from "../../helpers/firebase";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { createWithDevtools } from "../../store/store.helpers";

const wasteTypesCollection = collection(db, "wastetypes");

export const fetchWasteTypes = async (lang) => {
    try {
        const querySnapshot = await getDocs(
            query(wasteTypesCollection, orderBy("value"))
        );
        let results = [];
        querySnapshot.forEach((requestDoc) => {
            const data = requestDoc.data();
            const langFallback =
                data[`${lang}_label`] === undefined
                    ? data.en_label
                    : data[`${lang}_label`];
            results.push({
                id: requestDoc.id,
                ...data,
                value: requestDoc.id,
                label: `${data.value} ${String(langFallback)}`,
            });
        });
        return results;
    } catch (err) {
        console.error(err);
        return Promise.reject(err.code);
    }
};

const wasteTypes = (set) => {
    return {
        wasteTypes: new Map(),
        fetchTypes: async (lang) => {
            const types = await fetchWasteTypes(lang);
            set(function setWasteTypes(draft) {
                for (const type of types) {
                    draft.wasteTypes.set(type.id, type);
                }
            });
        },
    };
};

export const useWasteTypes = createWithDevtools(wasteTypes);
