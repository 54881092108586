import { useState } from "react";
import { Box, CardMedia } from "@mui/material";
import { CarouselControls } from "./CarouselControls";

export const WasteOfferCarousel = ({ wasteOffer, sx = {} }) => {
  const images = wasteOffer?.images || [];
  const [modalOpen, setModalOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    if (currentIndex < images.length - 1) {
      return setCurrentIndex(currentIndex + 1);
    }
    setCurrentIndex(0);
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      return setCurrentIndex(currentIndex - 1);
    }
    setCurrentIndex(images.length - 1);
  };

  const modalDisabled = !images.length;

  const toggleModal = () => {
    if (!modalDisabled) {
      setModalOpen(!modalOpen);
    }
  };

  const placeHolder = "/static/placeholder.svg";

  return (
    <Box sx={{ position: "relative" }}>
      {images.length > 1 ? (
        <CarouselControls
          index={currentIndex}
          total={images.length}
          onNext={handleNext}
          onPrevious={handlePrevious}
        />
      ) : null}
      <CardMedia
        component="img"
        src={images[currentIndex] || placeHolder}
        onClick={toggleModal}
        sx={{
          ...sx,
          cursor: !modalDisabled ? "zoom-in" : "default",
        }}
      />
    </Box>
  );
};
