import { MenuItem } from "@mui/material";
import { Controller } from "react-hook-form";
import { FIELD_TYPE } from "../../constants/enums.js";
import omit from "lodash/omit";

export default function FormField({ name, control, Component, ...rest }) {
  if (!Component) {
    throw new Error("Component is a required prop!");
  }

  return (
    <Controller
      name={name}
      defaultValue={rest.defaultValue}
      control={control}
      render={({ field, formState: { errors } }) => {
        const componentProps =
          rest.type === FIELD_TYPE.FILE
            ? { ...rest }
            : omit({ ...rest }, "acceptFiles");
        return (
          <Component
            {...field}
            {...componentProps}
            errors={errors}
            value={field.value || ""}
          >
            {rest.select
              ? rest.options.map((option) => {
                  return (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  );
                })
              : null}
          </Component>
        );
      }}
    />
  );
}
