import { useCallback } from "react";

import { useDropzone } from "react-dropzone";
import { Icon } from "@iconify/react";
import fileFill from "@iconify/icons-eva/file-fill";
import closeFill from "@iconify/icons-eva/close-fill";
import { motion, AnimatePresence } from "framer-motion";
import prettyBytes from "pretty-bytes";

import { experimentalStyled as styled } from "@mui/material/styles";

import {
    Box,
    List,
    Link,
    Button,
    ListItem,
    Typography,
    ListItemIcon,
    ListItemText,
    ListItemSecondaryAction,
    LinearProgress,
} from "@mui/material";
import { varFadeInRight } from "./animate";
import { MIconButton } from "./@material-extend";
import { useTranslation } from "react-i18next";

const DropZoneStyle = styled("div")(({ theme }) => ({
    outline: "none",
    display: "flex",
    textAlign: "center",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    padding: theme.spacing(5, 1),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.neutral,
    border: `1px dashed ${theme.palette.grey[500_32]}`,
    "&:hover": {
        opacity: 0.72,
        cursor: "pointer",
    },
    [theme.breakpoints.up("md")]: {
        textAlign: "left",
        flexDirection: "row",
        maxHeight: "180px",
    },
}));

export const UploadMultiple = ({
    accept,
    caption,
    progress,
    uploadTasks,
    disabled,
    error = false,
    value: files,
    onChange: setFiles,
    sx,
    ...other
}) => {
    const { t } = useTranslation();

    const hasFile = files.length > 0;

    const handleDrop = useCallback(
        (acceptedFiles) => {
            setFiles(acceptedFiles);
        },
        [setFiles]
    );

    const handleRemoveAll = () => {
        setFiles([]);
    };

    const handleRemoveFile = (file) => {
        const newFiles = [...files];
        newFiles.splice(newFiles.indexOf(file), 1);
        setFiles(newFiles);
    };

    const { getRootProps, getInputProps, isDragActive, isDragReject } =
        useDropzone({
            onDrop: handleDrop,
        });

    return (
        <Box sx={{ width: "100%", ...sx }} {...other}>
            <DropZoneStyle
                {...getRootProps()}
                sx={{
                    ...(isDragActive && { opacity: 0.72 }),
                    ...((isDragReject || error) && {
                        color: "error.main",
                        borderColor: "error.light",
                        bgcolor: "error.lighter",
                    }),
                }}
            >
                <input
                    {...getInputProps()}
                    disabled={disabled}
                    accept={accept}
                />

                <Box
                    component="img"
                    alt="select file"
                    src="/static/illustrations/illustration_upload.svg"
                    sx={{ height: 130, padding: 0 }}
                />

                <Box
                    sx={{
                        p: 3,
                        ml: { md: 2 },
                        padding: 0,
                    }}
                >
                    <Typography gutterBottom variant="h5">
                        {t("Uploads.Title")}
                    </Typography>

                    {caption ? (
                        <Typography
                            variant="body2"
                            sx={{ color: "text.secondary" }}
                        >
                            {caption}
                        </Typography>
                    ) : (
                        <Typography
                            variant="body2"
                            sx={{ color: "text.secondary" }}
                        >
                            {t("Uploads.DropFilesHere")}&nbsp;
                            <Link underline="always">
                                {t("Uploads.OrClickHere")}
                            </Link>
                            &nbsp;{t("Uploads.ToSelectFromYourDevice")}
                        </Typography>
                    )}
                </Box>
            </DropZoneStyle>

            <List disablePadding sx={{ ...(hasFile && { my: 5 }) }}>
                <AnimatePresence>
                    {files.map((file) => {
                        const fileProgress = progress.get(file.name);
                        return (
                            <Box
                                key={file.name}
                                component={motion.div}
                                {...varFadeInRight}
                                sx={{
                                    my: 1,
                                    py: 0.5,
                                    px: 2,
                                    borderRadius: 1,
                                    padding: 0,
                                    border: (theme) =>
                                        `solid 1px ${theme.palette.divider}`,
                                    bgcolor: "background.paper",
                                }}
                            >
                                <ListItem>
                                    <ListItemIcon>
                                        <Icon
                                            icon={fileFill}
                                            width={32}
                                            height={32}
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={file.name}
                                        secondary={prettyBytes(file.size)}
                                        primaryTypographyProps={{
                                            variant: "subtitle2",
                                        }}
                                    />
                                    <ListItemSecondaryAction>
                                        <MIconButton
                                            edge="end"
                                            size="small"
                                            disabled={
                                                Number(fileProgress) > 0 &&
                                                Number(fileProgress) < 100
                                            }
                                            onClick={() =>
                                                handleRemoveFile(file)
                                            }
                                        >
                                            <Icon icon={closeFill} />
                                        </MIconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                                {fileProgress ? (
                                    <LinearProgress
                                        variant="determinate"
                                        value={fileProgress}
                                    />
                                ) : null}
                            </Box>
                        );
                    })}
                </AnimatePresence>
            </List>

            {hasFile && (
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        "& > *": { ml: 1.5 },
                    }}
                >
                    <Button onClick={handleRemoveAll} sx={{ mr: 1.5 }}>
                        {t("Uploads.RemoveAll")}
                    </Button>
                </Box>
            )}
        </Box>
    );
};
