import * as Yup from "yup";

export const SignInSchema = Yup.object({
    email: Yup.string()
        .trim()
        .email("Validation.MustBeValidEmail")
        .required("Validation.RequiredField")
        .default(""),
    password: Yup.string().required().default(""),
    rememberMe: Yup.boolean().default(true),
});
