import { FIELD_TYPE, FORM_FIELD_NAME, OFFER_TYPE } from "../enums";
import AdditionalInfoField from "../map";

export const getWasteWoodChipSawDust = () => [
  new AdditionalInfoField({
    id: "yCytlv2UURI8ebQdUrTL",
    name: FORM_FIELD_NAME.WASTE_WOOD_CLASS,
    label: "WasteWoodClass",
    type: FIELD_TYPE.SELECT,
    defaultValue: "MZ5KX5lQunnRGUMPA9vc",
    options: [
      {
        id: "MZ5KX5lQunnRGUMPA9vc",
        label: "A",
      },
      {
        id: "YNIRn4bHUwkbn5uGOSOn",
        label: "A+B",
      },
      {
        id: "APpm3pJsryrV3IdZOeCP",
        label: "B",
      },
      {
        id: "6rgU5jIrRQqXYvjLglTf",
        label: "C",
      },
    ],
    conditionalOptions: [
      {
        optionId: "MZ5KX5lQunnRGUMPA9vc",
        name: FORM_FIELD_NAME.WASTE_WOOD_CODE,
        label: "WasteWoodCode",
        type: FIELD_TYPE.SELECT,
        defaultValue: "x8hYZ1sa85xfViAZ7VrP",
        options: [
          {
            id: "x8hYZ1sa85xfViAZ7VrP",
            label: "150103",
          },
          {
            id: "l8jXceJzO8p2KM10AfRW",
            label: "170201",
          },
          {
            id: "nSsX8YkCze2J4reIm8iy",
            label: "191207",
          },
          {
            id: "j0xiOrBPHODlOqwDnmfK",
            label: "200138",
          },
          {
            id: "OA2yWUtZwy2O8yq2TBaU",
            label: "030105",
          },
        ],
      },
      {
        optionId: "YNIRn4bHUwkbn5uGOSOn",
        name: FORM_FIELD_NAME.WASTE_WOOD_CODE,
        label: "WasteWoodCode",
        type: FIELD_TYPE.SELECT,
        defaultValue: "l8jXceJzO8p2KM10AfRW",
        options: [
          {
            id: "l8jXceJzO8p2KM10AfRW",
            label: "170201",
          },
          {
            id: "nSsX8YkCze2J4reIm8iy",
            label: "191207",
          },
          {
            id: "j0xiOrBPHODlOqwDnmfK",
            label: "200138",
          },
          {
            id: "OA2yWUtZwy2O8yq2TBaU",
            label: "030105",
          },
        ],
      },
      {
        optionId: "APpm3pJsryrV3IdZOeCP",
        name: FORM_FIELD_NAME.WASTE_WOOD_CODE,
        label: "WasteWoodCode",
        type: FIELD_TYPE.SELECT,
        defaultValue: "l8jXceJzO8p2KM10AfRW",
        options: [
          {
            id: "l8jXceJzO8p2KM10AfRW",
            label: "170201",
          },
          {
            id: "nSsX8YkCze2J4reIm8iy",
            label: "191207",
          },
          {
            id: "j0xiOrBPHODlOqwDnmfK",
            label: "200138",
          },
          {
            id: "OA2yWUtZwy2O8yq2TBaU",
            label: "030105",
          },
        ],
      },
      {
        optionId: "6rgU5jIrRQqXYvjLglTf",
        name: FORM_FIELD_NAME.WASTE_WOOD_CODE,
        label: "WasteWoodCode",
        type: FIELD_TYPE.SELECT,
        defaultValue: "l8jXceJzO8p2KM10AfRW",
        options: [
          {
            id: "l8jXceJzO8p2KM10AfRW",
            label: "170201",
          },
          {
            id: "nSsX8YkCze2J4reIm8iy",
            label: "191207",
          },
          {
            id: "j0xiOrBPHODlOqwDnmfK",
            label: "200138",
          },
          {
            id: "OA2yWUtZwy2O8yq2TBaU",
            label: "030105",
          },
        ],
      },
    ],
  }),
  new AdditionalInfoField({
    id: "TlZB5CHE5mi4u4QO7kJW",
    name: FORM_FIELD_NAME.HUMIDITY,
    label: "Humidity",
    type: FIELD_TYPE.RANGE,
    defaultValue: 15,
    limit: {
      min: 15,
      max: 55,
      step: 5,
      postFix: "%",
    },
  }),
  new AdditionalInfoField({
    id: "nsvTLrX7AotnnHfeXbRE",
    name: FORM_FIELD_NAME.FINISHED_WITH_MAGNETS,
    label: "FinishedWithMagnets",
    type: FIELD_TYPE.CHECKBOX,
    defaultValue: false,
  }),
  new AdditionalInfoField({
    id: "NBYWe9KcRwKkxXdZRqIR",
    name: FORM_FIELD_NAME.FINISED_WITH_NONFERROUS_METAL_SEPARATOR,
    label: "FinishedWithNonFerrousMetalSeparator",
    type: FIELD_TYPE.CHECKBOX,
    defaultValue: false,
  }),
  new AdditionalInfoField({
    id: "0w8tPvViI6Y7fe5k5hh9",
    name: FORM_FIELD_NAME.WIHTOUT_LEAVES_THORNS,
    label: "WithoutLeavesThorns",
    type: FIELD_TYPE.CHECKBOX,
    defaultValue: false,
  }),
  new AdditionalInfoField({
    id: "RnOCL9dEP5FuadqcqGLN",
    name: FORM_FIELD_NAME.PLASTIC_FREE,
    label: "PlasticFree",
    type: FIELD_TYPE.CHECKBOX,
    defaultValue: false,
  }),
  new AdditionalInfoField({
    id: "CgCuIVJh5s9aof0t4hrG",
    name: FORM_FIELD_NAME.LABORATORY_TEST,
    label: "LaboratoryTest",
    acceptFiles: "application/pdf",
    type: FIELD_TYPE.FILE,
    defaultValue: "",
  }),
  new AdditionalInfoField({
    id: "AuNp8FcI3L95FKlpRyRp",
    name: FORM_FIELD_NAME.WASTE_PERMIT,
    label: "WastePermit",
    acceptFiles: "application/pdf",
    type: FIELD_TYPE.FILE,
    requiredFor: OFFER_TYPE.BUY,
    defaultValue: "",
  }),
];
