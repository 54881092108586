import SvgIconStyle from "minimal/components/SvgIconStyle";

const getIcon = (name) => (
  <SvgIconStyle
    src={`/static/icons/navbar/${name}.svg`}
    sx={{ width: 22, height: 22 }}
  />
);

const ICONS = {
  map: getIcon("ic_map"),
  blog: getIcon("ic_blog"),
  cart: getIcon("ic_cart"),
  chat: getIcon("ic_chat"),
  mail: getIcon("ic_mail"),
  page: getIcon("ic_page"),
  user: getIcon("ic_user"),
  copy: getIcon("ic_copy"),
  error: getIcon("ic_error"),
  charts: getIcon("ic_charts"),
  editor: getIcon("ic_editor"),
  upload: getIcon("ic_upload"),
  animate: getIcon("ic_animate"),
  calendar: getIcon("ic_calendar"),
  elements: getIcon("ic_elements"),
  carousel: getIcon("ic_carousel"),
  language: getIcon("ic_language"),
  ecommerce: getIcon("ic_ecommerce"),
  analytics: getIcon("ic_analytics"),
  dashboard: getIcon("ic_dashboard"),
  components: getIcon("ic_components"),
  authenticator: getIcon("ic_authenticator"),
};

export const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: "WasteOffer.Title",
    items: [
      {
        title: "WasteOffer.List",
        href: "/offers",
        icon: ICONS.elements,
      },
      {
        title: "WasteOffer.MyList",
        href: "/offers/my",
        icon: ICONS.user,
      },
      {
        title: "WasteOffer.MyClosedList",
        href: "/offers/myclosed",
        icon: ICONS.user,
      },
      {
        title: "WasteOffer.MyBids",
        href: "/offers/mybids",
        icon: ICONS.user,
      },
      {
        title: "WasteOffer.MyArchivedBids",
        href: "/offers/myarchivedbids",
        icon: ICONS.user,
      },
      {
        title: "WasteOffer.Add",
        href: "/offers/create",
        icon: ICONS.ecommerce,
      },
    ],
  },
];
