let devTools;
let globalState = {};

export const devtools = (store) => (set, get, api) => {
    let extension;
    const prefix = store.name ? `@${store.name}` : "";

    try {
        extension =
            window.__REDUX_DEVTOOLS_EXTENSION__ ||
            window.top.__REDUX_DEVTOOLS_EXTENSION__;
    } catch (err) {
        if (
            process.env.NODE_ENV === "development" &&
            typeof window !== "undefined"
        ) {
            console.warn("Please install/enable Redux devtools extension");
        }
    }

    if (!extension) {
        devTools = null;
        return store(set, get, api);
    }

    const namedSet = (state, replace, name) => {
        set(state, replace);
        globalState = { ...globalState, [store.name]: get() };
        devTools.send(
            {
                type: prefix + " > " + (name || state.name || "action"),
            },
            globalState
        );
    };

    const initialState = store(namedSet, get, api);

    if (!devTools) {
        devTools = extension.connect({});
        devTools.init(globalState);
    }

    return initialState;
};
