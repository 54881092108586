import { FIELD_TYPE, FORM_FIELD_NAME, OFFER_TYPE } from "../enums";
import AdditionalInfoField from "../map";

export const getWasteWoodFields = () => [
  new AdditionalInfoField({
    id: "4obOwSoCgVqNIOMYVTC9",
    name: FORM_FIELD_NAME.WASTE_WOOD_SORTING_TYPE,
    label: "WasteWood",
    type: FIELD_TYPE.SELECT,
    defaultValue: "AXQ0IrsWPJRAvFPNoQFx",
    options: [
      {
        id: "uadBoSotp5u6Ll4y2e2T",
        label: "WasteWoods.Sorted",
      },
      {
        id: "AXQ0IrsWPJRAvFPNoQFx",
        label: "WasteWoods.Unsorted",
      },
    ],
    conditionalOptions: [
      {
        optionId: "uadBoSotp5u6Ll4y2e2T",
        name: FORM_FIELD_NAME.WASTE_WOOD_CLASS,
        label: "WasteWoodClass",
        type: FIELD_TYPE.SELECT,
        defaultValue: "dWCAJLQFvAMW7zr11hde",
        options: [
          {
            id: "dWCAJLQFvAMW7zr11hde",
            label: "A",
          },
          {
            id: "RTT82sYPakWsBMnCIJ82",
            label: "A+B",
          },
          {
            id: "6fQStYWpCf3pAOIc7zBL",
            label: "B",
          },
          {
            id: "gEoizgbLgacvFjZgxesG",
            label: "C",
          },
        ],
        conditionalOptions: [
          {
            optionId: "dWCAJLQFvAMW7zr11hde",
            name: FORM_FIELD_NAME.WASTE_WOOD_CODE,
            label: "WasteWoodCode",
            type: FIELD_TYPE.SELECT,
            defaultValue: "vjXV6XI0eSdoAKzVEGUi",
            options: [
              {
                id: "vjXV6XI0eSdoAKzVEGUi",
                label: "150103",
              },
              {
                id: "476aSkw7hSqgwhc6w2CA",
                label: "170201",
              },
              {
                id: "jSArwxtZLqz9mZVL1ZfL",
                label: "191207",
              },
              {
                id: "hG3iQgVKFaZc5vGGuCDB",
                label: "200138",
              },
            ],
          },
          {
            optionId: "RTT82sYPakWsBMnCIJ82",
            name: FORM_FIELD_NAME.WASTE_WOOD_CODE,
            label: "WasteWoodCode",
            type: FIELD_TYPE.SELECT,
            defaultValue: "476aSkw7hSqgwhc6w2CA",
            options: [
              {
                id: "476aSkw7hSqgwhc6w2CA",
                label: "170201",
              },
              {
                id: "jSArwxtZLqz9mZVL1ZfL",
                label: "191207",
              },
              {
                id: "hG3iQgVKFaZc5vGGuCDB",
                label: "200138",
              },
            ],
          },
          {
            optionId: "6fQStYWpCf3pAOIc7zBL",
            name: FORM_FIELD_NAME.WASTE_WOOD_CODE,
            label: "WasteWoodCode",
            type: FIELD_TYPE.SELECT,
            defaultValue: "476aSkw7hSqgwhc6w2CA",
            options: [
              {
                id: "476aSkw7hSqgwhc6w2CA",
                label: "170201",
              },
              {
                id: "jSArwxtZLqz9mZVL1ZfL",
                label: "191207",
              },
              {
                id: "hG3iQgVKFaZc5vGGuCDB",
                label: "200138",
              },
            ],
          },
          {
            optionId: "gEoizgbLgacvFjZgxesG",
            name: FORM_FIELD_NAME.WASTE_WOOD_CODE,
            label: "WasteWoodCode",
            type: FIELD_TYPE.SELECT,
            defaultValue: "476aSkw7hSqgwhc6w2CA",
            options: [
              {
                id: "476aSkw7hSqgwhc6w2CA",
                label: "170201",
              },
              {
                id: "jSArwxtZLqz9mZVL1ZfL",
                label: "191207",
              },
              {
                id: "hG3iQgVKFaZc5vGGuCDB",
                label: "200138",
              },
            ],
          },
        ],
      },
    ],
  }),
  new AdditionalInfoField({
    id: "AuNp8FcI3L95FKlpRyRp",
    name: FORM_FIELD_NAME.WASTE_PERMIT,
    label: "WastePermit",
    acceptFiles: "application/pdf",
    type: FIELD_TYPE.FILE,
    requiredFor: OFFER_TYPE.BUY,
    defaultValue: "",
  }),
];
