import { getDocs, query, where } from "firebase/firestore";

export const replaceWithAsterisks = (name, count = 0) => {
  if (!name) return;
  return name
    .split(" ")
    .map((part) =>
      part
        .slice(0, count + 5)
        .split("")
        .map((letter, index) => (index > count ? "*" : letter))
        .join("")
    )
    .join(" ");
};

export const obfuscateContacts = (item) => ({
  ...item,
  contact: {
    email: replaceWithAsterisks(item.contact.email, 3),
    fullName: replaceWithAsterisks(item.contact.name || item.contact.fullName),
    phoneNumber: replaceWithAsterisks(
      item.contact.phone || item.contact.phoneNumber,
      4
    ),
  },
});

// TODO: obfuscating contacts should be done in backend
export const mapDataHideContacts = (requestDoc, userId) => {
  const data = requestDoc.data();
  const item = data.createdBy !== userId ? obfuscateContacts(data) : data;
  return { id: requestDoc.id, ...item };
};

export const sortByCreatedAt = (a, b) => {
  // Previous sorting formula stopped working - no toDate() function on createdAt object
  // return b.createdAt?.toDate() - a.createdAt?.toDate();
  return (
    new Date(b.createdAt.seconds * 1000) + new Date(a.createdAt.seconds * 1000)
  );
};

export const fetchCollectionByUserId = async ({
  userId,
  collection,
  mine = true,
  filterBy = null,
}) => {
  const queryConstraints = [where("isParent", "==", true)];
  if (mine) {
    queryConstraints.push(where("createdBy", "==", userId));
  }
  if (filterBy) {
    for (const key of Object.keys(filterBy)) {
      if (key !== "wasteType") {
        queryConstraints.push(where(key, "==", filterBy[key]));
      } else if (key === "wasteType" && filterBy[key].length) {
        queryConstraints.push(where(key, "in", filterBy[key]));
      }
    }
  }
  const queryJoined = query(collection, ...queryConstraints);
  try {
    const querySnapshot = await getDocs(queryJoined);
    let results = [];
    querySnapshot.forEach((requestDoc) => {
      results.push(mapDataHideContacts(requestDoc, userId));
    });
    return results.sort(sortByCreatedAt);
  } catch (err) {
    console.error(err);
    return Promise.reject(err.code);
  }
};
