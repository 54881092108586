// @ts-check
import { signOut } from "helpers/auth";
import { useTranslation } from "react-i18next";
import { useNotifications } from "store/notifications";

// clear stored data and log out
export default function useLogout() {
  const { setInfo, setError } = useNotifications();
  const { t } = useTranslation();

  const handleLogout = async () => {
    try {
      signOut();
      setInfo(t("Success.Logout"));
    } catch (err) {
      console.error(err);
      setError(t("Error.Logout"));
    }
  };

  return { handleLogout };
}
