import { experimentalStyled as styled } from "@mui/material/styles";
import { DRAWER_WIDTH } from "wastexchange/components/Sidebar";

const APP_BAR_MOBILE = 64;
// const APP_BAR_DESKTOP = 92;

export const RootStyle = styled("div")({
    display: "flex",
    minHeight: "100%",
    overflow: "hidden",
});

export const MainStyle = styled("div")(({ theme }) => ({
    flexGrow: 1,
    overflow: "auto",
    minHeight: "100%",
    paddingTop: APP_BAR_MOBILE + 24,
    paddingBottom: theme.spacing(10),
    position: "relative",
    [theme.breakpoints.up("md")]: {
        marginLeft: DRAWER_WIDTH,
    },
    [theme.breakpoints.up("lg")]: {
        marginLeft: 0,
        // paddingTop: APP_BAR_DESKTOP + 24,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
}));
