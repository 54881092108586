import { Container } from "@mui/material";

import { getUserBids } from "wastexchange/store/wasteoffers";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BidsTable from "components/BidsTable/BidsTable";

const WasteOfferMyBids = ({ userId }) => {
  const [bids, setBids] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchBids = async () => {
      const res = await getUserBids(userId, false);
      setBids(res);
    };
    fetchBids();
  }, [userId]);

  if (!bids) {
    return null;
  }

  return (
    <Container maxWidth="lg">
      <BidsTable bids={bids} title={t("WasteOffer.PendingBids")} />
    </Container>
  );
};

export default WasteOfferMyBids;
