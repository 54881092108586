import { ChangeProfileForm } from "wastexchange/components/ChangeProfileForm";
import { useAuth } from "helpers/auth";
import { useEffect, useState } from "react";
import { useCompanies } from "store/companies";
import { useUserProfile } from "store/userprofile";

const ChangeProfile = () => {
  const { user } = useAuth();
  const { userProfile, fetchProfile } = useUserProfile();
  const { companies, fetchCompany } = useCompanies();
  const [company, setCompany] = useState({});

  useEffect(() => {
    if (user?.uid) {
      fetchProfile(user?.uid);
    }
  }, [fetchProfile, user?.uid]);

  useEffect(() => {
    if (userProfile?.company) {
      fetchCompany(userProfile?.company);
    }
  }, [fetchCompany, userProfile?.company]);

  useEffect(() => {
    if (companies.size) {
      setCompany(Array.from(companies)[0][1]);
    }
  }, [companies]);

  if (
    !userProfile ||
    (userProfile?.company && companies.size === 0) ||
    (Object.keys(company).length === 0 && companies.size !== 0)
  ) {
    return null;
  }

  return <ChangeProfileForm userProfile={userProfile} company={company} />;
};

export default ChangeProfile;
