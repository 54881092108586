import {
  addDoc,
  collection,
  collectionGroup,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { useAuth } from "helpers/auth";
import { db } from "helpers/firebase";
import { locationFilterRadiuses } from "helpers/options";
import { customAlphabet } from "nanoid";
import { useEffect, useRef } from "react";
import { fetchCollectionByUserId } from "store/data.helpers";
import { BidStatuses } from "store/schemas/WasteOfferBidSchema";
import { createWithDevtools } from "store/store.helpers";
import { isDateABeforeDateB } from "helpers/isDateABeforeDateB";
import { OfferStatuses } from "wastexchange/store/schemas/WasteOfferSchema";

import { useNotifications } from "store/notifications";
import { useTranslation } from "react-i18next";

import { getCenter, getGeoHash, queryByGeoHash } from "./geofire";

const nanoid = customAlphabet("123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ", 4);

const getNewCode = () => {
  return nanoid();
};

const offersCollection = collection(db, "wasteoffers");

const fetchOffers = async ({
  userId,
  mine = false,
  status = "",
  filter = null,
}) => {
  const filterBy = {};

  if (filter.wasteType) {
    filterBy.wasteType = filter.wasteType;
  }
  if (status === "closed" || status === "open" || status === "archived") {
    filterBy.status = status;
  }
  if (filter.offerType && filter.offerType !== "all") {
    filterBy.offerType = filter.offerType;
  }

  if (filter.location) {
    const radiusInM = filter.radius * 1000;
    return queryByGeoHash(
      getCenter(filter.location.lat, filter.location.lon),
      radiusInM,
      "wasteoffers",
      userId,
      mine,
      filterBy
    );
  }

  return fetchCollectionByUserId({
    userId,
    collection: offersCollection,
    mine,
    filterBy,
  });
};

export const createOffer = async (userId, data) => {
  if (!userId || !data) {
    throw new Error("Insuficcient parameters!");
  }

  return addDoc(offersCollection, {
    ...data,
    offerType: data.offerType || "sell",
    createdBy: userId,
    createdAt: new Date(),
    code: getNewCode(),
    status: OfferStatuses.OPEN,
    geohash: getGeoHash(data.location.lat, data.location.lon),
  });
};

export const getWasteOffer = (offerId) =>
  getDoc(doc(offersCollection, offerId));

export const updateOffer = async (userId, offerId, data) => {
  if (!userId || !offerId || !data) {
    throw new Error("Cannot update offer, insufficient data!");
  }

  const offerRef = doc(offersCollection, offerId);

  if (data.location) {
    data.geohash = getGeoHash(data.location.lat, data.location.lon);
  }

  if (!data.code) {
    data.code = getNewCode();
  }

  return setDoc(
    offerRef,
    {
      ...data,
      updatedAt: new Date(),
      updatedBy: userId,
    },
    { merge: true }
  );
};

export const updateBid = (userId, offerId, bidId, data) => {
  if (!userId || !bidId || !offerId) {
    throw new Error("Invalid or missing parameters!");
  }
  const offerDoc = doc(offersCollection, offerId);
  const bidsCollection = collection(offerDoc, "bids");
  const bidDoc = doc(bidsCollection, bidId);
  return updateDoc(bidDoc, {
    ...data,
    updatedAt: new Date(),
    updatedBy: userId,
  });
};

export const updateBidStatus = (userId, offerId, bidId, status) => {
  return updateBid(userId, offerId, bidId, { status });
};

export const deleteOffer = async (userId, offerId) => {
  if (!userId || !offerId) {
    throw new Error("Cannot delete offer, insufficient data!");
  }

  const offerRef = doc(offersCollection, offerId);
  const offerDoc = await getDoc(offerRef);
  if (offerDoc.exists) {
    await deleteDoc(offerRef);
  } else {
    alert("Doc does not exist");
  }
};

export const createBid = async (userId, offerId, data, expiresAt) => {
  if (!userId || !offerId || !data) {
    throw new Error("Cannot create bid, insufficient arguments!");
  }
  if (!isDateABeforeDateB(new Date(), expiresAt)) {
    throw new Error("The offer has expired");
  }
  const offerDoc = doc(offersCollection, offerId);

  const bidsCollection = collection(offerDoc, "bids");

  const bidRef = doc(bidsCollection);
  const bidDoc = await getDoc(bidRef);

  return setDoc(bidRef, {
    ...data,
    id: bidDoc.id,
    status: BidStatuses.NEW,
    createdAt: new Date(),
    createdBy: userId,
    isArchived: false,
  });
};

export const createBidComment = async (bidId, user, message) => {
  if (!user || !bidId || !message) {
    throw new Error("Cannot create comment, insufficient arguments!");
  }

  const bidsCollection = collectionGroup(db, "bids");
  const bidQuery = query(bidsCollection, where("id", "==", bidId));
  const bidSnap = await getDocs(bidQuery);

  for (const bidDoc of bidSnap.docs) {
    const data = bidDoc.data();
    const messageObj = {
      sender: user,
      message: message,
      time: new Date(),
    };
    const comments = data.comments
      ? [...data.comments, messageObj]
      : [messageObj];
    await setDoc(
      bidDoc.ref,
      {
        ...data,
        comments: comments,
      },
      { merge: true }
    );
  }
};

export async function getUserBids(userId, isArchived) {
  if (!userId) {
    throw new Error("User Id is missing!");
  }

  const bidsCollection = collectionGroup(db, "bids");
  const queryClauses = [orderBy("createdAt", "asc")];

  queryClauses.push(where("createdBy", "==", userId));
  queryClauses.push(where("isArchived", "==", isArchived));

  const bidQuery = query(bidsCollection, ...queryClauses);
  const bidSnap = await getDocs(bidQuery);

  const typeList = [];
  const offerList = [];
  const bidsResults = [];

  const typeSnap = await getDocs(query(collection(db, "wastetypes")));
  const offerSnap = await getDocs(query(collection(db, "wasteoffers")));

  for (const t of typeSnap.docs) {
    typeList.push(t.data());
  }

  for (const o of offerSnap.docs) {
    offerList.push({ ...o.data(), id: o.id });
  }

  for (const bidDoc of bidSnap.docs) {
    const offerFound = offerList.filter(
      (o) => o.id === bidDoc.ref.parent.parent.id
    );

    if (offerFound.length) {
      const typeFound = typeList.filter(
        (t) => t.id === offerFound[0].wasteType
      );

      const offerName = `${typeFound[0].value} ${typeFound[0].en_label}`;

      const data = bidDoc.data();

      bidsResults.push({
        id: bidDoc.id,
        offerId: offerFound[0].id,
        offerName: offerName,
        ...data,
        createdAt: data.createdAt.toDate(),
      });
    }
  }

  return bidsResults;
}

const getBidsCollection = (wasteOfferId) => {
  if (!wasteOfferId) {
    throw new Error("WasteOffer Id is missing!");
  }

  const offerDoc = doc(offersCollection, wasteOfferId);
  return collection(offerDoc, "bids");
};

const getBidsQuery = (wasteOfferId, createdBy = null) => {
  const bidsCollection = getBidsCollection(wasteOfferId);
  const queryClauses = [orderBy("createdAt", "asc")];
  if (createdBy) {
    queryClauses.push(where("createdBy", "==", createdBy));
  }
  return query(bidsCollection, ...queryClauses);
};

export const useBidsListener = (wasteOffer) => {
  const { addWasteOfferBid, setHighestBid } = useWasteOffers();
  const { user } = useAuth();
  useEffect(() => {
    if (!wasteOffer?.id || !user?.uid) {
      return;
    }
    const isBuyOfferType = wasteOffer.offerType === "buy";
    const oldHighestBid = wasteOffer.highestBid;
    const isOwner = wasteOffer.createdBy === user.uid;
    const q = getBidsQuery(wasteOffer.id, !isOwner ? user.uid : null);
    const unlisten = onSnapshot(q, (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        const newBid = { id: doc.id, ...doc.data() };
        addWasteOfferBid(wasteOffer.id, newBid);
        if (isBuyOfferType) {
          if (
            newBid?.amount < oldHighestBid?.amount ||
            (!oldHighestBid && newBid)
          ) {
            setHighestBid({
              amount: newBid.amount,
              id: newBid.id,
              currencyCode: newBid.currencyCode,
            });
          }
        } else {
          if (
            newBid?.amount > oldHighestBid?.amount ||
            (!oldHighestBid && newBid)
          ) {
            setHighestBid({
              amount: newBid.amount,
              id: newBid.id,
              currencyCode: newBid.currencyCode,
            });
          }
        }
      });
    });

    return () => {
      unlisten();
    };
  }, [addWasteOfferBid, setHighestBid, user, wasteOffer]);
};

export const useWasteOfferListener = (wasteOfferId) => {
  const { setWasteOffer, setOfferLoading } = useWasteOffers();
  const { user } = useAuth();
  const { t } = useTranslation();
  const { setInfo, setError } = useNotifications();
  const isFirstRender = useRef(true);
  useEffect(() => {
    if (!wasteOfferId || !user?.uid) {
      return;
    }
    setOfferLoading(true);
    const q = doc(offersCollection, wasteOfferId);
    const unlisten = onSnapshot(
      q,
      (querySnapshot) => {
        const data = querySnapshot.data();
        if (data) {
          setWasteOffer({ id: wasteOfferId, ...data });
          if (!isFirstRender.current) {
            setInfo(t("Offer.Updated"));
          }
          setOfferLoading(false);
          isFirstRender.current = false;
        }
        return null;
      },
      (onError) => {
        setError(t("Error.UpdateFailed"));
        return null;
      }
    );

    return () => {
      unlisten();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, wasteOfferId, setWasteOffer]);
};

const wasteOffersStore = (set) => {
  return {
    wasteOffer: null,
    wasteOfferBids: new Map(),
    offersList: [],
    highestBid: null,
    offerLoading: true,
    filter: {
      wasteType: [],
      location: "",
      radius: locationFilterRadiuses[2].value,
      offerType: "all",
    },
    setOfferLoading: (offerLoading) => {
      set(function setOfferLoading(draft) {
        draft.offerLoading = offerLoading;
      });
    },
    setFilter: (name, value) => {
      set(function setFilter(draft) {
        draft.filter[name] = value;
      });
    },
    setHighestBid: (highestBid) => {
      set(function setFilter(draft) {
        draft.highestBid = highestBid;
      });
    },
    setWasteOffer: (wasteOffer) => {
      set(function setWasteOffer(draft) {
        draft.wasteOffer = wasteOffer;
        draft.highestBid = wasteOffer.highestBid;
      });
    },
    fetchById: async (offerId, userId) => {
      const result = await getWasteOffer(offerId);
      if (result.exists()) {
        const wasteOffer = { id: result.id, ...result.data() };
        set(function setWasteOffer(draft) {
          draft.wasteOffer = wasteOffer;
          draft.highestBid = wasteOffer.highestBid;
        });
      }
    },
    addWasteOfferBid: (wasteOfferId, bid) => {
      set(function setWasteOfferBids(draft) {
        if (!draft.wasteOfferBids.get(wasteOfferId)) {
          draft.wasteOfferBids.set(wasteOfferId, new Map());
        }
        draft.wasteOfferBids.get(wasteOfferId).set(bid.id, bid);
      });
    },
    setWasteOfferBids: (wasteOfferId, bids) => {
      set(function setWasteOfferBids(draft) {
        draft.wasteOfferBids.set(wasteOfferId, bids);
      });
    },
    fetchList: async (userId, filter) => {
      const results = await fetchOffers({
        userId,
        filter,
        status: OfferStatuses.OPEN,
      });
      set(function setList(draft) {
        draft.offersList = results;
      });
    },
    fetchMyList: async (userId, filter) => {
      const results = await fetchOffers({
        userId,
        mine: true,
        status: OfferStatuses.OPEN,
        filter,
      });
      set(function setList(draft) {
        draft.offersList = results;
      });
    },
    fetchMyClosedList: async (userId, filter) => {
      const closedOffers = await fetchOffers({
        userId,
        mine: true,
        status: OfferStatuses.CLOSED,
        filter,
      });
      const archivedOffers = await fetchOffers({
        userId,
        mine: true,
        status: OfferStatuses.ARCHIVED,
        filter,
      });
      set(function setList(draft) {
        draft.offersList = [...archivedOffers, ...closedOffers];
      });
    },
  };
};

export const bidsSelector = (wasteOfferId) => (store) =>
  store.wasteOfferBids.get(wasteOfferId);

export const useWasteOffers = createWithDevtools(wasteOffersStore);
