import { FormControlLabel } from "@mui/material";
import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import MSwitch from "@mui/material/Switch";

const SwitchWithLabel = (
  { label, value, onChange, size = "small", ...rest },
  ref
) => {
  const { t } = useTranslation();
  const tLabel = label ? t(label) : null;

  return (
    <FormControlLabel
      inputRef={ref}
      control={
        <MSwitch checked={value === true} onChange={onChange} size={size} />
      }
      label={tLabel}
      {...rest}
    />
  );
};

export default forwardRef(SwitchWithLabel);
