import { Suspense, lazy } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { RequireAuth } from "./RequireAuth";
import { useCacheBuster } from "./helpers/cachebuster";
import { isProduction } from "./helpers/utils";
import { LoadingScreen } from "./minimal/components/LoadingScreeen";
import Landing from "./wastexchange/Landing";
import Dashboard from "wastexchange/pages/wasteoffers/DashBoard";
import TermsAndConditions from "wastexchange/pages/termsAndConditions";
import { UnSubEndPoint } from "wastexchange/components/UnSubEndPoint";
import ChangeProfile from "wastexchange/pages/account/ChangeProfile";
import LoginPage from "wastexchange/pages/account/Login";
import VerifyPage from "wastexchange/pages/account/Verify";
import Page404 from "wastexchange/pages/404";

const AdminApp = lazy(() => import("./admin/AdminApp"));
const App = lazy(() => import("./wastexchange/App"));

const isDev = process.env.NODE_ENV === "development";

const Root = () => {
  useCacheBuster(isProduction);
  return (
    <>
      <HelmetProvider>
        <Suspense fallback={<LoadingScreen />}>
          <BrowserRouter>
            <Routes>
              <Route path="/admin/*" element={<AdminApp />} />
              <Route path="/" element={<App />}>
                <Route index element={<Landing />} />
                <Route
                  path="/offers/*"
                  element={
                    <RequireAuth>
                      <Dashboard />
                    </RequireAuth>
                  }
                />
                <Route path="/terms-of-service" element={<TermsAndConditions />} />
                <Route
                  path="/unsub/:agent/:userId"
                  element={<UnSubEndPoint />}
                />
                <Route
                  path="/account"
                  element={
                    <RequireAuth>
                      <ChangeProfile />
                    </RequireAuth>
                  }
                />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/verify" element={<VerifyPage />} />
              </Route>
              <Route path="*" element={<Page404 />} />
            </Routes>
          </BrowserRouter>
        </Suspense>
      </HelmetProvider>
      <div
        style={{
          position: "fixed",
          bottom: isDev ? 30 : 0,
          right: 0,
          zIndex: 20000,
          fontSize: "12px",
          color: "gray",
        }}
      >
        v{window._appVersion}
      </div>
    </>
  );
};

export default Root;
