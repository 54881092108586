import { DateTimePicker as MDateTimePicker } from "@mui/x-date-pickers";
import { forwardRef } from "react";
import TextField from "./TextField";

function DateTimePicker({ label, value, ...rest }, ref) {
  return (
    <MDateTimePicker
      renderInput={(inputProps) => <TextField {...inputProps} />}
      label={label}
      value={value}
      inputRef={ref}
      {...rest}
    />
  );
}

export default forwardRef(DateTimePicker);
