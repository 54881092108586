import { Box } from "@mui/material";
import { getOptionsMap, quantityUnits } from "helpers/options";
import { useTranslation } from "react-i18next";

export const Quantity = ({ amount, unit }) => {
  const { t } = useTranslation();
  const units = getOptionsMap(quantityUnits, t);

  return (
    <Box display="flex" justifyContent="flex-end">
      {`${amount} ${t(units.get(unit).label, { count: Number(amount) })}`}
    </Box>
  );
};
