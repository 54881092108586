import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import arrowIosForwardFill from "@iconify/icons-eva/arrow-ios-forward-fill";
import arrowIosDownwardFill from "@iconify/icons-eva/arrow-ios-downward-fill";
// material
import { alpha, experimentalStyled as styled } from "@mui/material/styles";
import {
    Box,
    Collapse,
    ListItem,
    ListItemIcon,
    ListItemText,
} from "@mui/material";

// ----------------------------------------------------------------------

const ListItemStyle = styled(ListItem)(({ theme }) => ({
    ...theme.typography.body2,
    height: 48,
    textTransform: "capitalize",
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(2.5),
    color: theme.palette.text.secondary,
    "&.isActiveRoot": {
        color: theme.palette.primary.main,
        fontWeight: theme.typography.fontWeightMedium,
        backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity
        ),
        "&:before": {
            top: 0,
            right: 0,
            width: 3,
            bottom: 0,
            content: "''",
            position: "absolute",
            backgroundColor: theme.palette.primary.main,
        },
    },
    "&.isActiveSub": {
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightMedium,
        "& .subIcon:before": {
            transform: "scale(2)",
            backgroundColor: theme.palette.primary.main,
        },
    },
}));

const SubIconStyle = styled("span")(({ theme }) => ({
    width: 22,
    height: 22,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:before": {
        width: 4,
        height: 4,
        content: "''",
        display: "block",
        borderRadius: "50%",
        backgroundColor: theme.palette.text.disabled,
        transition: theme.transitions.create("transform"),
    },
}));

export const SidebarItem = ({
    level,
    title,
    href,
    info,
    icon,
    pathname,
    open = false,
    children,
}) => {
    const [show, setShow] = useState(open);
    const [currentHref, setCurrentHref] = useState(false);
    const isSubItem = level > 0;

    useEffect(() => {
        setCurrentHref(pathname === href);
    }, [pathname, href, setCurrentHref, currentHref]);

    const handleShow = () => {
        setShow((show) => !show);
    };

    if (children) {
        return (
            <>
                <ListItemStyle
                    button
                    disableGutters
                    onClick={handleShow}
                    className={open ? "isActiveRoot" : ""}
                >
                    <ListItemIcon>{icon && icon}</ListItemIcon>
                    <ListItemText primary={title} />
                    {info && info}
                    <Box
                        component={Icon}
                        icon={show ? arrowIosDownwardFill : arrowIosForwardFill}
                        sx={{ width: 16, height: 16, ml: 1 }}
                    />
                </ListItemStyle>
                <Collapse in={show}>{children}</Collapse>
            </>
        );
    }

    return (
        <ListItemStyle button to={href} disableGutters component={RouterLink}>
            <ListItemIcon>
                {isSubItem ? <SubIconStyle className="subIcon" /> : icon}
            </ListItemIcon>
            <ListItemText
                disableTypography
                primary={title}
                style={{ color: currentHref ? "green" : "gray" }}
            />

            {info && info}
        </ListItemStyle>
    );
};
