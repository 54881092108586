import { Icon } from "@iconify/react";
import arrowLeftFill from "@iconify/icons-eva/arrow-left-fill";
import arrowRightFill from "@iconify/icons-eva/arrow-right-fill";
import MIconButton from "minimal/components/@material-extend/MIconButton";

import {
  alpha,
  useTheme,
  experimentalStyled as styled,
} from "@mui/material/styles";
import { Typography } from "@mui/material";

const RootStyle = styled("div")(({ theme }) => ({
  zIndex: 9,
  display: "flex",
  alignItems: "center",
  position: "absolute",
  top: theme.spacing(2),
  right: theme.spacing(2),
  color: theme.palette.common.white,
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.grey[900], 0.48),
}));

const ArrowStyle = styled(MIconButton)(({ theme }) => ({
  padding: 6,
  opacity: 0.48,
  color: theme.palette.common.white,
  "&:hover": { opacity: 1 },
}));

export const CarouselControls = ({
  index,
  total,
  onNext,
  onPrevious,
  ...other
}) => {
  const theme = useTheme();
  const isRTL = theme.direction === "rtl";

  return (
    <RootStyle {...other}>
      <ArrowStyle size="small" onClick={onPrevious}>
        <Icon
          width={20}
          height={20}
          icon={isRTL ? arrowRightFill : arrowLeftFill}
        />
      </ArrowStyle>

      <Typography variant="subtitle2">
        {index + 1}/{total}
      </Typography>

      <ArrowStyle size="small" onClick={onNext}>
        <Icon
          width={20}
          height={20}
          icon={isRTL ? arrowLeftFill : arrowRightFill}
        />
      </ArrowStyle>
    </RootStyle>
  );
};
