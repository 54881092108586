import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

export default function BidsTableToolBar({ title }) {
  return (
    <Toolbar>
      <Typography variant="h6" id="tableTitle" component="div">
        {title}
      </Typography>
    </Toolbar>
  );
}
