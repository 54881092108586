import React, { forwardRef } from "react";
import { FormLabel, Slider } from "@mui/material";
import { useTranslation } from "react-i18next";

const getMarkText = (value, postFix) => {
  return `${value}${postFix || ""}`;
};

const getSliderMarks = (limit) => {
  const marks = [];

  const step = limit.step < 5 ? 5 : limit.step;

  for (let i = limit.min; i <= limit.max; i += step) {
    marks.push({
      value: i,
      label: `${i}${limit.postFix || ""}`,
    });
  }

  return marks;
};

const getMinValue = (min) => {
  const remainder = min % 5;
  return min - remainder;
};

const Range = (props, ref) => {
  const { label, value, limit, onChange } = props;
  const { t } = useTranslation();

  const min = getMinValue(limit.min);
  const sliderMarks = getSliderMarks({ ...limit, min });

  return (
    <>
      <FormLabel>{t(label)}</FormLabel>
      <Slider
        defaultValue={min}
        getAriaValueText={(v) => getMarkText(v, limit.postFix)}
        step={limit.step || 1}
        valueLabelDisplay="auto"
        min={min}
        max={limit.max}
        marks={sliderMarks}
        value={value}
        onChange={onChange}
        ref={ref}
      ></Slider>
    </>
  );
};

export default forwardRef(Range);
