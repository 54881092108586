import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import FormField from "components/forms/FormField";
import TextField from "components/forms/TextField";
import { Messages } from "components/messages/Messages";

import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNotifications } from "store/notifications";
import ChatIcon from "@mui/icons-material/Chat";
import { BidReplySchema } from "store/schemas/WasteOfferBidSchema";
import { LoadingButton } from "@mui/lab";
import { createBidComment } from "wastexchange/store/wasteoffers";
import { useUserProfile } from "store/userprofile";

const defaultValues = BidReplySchema.cast();
const messageContext = "bidNegotiationForm";

export const BidNegotiationForm = ({ bidId, bidOwnerId, offerOwnerId }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { setError } = useNotifications();

  const { userProfile } = useUserProfile();

  const { control, handleSubmit, reset } = useForm({
    defaultValues,
    resolver: yupResolver(BidReplySchema, {
      stripUnknown: true,
      abortEarly: false,
    }),
  });

  const { t } = useTranslation();

  if (!userProfile) {
    return null;
  }

  const { fullName, pictures } = userProfile;
  const photoURL = pictures?.src;

  const setErrorInDialog = (name, data) => {
    setError(t(name, data), { context: messageContext });
  };

  const openDialog = () => {
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  const onSubmit = (data) => {
    setIsSubmitting(true);
    try {
      const senderData = {
        fullName,
        photoURL,
        id: userProfile.id,
        to: userProfile.id === offerOwnerId ? bidOwnerId : offerOwnerId,
      };
      createBidComment(bidId, senderData, data.reply);
      reset();
    } catch (err) {
      setErrorInDialog(err.message);
    }
    setIsSubmitting(false);
    closeDialog();
  };

  return (
    <>
      <Button startIcon={<ChatIcon />} onClick={openDialog} color="info">
        {t("Offer.Reply")}
      </Button>
      <Dialog open={dialogOpen} onClose={closeDialog} maxWidth="md" fullWidth>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>
            <Typography>{t("Offer.ReplyLong")}</Typography>
          </DialogTitle>
          <DialogContent>
            <Messages context={messageContext} />
            <FormField
              name="reply"
              control={control}
              label="Offer.ReplyText"
              multiline
              minRows={5}
              maxRows={5}
              Component={TextField}
            />
          </DialogContent>
          <DialogActions>
            <LoadingButton
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              {t("Common.Submit")}
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};
