import { Box, LinearProgress, Stack, useMediaQuery, Alert, Link } from "@mui/material";
import { isLoadingSelector, useDataStore } from "store/data";
import LanguagePopover from "minimal/components/LanguagePopover";
import { AppBar, Toolbar } from "wastexchange/WasteNav.styles";
import menu2Fill from "@iconify/icons-eva/menu-2-fill";
import { Icon } from "@iconify/react";
import { MIconButton } from "minimal/components/@material-extend";
import AccountPopover from "minimal/components/AccountPopover";
//import { useAuth } from "helpers/auth";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useUserProfile } from "store/userprofile";

export const WasteNav = ({ handleOpenSidebar }) => {
  const isLoading = useDataStore(isLoadingSelector);

  //const { user } = useAuth();
  const { userProfile } = useUserProfile();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const hidden = useMediaQuery((theme) => theme.breakpoints.up("md"));

  function isProfileNotFinished() {

    if (!userProfile) return false;

    if (!userProfile.fullName) return true;

    return false;

  }

  return (
    <AppBar>
      <Toolbar variant="dense">
        {!hidden ? (
          <MIconButton
            onClick={handleOpenSidebar}
            sx={{ mr: 1, color: "text.primary" }}
          >
            <Icon icon={menu2Fill} />
          </MIconButton>
        ) : null}
        <Box
          p={1}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flex={1}
          style={{ position: "relative" }}
        >
          <Stack direction="row" spacing={2} alignItems="center" justifyContent="center" display="flex" width="100%">
            {isProfileNotFinished() && (
              <Alert
                severity="warning"
              >
                {t('Warning.UnfinishedProfile')} <Link onClick={() => navigate('/account')} color="inherit" style={{ cursor: "pointer" }}>{t('Common.UpdateProfile')}</Link>
              </Alert>
            )}
          </Stack>
          <Stack direction="row" spacing={1} alignItems="center">
            <LanguagePopover />
            <AccountPopover />
          </Stack>
        </Box>
        {isLoading && (
          <Box
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
            }}
          >
            <LinearProgress color="secondary" />
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
};
