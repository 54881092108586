import { Card, CardContent, Grid, Typography } from "@mui/material";
import { MAvatar } from "minimal/components/@material-extend";
import createAvatar from "minimal/utils/createAvatar";

const dateFormat = (dt) =>
  `${(dt.getMonth() + 1).toString().padStart(2, "0")}/${dt
    .getDate()
    .toString()
    .padStart(2, "0")}/${dt.getFullYear().toString().padStart(4, "0")} ${dt
    .getHours()
    .toString()
    .padStart(2, "0")}:${dt.getMinutes().toString().padStart(2, "0")}`;

export const BidComment = ({ time, sender, comment }) => {
  return (
    <Card style={{ marginTop: "5px" }}>
      <CardContent>
        <Grid container direction="column">
          <Grid item>
            <Grid container direction="row" spacing={2}>
              <Grid item>
                <MAvatar
                  src={sender.photoURL}
                  alt={sender.fullName}
                  color={
                    sender.photoURL
                      ? "default"
                      : createAvatar(sender.fullName).color
                  }
                >
                  {createAvatar(sender.fullName).name}
                </MAvatar>
              </Grid>
              <Grid item>
                <Grid container direction="column">
                  <Typography fontSize={15} style={{ wordBreak: "break-all" }}>
                    {sender.fullName}
                  </Typography>
                  <Typography
                    variant="caption"
                    display="block"
                    fontSize={11}
                    gutterBottom
                  >
                    {dateFormat(time.toDate())}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            style={{
              justifyContent: "start",
              display: "flex",
            }}
            item
            xs={8}
          >
            <Typography
              gutterBottom
              style={{
                marginBottom: "auto",
                marginTop: "auto",
                wordBreak: "break-all",
              }}
            >
              {" "}
              {comment}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
