import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useWasteTypes } from "../store/wastetypes";
export const WasteOfferTitle = ({ wasteOffer, variant = "body1" }) => {
  const { i18n } = useTranslation();
  const { wasteTypes } = useWasteTypes();

  if (!wasteOffer) {
    return null;
  }

  const wasteType = wasteTypes.get(wasteOffer.wasteType) || {};
  const name = wasteType[`${i18n.language}_label`] || wasteOffer.name;

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Typography variant={variant}>{name}</Typography>
    </Box>
  );
};
