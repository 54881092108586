import {
    getStorage,
    ref,
    uploadBytesResumable,
    getDownloadURL,
} from "firebase/storage";
import { createWithDevtools } from "../store/store.helpers";

const storage = getStorage();

const uploads = (set) => {
    const setProgress = (fileName, progressValue) => {
        set(function setProgress(draft) {
            draft.progress.set(fileName, progressValue);
        });
    };
    const setUploadTask = (fileName, task) => {
        set(function setUploadTask(draft) {
            draft.uploadTasks.set(fileName, task);
        });
    };
    const handleUpload = (path, files) => {
        const results = [];
        return new Promise((resolve, reject) => {
            for (const file of files) {
                const fileRef = ref(storage, `${path}/${file.name}`);
                const uploadTask = uploadBytesResumable(fileRef, file);
                setUploadTask(file.name, uploadTask);
                uploadTask.on(
                    "state_changed",
                    (snapshot) => {
                        setProgress(
                            file.name,
                            (snapshot.bytesTransferred / snapshot.totalBytes) *
                                100
                        );
                    },
                    (error) => {
                        // handle errors
                        console.error(error);
                        results.push({
                            success: false,
                            fileName: file.name,
                            error: error.message,
                        });
                        setProgress(file.name, 0);
                        if (results.length === files.length) {
                            resolve(results);
                        }
                    },
                    async () => {
                        // handle completed upload
                        const url = await getDownloadURL(
                            uploadTask.snapshot.ref
                        );
                        results.push({
                            success: true,
                            fileName: file.name,
                            url,
                        });
                        setProgress(file.name, 0);
                        if (results.length === files.length) {
                            resolve(results);
                        }
                    }
                );
            }
        });
    };

    return {
        progress: new Map(),
        uploadTasks: new Map(),
        handleUpload,
    };
};

export const useUploads = createWithDevtools(uploads);
