import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export const PaymentDeadline = ({ bid }) => {
    const { t } = useTranslation();

    const label = <strong>{t("PaymentDeadline.Amount")}</strong>;

    if (bid.prePayment) {
        return (
            <Typography>
                {label}: {t("WasteOfferBid.PrePayment")}
            </Typography>
        );
    }
    const { amount, unit } = bid.paymentDeadline;

    const translatedUnit = t(`WasteOfferBid.Deadline_${unit}`, {
        count: amount,
    });

    return (
        <Typography>
            {label}: {`${amount} ${translatedUnit}`}
        </Typography>
    );
};
