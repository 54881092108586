import { Container } from "@mui/material";

import { getUserBids } from "wastexchange/store/wasteoffers";
import { GridContainer, GridItem } from "components/layout/Helpers";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BidStatuses } from "store/schemas/WasteOfferBidSchema";
import BidsTable from "components/BidsTable/BidsTable";

const WasteOfferMyArchivedBids = ({ userId }) => {
  const [acceptBids, setAcceptBids] = useState([]);
  const [rejectBids, setRejectBids] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchBids = async () => {
      const bids = await getUserBids(userId, true);
      setAcceptBids(bids.filter((bid) => bid.status === BidStatuses.ACCEPTED));
      setRejectBids(bids.filter((bid) => bid.status === BidStatuses.REJECTED));
    };
    fetchBids();
  }, [userId]);

  return (
    <Container maxWidth="lg">
      <GridContainer container spacing={2}>
        <GridItem>
          <BidsTable bids={acceptBids} title={t("WasteOffer.AcceptedBids")} />
        </GridItem>
        <GridItem>
          <BidsTable bids={rejectBids} title={t("WasteOffer.RejectedBids")} />
        </GridItem>
      </GridContainer>
    </Container>
  );
};

export default WasteOfferMyArchivedBids;
