import * as Yup from "yup";

export const ContactSchema = Yup.object().shape({
    fullName: Yup.string().required("Validation.RequiredField").default(""),
    email: Yup.string()
        .email("Validation.MustBeValidEmail")
        .required("Validation.RequiredField")
        .default(""),
    phoneNumber: Yup.string()
        .matches(/^\+{1}[1-9]\d{1,14}$/, {
            excludeEmptyString: true,
            message: "Validation.PhoneFormat",
        })
        .nullable()
        .default(""),
    language: Yup.string().default(""),
});
