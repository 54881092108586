import { useMemo } from "react";
import { Box, CardContent, Link, Stack, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { WasteOfferTitle } from "wastexchange/components/WasteOfferTitle";
import { useTranslation } from "react-i18next";
import { Locations } from "./Locations";
import { startCaseCapitalLetter } from "helpers/startCaseCapitalLetter";
import {
  isLessThan5MinutesBefore,
  isDateABeforeDateB,
} from "helpers/isDateABeforeDateB";

export const WasteOfferOverviewContent = ({ wasteOffer }) => {
  const { highestBid, bidsCount = 0 } = wasteOffer;
  const { t } = useTranslation();
  const { amount, currencyCode } = highestBid
    ? highestBid
    : wasteOffer.startingPrice;

  const priceAmountWithCurrency = `${amount}${
    wasteOffer.startingPrice.usePercentage ? "%" : ""
  } ${currencyCode}`;

  const isExtendedBy5Minutes = useMemo(() => {
    const now = new Date();

    if (wasteOffer?.autoExtend && wasteOffer?.originalExpiryDate) {
      if (
        isDateABeforeDateB(now, wasteOffer.expiresAt) &&
        isLessThan5MinutesBefore(now, wasteOffer.expiresAt)
      )
        return true;
    }
    return false;
  }, [wasteOffer]);

  return (
    <CardContent>
      <Stack
        display="flex"
        justifyContent="space-between"
        direction="row"
        alignItems="center"
        pb={2}
      >
        <Link component={RouterLink} to={`/offers/${wasteOffer?.id}`}>
          <WasteOfferTitle wasteOffer={wasteOffer} />
        </Link>
        <Typography>
          <strong>{wasteOffer.code}</strong>
        </Typography>
      </Stack>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="body1">
          {t(
            `WasteCategories.${startCaseCapitalLetter(
              wasteOffer.wasteCategory
            )}`
          )}
        </Typography>
        <Typography variant="body1">
          {t(
            `OfferTypes.${startCaseCapitalLetter(
              wasteOffer.offerType || "sell"
            )}`
          )}
        </Typography>
      </Box>
      <Typography variant="body1" noWrap component="div">
        <Locations wasteOffer={wasteOffer} />
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: bidsCount > 0 ? "space-between" : "flex-end",
          mt: 2,
        }}
      >
        {bidsCount > 0 ? (
          <Typography>{t("BidsCount", { count: bidsCount })}</Typography>
        ) : null}
        <Typography>
          <strong>{priceAmountWithCurrency}</strong>
        </Typography>
      </Box>
      {wasteOffer?.sharedOffer ? (
        <Typography
          variant="body1"
          noWrap
          component="div"
          fontWeight={700}
          mt={1}
        >
          {t("SharedOffer")}
        </Typography>
      ) : null}
      {isExtendedBy5Minutes ? (
        <Typography
          variant="body1"
          noWrap
          component="div"
          fontWeight={700}
          mt={1}
        >
          {t("Extended5Minutes")}
        </Typography>
      ) : null}
    </CardContent>
  );
};
