// @ts-check

import {
    doc,
    addDoc,
    setDoc,
    getDoc,
    collection,
    getDocs,
    query,
    where,
    limit,
    updateDoc,
    writeBatch,
} from "firebase/firestore";
import { db } from "helpers/firebase";
import { createWithDevtools } from "./store.helpers";

const collectionRef = collection(db, "userprofiles");

export const subscribeToSearch = async (userId, data) => {
    const searchesCollection = collection(db, "savedsearches");

    const searchesQuery = query(
        searchesCollection,
        where("location", "==", data.location),
        where("wasteTypeId", "==", data.wasteTypeId)
    );

    const searchesRef = await getDocs(searchesQuery);

    for (const search of searchesRef.docs) {
        const searchData = search.data();
        if (!(userId in searchData.subscribers)) {
            const subscribers = { ...searchData.subscribers };
            subscribers[userId] = true;
            await updateDoc(search.ref, { subscribers });
        }
    }

    if (!searchesRef.docs.length) {
        const subscribers = {};
        subscribers[userId] = true;
        await addDoc(searchesCollection, {
            ...data,
            subscribers,
        });
    }
};

export const unSubscribeSearch = async (userId, data) => {
    const searchesCollection = collection(db, "savedsearches");
    const searchesQuery = query(
        searchesCollection,
        where("location", "==", data.location),
        where("wasteTypeId", "==", data.wasteTypeId)
    );

    const searchesRef = await getDocs(searchesQuery);

    for (const search of searchesRef.docs) {
        const searchData = search.data();
        if (userId in searchData.subscribers) {
            const subscribers = searchData.subscribers;

            delete subscribers[userId];

            await updateDoc(search.ref, {
                subscribers,
            });
        }
    }
};

export const unSubscribeAllSearches = async (userId, savedsearches) => {
    const batch = writeBatch(db);
    const searchesCollection = collection(db, "savedsearches");

    let counter = 0;

    for (const sub of savedsearches) {
        counter += 1;

        const searchQuery = query(
            searchesCollection,
            where("location", "==", sub.location),
            where("wasteTypeId", "==", sub.wasteTypeId)
        );

        const searchesRef = await getDocs(searchQuery);

        for (const search of searchesRef.docs) {
            const searchData = search.data();

            if (userId in searchData.subscribers) {
                const subscribers = searchData.subscribers;

                delete subscribers[userId];

                batch.update(search.ref, {
                    subscribers,
                });

                if (counter % 20 === 0) {
                    await batch.commit();
                }
            }
        }
    }

    if (savedsearches.length) {
        await batch.commit();
    }
};

export const updateProfileData = async (userId, data) => {
    const profileRef = doc(db, "userprofiles", userId);
    const result = await setDoc(
        profileRef,
        { ...data, updatedAt: new Date(), updatedBy: userId },
        { merge: true }
    );
    return result;
};

export function getUserProfile(userId) {
    return getDoc(doc(db, "userprofiles", userId));
}

export const checkByEmail = async (email) => {
    const result = await getDocs(
        query(collectionRef, where("email", "==", email), limit(1))
    );
    if (!result.empty) {
        return true;
    }
    return false;
};

export const checkByPhoneNumber = async (phoneNumber) => {
    const result = await getDocs(
        query(collectionRef, where("phoneNumber", "==", phoneNumber), limit(1))
    );
    if (!result.empty) {
        return true;
    }
    return false;
};

export const getReferredCompanies = async (referCode) => {
    const companiesList = [];

    if (!referCode) {
        return companiesList;
    }

    const profilesRef = await getDocs(
        query(collectionRef, where("referredBy", "==", referCode))
    );    

    for (const ref of profilesRef.docs) {
        const profileData = ref.data();
        if (profileData.company) {
            const companyRef = await getDoc(
                doc(db, "companies", profileData.company)
            );
            const companyData = companyRef.data();
            companiesList.push({
                name: profileData.fullName,
                email: profileData.email,
                company: companyData.name,
            });
        }
    }

    return companiesList;
};

const userProfile = (set) => {
    return {
        userProfile: null,
        fetchProfile: async (userId) => {
            const result = await getUserProfile(userId);
            const data = result.data();
            set(function setUserProfile(draft) {
                draft.userProfile = { id: result.id, ...data };
            });
        },
        updateProfile: async (userId, data) => {
            await updateProfileData(userId, data);
            set(function setUserProfile(draft) {
                draft.userProfile = { ...draft.userProfile, ...data };
            });
        },
    };
};

export const useUserProfile = createWithDevtools(userProfile);
