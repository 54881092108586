// material
import { Box } from "@mui/material";

// ----------------------------------------------------------------------

export default function Logo({ ...other }) {
    return (
        <Box
            component="img"
            alt="logo"
            src="/static/brand/logo_single.svg"
            height={40}
            {...other}
        />
    );
}
