import { createWithDevtools } from "./store.helpers";

const defaultOptions = {
    timeout: 5000,
    variant: "default",
    context: "general",
};

const createNotification = (text, options) => ({
    text,
    options: { ...defaultOptions, ...options },
});

const notifications = (set, get) => {
    const getHandler = (variant) => (text, options) => {
        const notification = createNotification(text, { variant, ...options });
        const add = (draft) => {
            const existing = draft.items.find((n) => n.text === text);
            if (!existing) {
                draft.items.push(notification);
            }
        };
        set(add);
        return notification;
    };

    return {
        items: [],
        setInfo: getHandler("info"),
        setSuccess: getHandler("success"),
        setWarning: getHandler("warning"),
        setError: getHandler("error"),
        dismiss: (text) => {
            const index = get().items.findIndex((n) => n.text === text);
            if (index !== -1) {
                set(function dismiss(draft) {
                    draft.items.splice(index, 1);
                });
            }
        },
        clearByContext: (context) => {
            // clear by context
            set(function clearContext(draft) {
                draft.items = draft.items.filter(
                    (not) => not.options.context !== context
                );
            });
        },
        clearAll: () =>
            // clear all notifications
            set(function clearAll(draft) {
                draft.items = [];
            }),
    };
};

export const filterItemsByContext = (context) => (state) =>
    state.items.filter((item) => item.options.context === context);

export const useNotifications = createWithDevtools(notifications);
