import { useRef, useState } from "react";

import { Icon } from "@iconify/react";
import homeFill from "@iconify/icons-eva/home-fill";
import personFill from "@iconify/icons-eva/person-fill";
import documentOutline from "@iconify/icons-eva/file-text-outline";
import { Link as RouterLink, useNavigate } from "react-router-dom";

import { alpha } from "@mui/material/styles";
import { Button, Box, Divider, MenuItem, Typography } from "@mui/material";
import { signOut } from "helpers/auth";
import { useNotifications } from "store/notifications";
import { MIconButton } from "minimal/components/@material-extend";
import MyAvatar from "minimal/components/MyAvatar";
import MenuPopover from "minimal/components/MenuPopover";
import { useIsMounted } from "../../helpers/useIsMounted";
import { useUserProfile } from "store/userprofile";
import { useTranslation } from "react-i18next";

const MENU_OPTIONS = [
  {
    label: "Home",
    icon: homeFill,
    linkTo: "/",
  },
  {
    label: "Account",
    icon: personFill,
    linkTo: "/account",
  },
  {
    label: "T&C",
    icon: documentOutline,
    linkTo: "/terms-of-service",
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const isMounted = useIsMounted();
  const { setError } = useNotifications();
  const navigate = useNavigate();
  const anchorRef = useRef(null);
  const { userProfile } = useUserProfile();
  const [open, setOpen] = useState(false);

  const { t } = useTranslation();

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      await signOut();
      if (isMounted) {
        navigate("/");
        handleClose();
      }
    } catch (error) {
      console.error(error);
      setError("Unable to logout");
    }
  };

  if (!userProfile) {
    return null;
  }

  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              position: "absolute",
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
            },
          }),
        }}
      >
        <MyAvatar />
      </MIconButton>
      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {userProfile.fullName}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            {userProfile.email}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        {MENU_OPTIONS.map((option) => (
          <MenuItem
            target={option.label === "T&C" ? "_blank" : ""}
            key={option.label}
            to={option.linkTo}
            component={RouterLink}
            onClick={handleClose}
            sx={{ typography: "body2", py: 1, px: 2.5 }}
          >
            <Box
              component={Icon}
              icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24,
              }}
            />

            {t(option.label)}
          </MenuItem>
        ))}

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button
            fullWidth
            color="inherit"
            variant="outlined"
            onClick={handleLogout}
          >
            Logout
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
