import { useCallback, useEffect, useState } from "react";
import { Alert, AlertTitle, Box } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import { useNotifications } from "store/notifications";

export const MessagesBanner = ({ message }) => {
  const [open, setOpen] = useState(true);
  const { dismiss } = useNotifications();

  const handleClearMessage = useCallback(() => {
    dismiss(message.text);
  }, [dismiss, message]);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    let timeout;
    if (Number(message.options.timeout) > 0) {
      timeout = setTimeout(() => {
        handleClose();
      }, message.options.timeout);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [message]);

  return (
    <Box sx={{ position: "relative" }}>
      <Collapse in={open} onExited={handleClearMessage}>
        <Alert
          sx={{ padding: 2 }}
          severity={message.options.variant}
          onClose={
            !message.options.action && message.options.clearable
              ? handleClose
              : null
          }
          action={
            <IconButton size="small" onClick={() => handleClose()}>
              <CloseIcon />
            </IconButton>
            // message.options.action ? (
            //     <Button
            //         color="inherit"
            //         size="small"
            //         onClick={message.options.action.handler}
            //     >
            //         {message.options.action.text}
            //     </Button>
            // ) : null
          }
        >
          {message.options.title && (
            <AlertTitle>{message.options.title}</AlertTitle>
          )}
          <div
            dangerouslySetInnerHTML={{
              __html: message.text,
            }}
          />
        </Alert>
      </Collapse>
    </Box>
  );
};
