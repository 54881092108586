import { Box, Typography, Button } from "@mui/material";
import { GridContainer, GridItem } from "components/layout/Helpers";
import { FIELD_TYPE } from "constants/enums";
import { getMappedAdditionalFields } from "helpers/offer";
import React from "react";
import { useTranslation } from "react-i18next";
import { useWasteTypes } from "wastexchange/store/wastetypes";

const selectAndRangeFilter = (field) =>
  field.type === FIELD_TYPE.SELECT || field.type === FIELD_TYPE.RANGE;

const checkboxFilter = (field) => field.type === FIELD_TYPE.CHECKBOX;

const fileFilter = (field) => field.type === FIELD_TYPE.FILE;

const textFilter = (field) => field.type === FIELD_TYPE.TEXT;

const AdditionalContentsRow = ({ additionalFields }) => {
  const { t } = useTranslation();

  return additionalFields.map((field, index) => {
    let fieldValue = field.value;
    if (field.type === FIELD_TYPE.CHECKBOX) {
      fieldValue = field.value ? t("Common.Yes") : t("Common.No");
    }

    return (
      <React.Fragment key={field.name}>
        <GridItem
          xs={12}
          sm={6}
          container
          direction="column"
          alignItems="flex-start"
          mt={2}
        >
          <Typography variant="body1">
            <strong>{t(field.name)}</strong>
          </Typography>
          <Typography>{t(fieldValue)}</Typography>
        </GridItem>
      </React.Fragment>
    );
  });
};

const WasteOfferAdditionalContents = ({ wasteOffer }) => {
  const { t } = useTranslation();
  const { wasteTypes } = useWasteTypes();

  const wasteType = wasteTypes.get(wasteOffer.wasteType) || {};
  const additionalFields = getMappedAdditionalFields(wasteOffer, wasteType);

  return (
    <GridContainer direction={{ xs: "column", sm: "row" }}>
      <GridItem xs={6}>
        <GridContainer>
          <AdditionalContentsRow
            additionalFields={additionalFields.filter(selectAndRangeFilter)}
          />
          <AdditionalContentsRow
            additionalFields={additionalFields.filter(checkboxFilter)}
          />
          <AdditionalContentsRow
            additionalFields={additionalFields.filter(textFilter)}
          />
        </GridContainer>
      </GridItem>
      <GridItem xs={6}>
        <GridContainer direction="column" alignItems="space-between">
          {additionalFields.filter(fileFilter).map((field) => {
            if (field.value) {
              return (
                <Box xs={6} textAlign="center" mt={2} key={field.name}>
                  <Typography variant="body1">
                    <strong>{t(field.name)}</strong>
                  </Typography>
                  <Button
                    onClick={() => window.open(field.value)}
                    variant="contained"
                    color="primary"
                    sx={{ marginTop: "0.5em" }}
                  >
                    {t("Offer.Download")}
                  </Button>
                </Box>
              );
            }
            return null;
          })}
        </GridContainer>
      </GridItem>
    </GridContainer>
  );
};

export default WasteOfferAdditionalContents;
