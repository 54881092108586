import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { useTranslation } from "react-i18next";

const headCells = [
  {
    id: "offer",
    rightAlign: false,
    disablePadding: true,
    label: "WasteOffer.BidsTable.OfferName",
  },
  {
    id: "created",
    rightAlign: true,
    disablePadding: false,
    label: "WasteOffer.BidsTable.CreatedAt",
  },
  { id: "status", rightAlign: true, disablePadding: false, label: "Status" },
  {
    id: "amount",
    rightAlign: true,
    disablePadding: false,
    label: "WasteOffer.BidsTable.Amount",
  },
  {
    id: "currency",
    rightAlign: true,
    disablePadding: false,
    label: "WasteOffer.BidsTable.Currency",
  },
];

export default function BidsTableHead({
  order,
  orderBy,
  onRequestSort,
  expand = true,
}) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const { t } = useTranslation();

  return (
    <TableHead>
      <TableRow>
        {expand && <TableCell />}

        {headCells.map((headCell, headIndex) => {
          if (!expand && !headIndex) {
            return <></>;
          }
          return (
            <TableCell
              key={headCell.id}
              align={headCell.rightAlign ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {t(headCell.label)}
                {orderBy === headCell.id ? (
                  <span>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}
