import { useState, useEffect } from "react";

import packageJson from "../../package.json";

window._appVersion = packageJson.version;

const semverGreaterThan = (versionA, versionB) => {
  const versionsA = versionA.split(/\./g);

  const versionsB = versionB.split(/\./g);
  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());

    const b = Number(versionsB.shift());
    // eslint-disable-next-line no-continue
    if (a === b) continue;
    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b);
  }
  return false;
};

const refreshCacheAndReload = () => {
  if (caches) {
    // Service worker cache should be cleared with caches.delete()
    caches.keys().then(function (names) {
      for (let name of names) caches.delete(name);
    });
  }
  // delete browser cache and hard reload
  window.location.reload();
};

export const useCacheBuster = (shouldBust) => {
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [isLatestVersion, setIsLatestVersion] = useState(false);

  useEffect(() => {
    const checkMeta = async () => {
      try {
        const response = await fetch("/meta.json");
        const meta = await response.json();
        const latestVersion = meta.version;
        const currentVersion = window._appVersion;
        const shouldForceRefresh = semverGreaterThan(
          latestVersion,
          currentVersion
        );
        setLoaded(true);
        setLoading(false);
        setIsLatestVersion(!shouldForceRefresh);
        if (shouldForceRefresh) {
          refreshCacheAndReload();
        }
      } catch (error) {
        console.error(error);
        setLoaded(true);
        setLoading(false);
      }
    };
    if (shouldBust && !loading && !loaded && !isLatestVersion) {
      checkMeta();
    }
  }, [shouldBust, loading, loaded, isLatestVersion]);

  return { loaded, isLatestVersion, refreshCacheAndReload };
};
