import { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { Card } from "@mui/material";
import { getComparator, stableSort } from "helpers/sort";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useWasteTypes } from "wastexchange/store/wastetypes";
import { getShortAddress } from "helpers/addresses";
import { getOptionsMap, quantityUnits } from "helpers/options";
import { useTranslation } from "react-i18next";

const headCells = [
  {
    id: "name",
    rightAlign: false,
    disablePadding: true,
    label: "OfferTable.OfferName",
  },
  {
    id: "location",
    rightAlign: false,
    disablePadding: false,
    label: "OfferTable.Location",
  },
  {
    id: "bids",
    rightAlign: true,
    disablePadding: false,
    label: "OfferTable.Bids",
  },
  {
    id: "code",
    rightAlign: true,
    disablePadding: false,
    label: "OfferTable.Code",
  },
  {
    id: "price",
    rightAlign: true,
    disablePadding: false,
    label: "OfferTable.StartingPrice",
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const { t } = useTranslation();

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.rightAlign ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ boxShadow: "none" }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {t(headCell.label)}
              {orderBy === headCell.id ? (
                <span>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function OffersTable({ offers }) {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("created");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [rows, setRows] = useState([]);
  const navigate = useNavigate();
  const { wasteTypes } = useWasteTypes();
  const { t } = useTranslation();
  const units = getOptionsMap(quantityUnits, t);

  useEffect(() => {
    const tempRows = [];

    for (const offer of offers) {
      const { highestBid, bidsCount = 0 } = offer;
      const { amount, currencyCode } = highestBid
        ? highestBid
        : offer.startingPrice;
      const priceAmountWithCurrency = `${amount}${
        offer.startingPrice.usePercentage ? "%" : ""
      } ${currencyCode}`;
      const wasteType = wasteTypes.get(offer.wasteType);

      const offerRow = {
        id: offer.id,
        name: wasteType?.label || offer.name,
        code: offer.code,
        price: priceAmountWithCurrency,
        bids: bidsCount,
        image: offer?.images ? offer.images[0] : null,
        location: offer.locations,
      };
      tempRows.push(offerRow);
    }
    setRows(tempRows);
  }, [offers, wasteTypes]);

  const locToStr = ({ location, quantity }) => {
    const { amount, unit } = quantity;
    const quaStr = `${amount} ${t(units.get(unit).label, {
      count: Number(amount),
    })}`;
    return `${quaStr}, ${getShortAddress(location).label}`;
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (event, id) => {
    navigate(`/offers/${id}`);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Card>
      <TableContainer>
        <Table size="medium" aria-label="enhanced table">
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
          />
          <TableBody>
            {stableSort(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, row.id)}
                    tabIndex={-1}
                    key={index}
                  >
                    <TableCell component="th" scope="row" padding="none">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {row.image && (
                          <img
                            alt="OfferImage"
                            style={{
                              width: "60px",
                              height: "60px",
                              objectFit: "cover",
                              borderRadius: "10px",
                              margin: "10px 10px 10px 0",
                            }}
                            src={row.image}
                          />
                        )}
                        {row.name}
                      </div>
                    </TableCell>
                    <TableCell align="left">
                      {row.location.map((l, i) => (
                        <p key={`l-${row.id}-${i}`}>{locToStr(l)}</p>
                      ))}
                    </TableCell>
                    <TableCell align="right">{row.bids}</TableCell>
                    <TableCell align="right">{row.code}</TableCell>
                    <TableCell align="right">{row.price}</TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={rows.length > 50 ? [50, 100, 200] : []}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Card>
  );
}
