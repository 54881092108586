import { Button, IconButton } from "@mui/material";
import FormField from "components/forms/FormField";
import LocationField from "components/forms/LocationField";
import TextField from "components/forms/TextField";
import { GridContainer, GridItem } from "components/layout/Helpers";
import { getTranslatedOptions, quantityUnits } from "helpers/options";
import { useTranslation } from "react-i18next";
import ClearIcon from "@mui/icons-material/Clear";
import { useFieldArray } from "react-hook-form";
import { LocationWithQuantitySchema } from "wastexchange/store/schemas/LocationWithQuantitySchema";
import { FORM_FIELD_NAME } from "constants/enums";

const locationDefaultValues = LocationWithQuantitySchema.cast();

export const WasteOfferFormLocations = ({ control, maxLocations }) => {
  const { t, i18n } = useTranslation();
  const quantityUnitOptions = getTranslatedOptions(quantityUnits, t);
  const { fields, append, remove } = useFieldArray({
    control,
    name: FORM_FIELD_NAME.LOCATIONS,
  });

  const handleRemove = (index) => () => {
    remove(index);
  };

  return (
    <>
      {fields.map((field, index) => {
        const length = fields.length;
        const locationLabel =
          length === 1
            ? t("Common.Location")
            : t("Common.Location_indexed", { index: index + 1 });

        const quantityAmountLabel =
          length === 1
            ? t("QuantityAmount")
            : t("QuantityAmount_indexed", {
                index: index + 1,
              });

        const quantityUnitLabel =
          length === 1
            ? t("QuantityUnit")
            : t("QuantityUnit_indexed", {
                index: index + 1,
              });

        return (
          <GridItem key={field.id}>
            <GridContainer>
              <GridItem xs={length === 1 ? 12 : 11}>
                <GridContainer spacing={2}>
                  <GridItem sm={7}>
                    <FormField
                      name={`locations.${index}.location`}
                      control={control}
                      translatedLabel={locationLabel}
                      t={t}
                      language={i18n.language}
                      Component={LocationField}
                    />
                  </GridItem>
                  <GridItem sm={5}>
                    <GridContainer spacing={2}>
                      <GridItem xs={5}>
                        <FormField
                          name={`locations.${index}.quantity.amount`}
                          control={control}
                          translatedLabel={quantityAmountLabel}
                          Component={TextField}
                          isNumeric
                        />
                      </GridItem>
                      <GridItem xs={7}>
                        <FormField
                          name={`locations.${index}.quantity.unit`}
                          control={control}
                          translatedLabel={quantityUnitLabel}
                          options={quantityUnitOptions}
                          select
                          defaultValue={quantityUnitOptions[0].value}
                          Component={TextField}
                        />
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
              </GridItem>
              {length > 1 ? (
                <GridItem xs={1}>
                  <IconButton onClick={handleRemove(index)}>
                    <ClearIcon />
                  </IconButton>
                </GridItem>
              ) : null}
            </GridContainer>
          </GridItem>
        );
      })}
      {fields.length < maxLocations ? (
        <GridItem>
          <Button
            onClick={() => {
              append(locationDefaultValues);
            }}
          >
            {t("AddLocation", {
              maxLocations,
            })}
          </Button>
        </GridItem>
      ) : null}
    </>
  );
};
