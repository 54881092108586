import {
    collection,
    doc,
    endAt,
    getDoc,
    getDocs,
    orderBy,
    query,
    startAt,
    where,
} from "firebase/firestore";
import {
    geohashForLocation,
    geohashQueryBounds,
    distanceBetween,
} from "geofire-common";
import { db } from "helpers/firebase";

export const getCenter = (lat, lng) => [Number(lat), Number(lng)];

export const getGeoHash = (lat, lng) => geohashForLocation(getCenter(lat, lng));

export const queryByGeoHash = async (
    center,
    radiusInM,
    collectionName,
    userId,
    mine = false,
    filterBy
) => {
    const queryConstraints = [];

    const dataCollection = collection(db, collectionName);

    if (mine) {
        queryConstraints.push(where("createdBy", "==", userId));
    }

    if (filterBy) {
        for (const key of Object.keys(filterBy)) {
            if (key !== "wasteType") {
                queryConstraints.push(where(key, "==", filterBy[key]));
            } else if (key === "wasteType" && filterBy[key].length) {
                queryConstraints.push(where(key, "in", filterBy[key]));
            }
        }
    }

    // Each item in 'bounds' represents a startAt/endAt pair. We have to issue
    // a separate query for each pair. There can be up to 9 pairs of bounds
    // depending on overlap, but in most cases there are 4.
    const bounds = geohashQueryBounds(center, radiusInM);
    const promises = [];

    for (const b of bounds) {
        const q = query(
            dataCollection,
            orderBy("geohash"),
            startAt(b[0]),
            endAt(b[1]),
            ...queryConstraints
        );
        promises.push(getDocs(q));
    }

    // Collect all the query results together into a single list
    const snapshots = await Promise.all(promises);
    const matchingDocs = new Map();

    for await (const snap of snapshots) {
        for await (const document of snap.docs) {
            const lat = document.get("location.lat");
            const lng = document.get("location.lon");

            // We have to filter out a few false positives due to GeoHash
            // accuracy, but most will match
            const distanceInKm = distanceBetween(getCenter(lat, lng), center);
            const distanceInM = distanceInKm * 1000;
            if (distanceInM <= radiusInM) {
                // todo: multi location
                const data = document.data();
                if (data.isParent) {
                    matchingDocs.set(document.id, {
                        id: document.id,
                        ...document.data(),
                    });
                } else if (!data.isParent && data.parentId) {
                    if (!matchingDocs.has(data.parentId)) {
                        const parent = await getDoc(
                            doc(dataCollection, data.parentId)
                        );
                        if (parent.exists) {
                            matchingDocs.set(parent.id, {
                                id: parent.id,
                                ...parent.data(),
                            });
                        }
                    }
                }
            }
        }
    }
    return Array.from(matchingDocs.values());
};
