import { Box, LinearProgress, Link, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { AsyncResourceContent } from "use-async-resource";
import { useAsyncResource } from "use-async-resource";
import { Heading } from "components/typography/Helpers";
import { useWasteOffers } from "wastexchange/store/wasteoffers";
import { WasteOffersLoader } from "wastexchange/components/WasteOffersLoader";
import { WasteOffersFilter } from "wastexchange/components/WasteOffersFilter";
import { useEffect, useState } from "react";
import { resourceCache } from "use-async-resource";
import { subscribeToSearch, useUserProfile } from "store/userprofile";
import { useWasteTypes } from "wastexchange/store/wastetypes";
import { useNotifications } from "store/notifications";
import { getGeoHash } from "wastexchange/store/geofire";
import { ClosedOffersView } from "wastexchange/components/ClosedOffersView";

const WasteOffersList = ({ userId, role, type = "List" }) => {
  const { t } = useTranslation();
  const { setInfo } = useNotifications();
  const { userProfile, updateProfile, fetchProfile } = useUserProfile();
  const { wasteTypes } = useWasteTypes();
  const { filter, fetchList, fetchMyList, fetchMyClosedList } =
    useWasteOffers();
  const fetchMethod =
    type === "MyList"
      ? fetchMyList
      : type === "MyClosedList"
        ? fetchMyClosedList
        : fetchList;
  // calling useAsyncResource without any additional parameters results in lazy loading
  const [wasteOffersReader, handleFetch] = useAsyncResource(fetchMethod);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    handleFetch(userId, filter);
    return () => {
      // clear cache for filters change to trigger new fetch
      resourceCache(fetchMethod).clear();
    };
  }, [fetchMethod, filter, handleFetch, userId]);

  const saveSearch = async () => {
    setIsSubmitting(true);

    const result = window.confirm(t("Common.ConfirmSaveSearch"));

    if (!result) {
      setIsSubmitting(false);
      return;
    }

    const inSaved = (saved, n) =>
      saved.find((s) => {
        return (
          s.geoHash === n.geoHash &&
          s.radius === n.radius &&
          s.wasteTypeId === n.wasteTypeId
        );
      });

    await fetchProfile(userId);

    const newSearch = {
      wasteType: "",
      wasteTypeId: "",
      location: filter.location ? filter.location : "",
      offerType: filter.offerType ? filter.offerType : "",
      radius: filter.location ? filter.radius : 0,
      geoHash: filter.location
        ? getGeoHash(filter.location.lat, filter.location.lon)
        : "",
    };

    // NOTE: generate new list with matching wasteType ids
    const wasteTypeList = Array.from(wasteTypes.values()).filter((obj) =>
      filter.wasteType.includes(obj.id)
    );

    const toProfile = userProfile.savedsearches
      ? [...userProfile.savedsearches]
      : [];

    // NOTE: if no wasteTypes just save without it
    if (!wasteTypeList.length) {
      if (!inSaved(toProfile, newSearch)) {
        await subscribeToSearch(userId, newSearch);
        toProfile.push(newSearch);
      }
    }

    // NOTE: if there are wasteTypes add them with locations
    for (const wasteType of wasteTypeList) {
      const newTypeSearch = {
        ...newSearch,
        wasteType: wasteType.label,
        wasteTypeId: wasteType.id,
      };

      if (!inSaved(toProfile, newTypeSearch)) {
        await subscribeToSearch(userId, newTypeSearch);
        toProfile.push(newTypeSearch);
      }
    }

    await updateProfile(userId, {
      ...userProfile,
      savedsearches: toProfile,
    });

    setInfo(t("Success.SearchAdd"));

    setIsSubmitting(false);
  };

  if (type === "MyClosedList") {
    return (
      <>
        <WasteOffersFilter closed="true" />
        <AsyncResourceContent
          fallback={<LinearProgress />}
          errorMessage={(e) =>
            t("Error.DataFetch", {
              message: t(`FirebaseErrors.${e}`),
            })
          }
        >
          <ClosedOffersView userId={userId} dataReader={wasteOffersReader} />
        </AsyncResourceContent>
      </>
    );
  }

  return (
    <Box p={2}>
      <Heading gutterBottom>{t(`WasteOffer.${type}`)}</Heading>
      <WasteOffersFilter />
      <AsyncResourceContent
        fallback={<LinearProgress />}
        errorMessage={(e) =>
          t("Error.DataFetch", { message: t(`FirebaseErrors.${e}`) })
        }
      >
        <WasteOffersLoader
          dataReader={wasteOffersReader}
          userId={userId}
          role={role}
        />
        {type === "List" && (
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 40,
            }}
          >
            <Typography>{t("Common.SaveQuestion")}</Typography>
            <Link
              component="button"
              variant="body1"
              style={{ marginLeft: 5, textDecoration: "none" }}
              onClick={() => saveSearch()}
              disabled={isSubmitting}
            >
              {t("Common.SaveSearch")}
            </Link>
          </Box>
        )}
      </AsyncResourceContent>
    </Box>
  );
};

// has to be a default export for dynamic (lazy) import
export default WasteOffersList;
