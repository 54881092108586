import { currencies as currencyJson } from "currencies.json";
export const CURRENCIES = currencyJson
  .filter((c) => c.code === "EUR")
  .map((curr) => ({
    value: curr.code,
    label: curr.name,
  }));

export const PERCENTAGES = [
  { value: "FOB RDAM", label: "FOB RDAM" },
  {
    value: "LME Steel Scrap CFR Turkey (Platts)",
    label: "LME Steel Scrap CFR Turkey (Platts)",
    purchaseOrderLable:
      "LME Steel Scrap CFR Turkey (Platts). Month 2 Closing Price (day-delayed)",
  },
  {
    label: "LME Aluminium",
    value: "LME Aluminium",
    purchaseOrderLable: "LME Aluminium. 3-month Closing Price (day delayed)",
  },
  {
    label: "LME Copper",
    value: "LME Copper",
    purchaseOrderLable: "LME Copper. 3-month Closing Price (day delayed))",
  },
  {
    label: "LME Lead",
    value: "LME Lead",
    purchaseOrderLable: "LME Lead. 3-month Closing Price (day delayed)",
  },
];

export const DELIVERY_OPTIONS = [
  { value: "DAP", label: "DAP" },
  { value: "FCA", label: "FCA" },
];

