import { Container } from "@mui/material";
import OffersTable from "components/OffersTable/OffersTable";
import { Heading } from "components/typography/Helpers";
import { useTranslation } from "react-i18next";
import { OfferStatuses } from "wastexchange/store/schemas/WasteOfferSchema";
import { useWasteOffers } from "wastexchange/store/wasteoffers";

export const ClosedOffersView = ({ userId, dataReader }) => {
  const { t } = useTranslation();

  const { offersList: list } = useWasteOffers();
  const archivedOffers = list.filter((offer) => {
    return offer.status === OfferStatuses.ARCHIVED;
  });
  const closedOffers = list.filter((offer) => {
    return offer.status === OfferStatuses.CLOSED;
  });

  dataReader();

  return (
    <Container maxWidth="lg">
      <Container maxWidth="lg">
        <Heading gutterBottom>{t(`WasteOffer.MyClosedList`)}</Heading>
        <OffersTable offers={closedOffers} />
      </Container>
      <br />
      <Container maxWidth="lg">
        <Heading gutterBottom>{t(`WasteOffer.MyArchivedOffers`)}</Heading>
        <OffersTable offers={archivedOffers} />
      </Container>
    </Container>
  );
};
