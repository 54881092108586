import React, { forwardRef, useRef } from "react";
import { Stack, Typography, Button, FormHelperText } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

const File = (props, ref) => {
  const { name, label, acceptFiles, value, onChange, errors } = props;
  const inputRef = useRef();
  const { t } = useTranslation();
  const fileName = value?.name || "";

  const error = errors[name];

  useEffect(() => {
    if (error) {
      const inputElement = document.getElementById(name);
      window.scrollTo({
        behavior: "smooth",
        top:
          inputElement.getBoundingClientRect().top -
          document.body.getBoundingClientRect().top -
          150,
      });
    }
  }, [error, name]);

  const fileAddHandler = (e) => {
    onChange(e.target.files[0]);
  };

  return (
    <>
      <Stack direction="row" spacing={2} alignItems="center" ref={inputRef}>
        <Typography>{t(label)}:</Typography>
        <Button
          id={name}
          variant="contained"
          component="label"
          color={error && "error"}
        >
          {t("Uploads.Upload")}
          <input
            hidden
            accept={acceptFiles}
            type="file"
            ref={ref}
            onChange={fileAddHandler}
          />
        </Button>
        {error && <FormHelperText error>{t(error.message)}</FormHelperText>}
      </Stack>
      {fileName && <Typography variant="caption">File: {fileName}</Typography>}
    </>
  );
};

export default forwardRef(File);
