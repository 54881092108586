import { countries } from "helpers/options";
import FormField from "./FormField";
import TextField from "./TextField";

export const CountrySelect = ({ control, name = "countryCode", ...rest }) => {
    return (
        <FormField
            name={name}
            control={control}
            label="Common.Country"
            options={countries}
            select
            Component={TextField}
            {...rest}
        />
    );
};
