import { Link as RouterLink } from "react-router-dom";
import { experimentalStyled as styled } from "@mui/material/styles";
import { Container, Stack } from "@mui/material";

import Terms from "./Terms";
import Page from "wastexchange/components/Page";
import Logo from "../../../minimal/components/Logo";

const RootStyle = styled(Page)(({ theme }) => ({
  display: "flex",
  minHeight: "100%",
  alignItems: "center",
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10),
}));

const HeaderStyle = styled("header")(({ theme }) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  width: "100%",
  position: "absolute",
  padding: theme.spacing(3, 3, 0),
  [theme.breakpoints.up("sm")]: { padding: theme.spacing(5, 5, 0) },
}));

export default function TermsAndConditions() {
  return (
    <RootStyle title="T&C">
      <HeaderStyle>
        <RouterLink to="/">
          <Logo />
        </RouterLink>
      </HeaderStyle>

      <Container>
        <Stack spacing={1} mt={2}>
          <Terms />
        </Stack>
      </Container>
    </RootStyle>
  );
}
