import { yupResolver } from "@hookform/resolvers/yup";
import { Box, ToggleButtonGroup, ToggleButton, Stack } from "@mui/material";
import { WasteOfferSchema } from "wastexchange/store/schemas/WasteOfferSchema";
import FormField from "components/forms/FormField";
import TextField from "components/forms/TextField";
import { GridItem } from "components/layout/Helpers";
import { GridContainer } from "components/layout/Helpers";
import { Heading } from "components/typography/Helpers";
import { useAuth } from "helpers/auth";
import { useState, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNotifications } from "store/notifications";
import SendIcon from "@mui/icons-material/Send";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { useUploads } from "helpers/uploads";
import { useWasteTypes } from "wastexchange/store/wastetypes";
import {
  createOffer,
  deleteOffer,
  updateOffer,
} from "wastexchange/store/wasteoffers";
import { PriceInputField } from "components/forms/PriceInputField";
import { UploadMultiple } from "minimal/components/UploadMultiple";
import { UploadedImages } from "wastexchange/components/UploadedImages";
import { WasteOfferFormLocations } from "./WasteOfferFormLocations";
import { LoadingButton } from "@mui/lab";
import DateTimePicker from "components/forms/DateTimePicker";
import {
  FORM_FIELD_NAME,
  OFFER_TYPE,
  WASTE_CATEGORY,
} from "../../../constants/enums";
import { startCaseCapitalLetter } from "helpers/startCaseCapitalLetter";
import _ from "lodash";
import WasteOfferFormAdditionalFields from "./components/WasteOfferFormAdditionalFields";
import { useEffect, useRef } from "react";
import SwitchWithLabel from "components/forms/SwitchWithLabel";

const maxLocations = 5;

const WasteOfferForm = ({ isEdit, offerId, formDataValues = {} }) => {
  const navigate = useNavigate();
  const [isPosting, setIsPosting] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { setError, setInfo } = useNotifications();
  const { progress, handleUpload, uploadTasks } = useUploads();
  const { wasteTypes } = useWasteTypes();
  const locationsRef = useRef(0);

  const { user } = useAuth();
  const [files, setFiles] = useState([]);

  const { t, i18n } = useTranslation();

  const postSuccess = t("PostSuccess");
  const postError = t("PostError");

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: formDataValues,
    resolver: yupResolver(WasteOfferSchema, {
      abortEarly: false,
      recursive: true,
    }),
  });

  const wasteCategory = watch(FORM_FIELD_NAME.WASTE_CATEGORY);
  const wasteType = watch(FORM_FIELD_NAME.WASTE_TYPE);
  const offerType = watch(FORM_FIELD_NAME.OFFER_TYPE, OFFER_TYPE.SELL);
  const sharedOffer = watch("sharedOffer");
  const autoExtend = watch("autoExtend");

  const locations = watch("locations");
  const usePercentage = watch("startingPrice.usePercentage");

  const isPriceWithPercentage = useMemo(() => {
    if (wasteCategory.toLowerCase() === WASTE_CATEGORY.METAL.toLowerCase()) {
      return true;
    }
  }, [wasteCategory]);

  useEffect(() => {
    setValue("startingPrice.currencyCode", "");
  }, [usePercentage, setValue]);

  useEffect(() => {
    const subscription = watch((_, { name }) => {
      if (name === FORM_FIELD_NAME.WASTE_CATEGORY) {
        setValue(FORM_FIELD_NAME.WASTE_TYPE, "");
      }
    });
    return () => subscription.unsubscribe();
  }, [setValue, watch]);

  useEffect(() => {
    reset({
      ...formDataValues,
      wasteCategory,
      wasteType,
      sharedOffer,
      offerType,
      autoExtend,
    });
  }, [
    formDataValues,
    reset,
    wasteCategory,
    wasteType,
    sharedOffer,
    offerType,
    autoExtend,
  ]);

  useEffect(() => {
    if (sharedOffer && locationsRef.current === 0 && locations.length > 1) {
      locationsRef.current = 1;
      setValue("locations", [locations[0]]);
    } else {
      locationsRef.current = 0;
    }
  }, [sharedOffer, setValue, locations]);

  const onSubmit = async (data) => {
    if (Object.keys(errors).length > 0) {
      return;
    }
    const filteredData = _.omit(data, "wasteTypes");

    setIsSubmitting(true);
    try {
      if (!user) {
        setIsSubmitting(false);
        throw new Error("Must be signed in");
      }
      handlePostData({ ...filteredData, language: i18n.language });
    } catch (err) {}
    setIsSubmitting(false);
  };

  const handlePostData = async (data) => {
    if (!user) {
      return null;
    }

    setIsPosting(true);
    try {
      const locations = [...data.locations];
      // save first location as default location
      const mainLocation = locations[0].location;
      const quantity = locations[0].quantity;

      const pdfs = [];
      const formData = {};

      for (const field in data) {
        if (data[field].type === "application/pdf") {
          pdfs.push({ name: field, pdf: data[field] });
        } else {
          formData[field] = data[field];
        }
      }

      const saveRef = isEdit
        ? await updateOffer(user.uid, offerId, {
            ...formData,
            offerType,
            wasteCategory,
            location: mainLocation,
            quantity,
            isParent: true,
          })
        : await createOffer(user.uid, {
            ...formData,
            offerType,
            wasteCategory,
            location: mainLocation,
            quantity,
            isParent: true,
          });

      // get the parent waste offer id
      const refId = isEdit ? offerId : saveRef.id;

      if (locations.length > 1) {
        // save remaining locations as multiple waste offers with parent
        const locationIndices = Array.from(Array(maxLocations).keys());
        for await (const i of locationIndices) {
          if (i === 0) {
            continue; // parent already saved
          }
          const subOfferId = `${refId}_${i}`;
          const location = locations[i] || null;
          if (!location) {
            // try and delete all previously saved locations not included in the update
            await deleteOffer(user.uid, subOfferId);
            continue;
          }
          // save only location, quantity and parentId for the subOffer
          const subOfferData = {
            wasteType: data.wasteType,
            wasteCategory: wasteCategory,
            offerType: data.offerType || offerType,
            sharedOffer: data.sharedOffer || false,
            location: location.location,
            quantity: location.quantity,
            parentId: refId,
            createdAt: new Date(),
            createdBy: user.uid,
          };
          await updateOffer(user.uid, subOfferId, subOfferData);
        }
      }

      for (const pdf of pdfs) {
        const uploadResult = await handleUpload(
          `wasteoffers/${user.uid}/${refId}`,
          [pdf.pdf]
        );
        const successUploadUrls = uploadResult
          .filter((r) => !!r.success)
          .map((r) => r.url);

        await updateOffer(user.uid, refId, {
          [`${pdf.name}`]: successUploadUrls[0],
        });
      }

      // upload files
      if (files.length) {
        const existingImages = isEdit ? formDataValues.images || [] : [];

        const uploadResult = await handleUpload(
          `wasteoffers/${user.uid}/${refId}`,
          files
        );
        const successUploadUrls = uploadResult
          .filter((r) => !!r.success)
          .map((r) => r.url);

        await updateOffer(user.uid, refId, {
          images: [...existingImages, ...successUploadUrls],
        });
      }
      setFiles([]);
      setInfo(postSuccess);
      navigate("/offers/my");
    } catch (err) {
      console.error(err);
      setError(postError);
    } finally {
      setIsPosting(false);
    }
  };

  const title = t(isEdit ? "WasteOffer.Edit" : "WasteOffer.Add");

  const wasteCategoryOptions = _.values(WASTE_CATEGORY).map((category) => {
    return {
      label: i18n.t(`WasteCategories.${startCaseCapitalLetter(category)}`),
      value: category,
    };
  });

  const filteredWasteTypes = Array.from(wasteTypes.values())
    .filter((wt) => wt.category === wasteCategory)
    .map((wt) => {
      return { label: wt[`${i18n.language}_label`], value: wt.id };
    });

  useEffect(() => {
    if (wasteCategory.toLowerCase() === WASTE_CATEGORY.WOOD.toLowerCase()) {
      setValue("wasteTypes", filteredWasteTypes);
    }
  }, [wasteType, wasteCategory, filteredWasteTypes, setValue, t]);

  return (
    <Box p={2} sx={{ maxWidth: { md: 760 } }}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Heading>{title}</Heading>
      <Box pt={2}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <GridContainer spacing={2}>
            <GridItem>
              <Stack spacing={1} direction="row">
                <ToggleButtonGroup
                  size="large"
                  color="success"
                  value={offerType}
                  exclusive
                  onChange={(event) => {
                    setValue(FORM_FIELD_NAME.OFFER_TYPE, event.target.value);
                  }}
                  aria-label="OfferType"
                >
                  <ToggleButton
                    value={OFFER_TYPE.SELL}
                    style={{ width: "100px" }}
                  >
                    {t("OfferTypes.Sell")}
                  </ToggleButton>
                  <ToggleButton
                    value={OFFER_TYPE.BUY}
                    style={{ width: "100px" }}
                  >
                    {t("OfferTypes.Buy")}
                  </ToggleButton>
                </ToggleButtonGroup>
                <FormField
                  label="SharedOffer"
                  name="sharedOffer"
                  Component={SwitchWithLabel}
                  control={control}
                />

                <FormField
                  label="AutoExtend"
                  name="autoExtend"
                  Component={SwitchWithLabel}
                  control={control}
                />
              </Stack>
            </GridItem>
            <GridItem>
              <FormField
                name={FORM_FIELD_NAME.WASTE_CATEGORY}
                control={control}
                label="WasteCategory"
                options={wasteCategoryOptions}
                select
                Component={TextField}
              />
            </GridItem>
            <GridItem>
              <FormField
                name={FORM_FIELD_NAME.WASTE_TYPE}
                control={control}
                label="WasteType"
                options={filteredWasteTypes}
                select
                Component={TextField}
                disabled={filteredWasteTypes.length === 0}
              />
            </GridItem>
            <WasteOfferFormAdditionalFields
              {...{ wasteType, control, watch, setValue }}
            />
            <GridItem>
              <FormField
                name="description"
                control={control}
                label="Description"
                multiline
                minRows={4}
                maxRows={4}
                Component={TextField}
              />
            </GridItem>
            <WasteOfferFormLocations
              control={control}
              maxLocations={sharedOffer ? 1 : 5}
            />
            <GridItem>
              <FormField
                name="expiresAt"
                control={control}
                label="ExpiresAt"
                Component={DateTimePicker}
              />
            </GridItem>
            <GridItem>
              <PriceInputField
                withPercentage={isPriceWithPercentage}
                control={control}
                context="startingPrice"
                labelContext="StartingPrice"
                usePercentage={usePercentage}
              />
            </GridItem>

            <GridItem>
              <UploadMultiple
                value={files}
                onChange={setFiles}
                progress={progress}
                uploadTasks={uploadTasks}
                disabled={isPosting}
                accept="image/*"
              />
            </GridItem>
            {isEdit && formDataValues?.images ? (
              <GridItem>
                <UploadedImages images={formDataValues.images} />
              </GridItem>
            ) : null}
            <GridItem
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <LoadingButton
                type="submit"
                loading={isSubmitting}
                variant="contained"
                color="primary"
                endIcon={<SendIcon />}
                disabled={isPosting}
              >
                {t("Common.Save")}
              </LoadingButton>
            </GridItem>
          </GridContainer>
        </form>
      </Box>
    </Box>
  );
};

export default WasteOfferForm;
