import { Icon } from "@iconify/react";
import { useDropzone } from "react-dropzone";
import { useCallback, useState } from "react";
import roundAddAPhoto from "@iconify/icons-ic/round-add-a-photo";

// material
import { alpha, experimentalStyled as styled } from "@mui/material/styles";
import {
  Box,
  Typography,
  FormHelperText,
  CircularProgress,
} from "@mui/material";
import prettyBytes from "pretty-bytes";
import { useUploads } from "helpers/uploads";
import { useAuth } from "helpers/auth";
import { useNotifications } from "store/notifications";

const PHOTO_SIZE = 3145728; // bytes
const FILE_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png"];

const RootStyle = styled("div")(({ theme }) => ({
  width: 144,
  height: 144,
  margin: "auto",
  borderRadius: "50%",
  padding: theme.spacing(1),
  border: `1px dashed ${theme.palette.grey[500_32]}`,
}));

const DropZoneStyle = styled("div")({
  zIndex: 0,
  width: "100%",
  height: "100%",
  outline: "none",
  display: "flex",
  overflow: "hidden",
  borderRadius: "50%",
  position: "relative",
  alignItems: "center",
  justifyContent: "center",
  "& > *": { width: "100%", height: "100%" },
  "&:hover": {
    cursor: "pointer",
    "& .placeholder": {
      zIndex: 9,
    },
  },
});

const LoadingStyle = styled("div")(({ theme }) => ({
  zIndex: 99,
  display: "flex",
  alignItems: "center",
  position: "absolute",
  justifyContent: "center",
  backgroundColor: alpha(theme.palette.grey[900], 0.72),
}));

const PlaceholderStyle = styled("div")(({ theme }) => ({
  display: "flex",
  position: "absolute",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "center",
  color: theme.palette.text.secondary,
  backgroundColor: theme.palette.background.neutral,
  transition: theme.transitions.create("opacity", {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "&:hover": {
    opacity: 0.72,
  },
}));

export const UploadAvatar = ({
  disabled,
  caption,
  url,
  onChange,
  ...other
}) => {
  const { user } = useAuth();
  const { setError } = useNotifications();
  const [file, setFile] = useState(null);
  const [isError, setIsError] = useState(null);
  const { progress, handleUpload } = useUploads();

  const handleDrop = useCallback(
    // eslint-disable-next-line consistent-return
    async (acceptedFiles) => {
      const file = acceptedFiles[0];

      const checkSize = file.size < PHOTO_SIZE;
      const checkType = FILE_FORMATS.includes(file.type);

      if (!checkSize) {
        setIsError("size-invalid");
      }

      if (!checkType) {
        setIsError("type-invalid");
      }

      try {
        if (!user) {
          throw new Error("Must be signed in");
        }
        if (checkSize && checkType) {
          setFile(file);
          const uploadResult = await handleUpload(
            `/userprofiles/${user.uid}/pictures/`,
            [file]
          );
          if (!uploadResult[0].success) {
            throw new Error("Uploads.Error");
          }
          onChange(uploadResult[0].url);
        }
      } catch (error) {
        setError(error.message);
      }
    },
    [handleUpload, onChange, setError, user]
  );

  const { getRootProps, getInputProps, isDragActive, isDragReject } =
    useDropzone({
      onDrop: handleDrop,
      multiple: false,
      disabled,
    });

  const fileProgress = progress.get(file?.name);
  const isLoading = Number(fileProgress) > 0 && Number(fileProgress) < 100;

  return (
    <>
      <RootStyle {...other}>
        <DropZoneStyle
          {...getRootProps()}
          sx={{
            ...(isDragActive && { opacity: 0.72 }),
            ...((isDragReject || isError) && {
              color: "error.main",
              borderColor: "error.light",
              bgcolor: "error.lighter",
            }),
          }}
        >
          <input {...getInputProps()} />

          {isLoading && (
            <LoadingStyle>
              <CircularProgress
                size={32}
                thickness={2.4}
                variant="determinate"
                value={fileProgress}
              />
            </LoadingStyle>
          )}

          {url && (
            <Box
              component="img"
              alt="avatar"
              src={url}
              sx={{ zIndex: 8, objectFit: "cover" }}
            />
          )}

          <PlaceholderStyle
            className="placeholder"
            sx={{
              ...(file && {
                opacity: 0,
                color: "common.white",
                bgcolor: "grey.900",
                "&:hover": { opacity: 0.72 },
              }),
            }}
          >
            <Box
              component={Icon}
              icon={roundAddAPhoto}
              sx={{ width: 24, height: 24, mb: 1 }}
            />
            <Typography variant="caption">
              {file ? "Update photo" : "Upload photo"}
            </Typography>
          </PlaceholderStyle>
        </DropZoneStyle>
      </RootStyle>

      <Box sx={{ display: "flex", justifyContent: "center" }}>
        {isError === "size-invalid" && (
          <FormHelperText error>{`File is larger than ${prettyBytes(
            PHOTO_SIZE
          )}`}</FormHelperText>
        )}

        {isError === "type-invalid" && (
          <FormHelperText error>
            File type must be *.jpeg, *.jpg, *.png, *.gif
          </FormHelperText>
        )}
      </Box>

      <Typography
        variant="caption"
        sx={{
          mt: 2,
          mb: 5,
          mx: "auto",
          display: "block",
          textAlign: "center",
          color: "text.secondary",
        }}
      >
        {!caption ? (
          <>
            Allowed *.jpeg, *.jpg, *.png, *.gif
            <br /> Max size of {prettyBytes(PHOTO_SIZE)}
          </>
        ) : (
          caption
        )}
      </Typography>
    </>
  );
};
