import { useMemo } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Stack,
  Typography,
} from "@mui/material";
import {
  updateBid,
  updateBidStatus,
  updateOffer,
  useWasteOffers,
} from "wastexchange/store/wasteoffers";
import { GridItem } from "components/layout/Helpers";
import { formatDateTime } from "helpers/locale";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BidStatuses } from "store/schemas/WasteOfferBidSchema";
import { OfferStatuses } from "wastexchange/store/schemas/WasteOfferSchema";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import GetAppIcon from "@mui/icons-material/GetApp";
import { useNotifications } from "store/notifications";
import StarIcon from "@mui/icons-material/Star";
import { PaymentDeadline } from "./PaymentDeadline";
import { PDFDownloadLink, pdf } from "@react-pdf/renderer";
import PurchaseOrder from "components/PDF/PurchaseOrder";
import { getOrderData } from "helpers/pdf";
import { useInView } from "react-intersection-observer";
import { BidCommentSection } from "./BidCommentSection";
import { BidNegotiationForm } from "./BidNegotiationForm";
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getMetadata,
} from "firebase/storage";
import { httpsCallable } from "firebase/functions";
import { functionsInstance } from "helpers/firebase";
import OfferInvoice from "components/PDF/OfferInvoice";
import { useCompanies } from "store/companies";
import { getOptionsMap, quantityUnits } from "helpers/options";

const sendOfferPO = httpsCallable(functionsInstance, "wasteoffers-sendOfferPO");
const sendOfferInvoice = httpsCallable(
  functionsInstance,
  "wasteoffers-sendOfferInvoice"
);

const storage = getStorage();

export const getStatusProps = (status) => {
  switch (status) {
    case BidStatuses.NEW: {
      return { color: "info", variant: "outlined" };
    }
    case BidStatuses.READ: {
      return { color: "secondary", variant: "filled" };
    }
    case BidStatuses.ACCEPTED: {
      return { color: "success", variant: "filled" };
    }
    case BidStatuses.REJECTED: {
      return { color: "error", variant: "filled" };
    }
    default: {
      return { color: "secondary", variant: "outlined" };
    }
  }
};

// TODO: this component need a huge refactor!!
export const BidItem = ({
  userId,
  wasteOffer,
  bid,
  hasAccepted,
  setHasAccepted,
  commodity,
}) => {
  const { setError, setSuccess } = useNotifications();
  const { companies, fetchCompany } = useCompanies();
  const [bidStatus, setBidStatus] = useState(bid.status || BidStatuses.NEW);
  const [offerStatus, setOfferStatus] = useState(
    wasteOffer.status === OfferStatuses.OPEN
  );
  const { highestBid } = useWasteOffers();

  const isSharedOffer = useMemo(() => {
    return wasteOffer.sharedOffer || false;
  }, [wasteOffer.sharedOffer]);

  const wasteOfferQuantity = useMemo(() => {
    return (
      wasteOffer.locations.reduce((acc, l) => acc + l.quantity.amount, 0) || 0
    );
  }, [wasteOffer.locations]);

  const bidQuantity = bid?.quantity || wasteOfferQuantity;

  const filename = useMemo(() => {
    if (wasteOffer?.sharedOffer) {
      return `Purchase_Order_${wasteOffer.code}_${bid.id}.pdf`;
    }
    return `Purchase_Order_${wasteOffer.code}.pdf`;
  }, [wasteOffer, bid]);
  const invoice_filename = useMemo(() => {
    if (wasteOffer?.sharedOffer) {
      return `Offer_Invoice_${wasteOffer.code}_${bid.id}.pdf`;
    }
    return `Offer_Invoice_${wasteOffer.code}.pdf`;
  }, [wasteOffer, bid.id]);
  const [offerData, setOfferData] = useState(null);
  const [pdfBlob, setPdfBlob] = useState(null);
  const [reading, setReading] = useState(false);
  const [justAccepted, setJustAccepted] = useState(false);
  const [sentEmail, setSentEmail] = useState(false);
  const statusProps = getStatusProps(bidStatus);
  const { t, i18n } = useTranslation();
  const formattedDate = formatDateTime(bid.createdAt, i18n.language);
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  const isWasteOfferOwner = userId === wasteOffer?.createdBy;
  const showInvoice =
    isWasteOfferOwner &&
    (bidStatus === BidStatuses.ACCEPTED ||
      wasteOffer.status === OfferStatuses.ARCHIVED);

  const units = getOptionsMap(quantityUnits, t);

  useEffect(() => {
    if (wasteOffer.company) {
      fetchCompany(wasteOffer.company);
    }
  }, [fetchCompany, wasteOffer.company]);

  const company = wasteOffer.company ? companies.get(wasteOffer.company) : null;

  const readingBid = useCallback(async () => {
    if (isWasteOfferOwner && bidStatus === BidStatuses.NEW) {
      setReading(true);
      try {
        await updateBidStatus(userId, wasteOffer.id, bid.id, BidStatuses.READ);
        setBidStatus(BidStatuses.READ);
      } catch (err) {
        console.error("Error setting bid status: ", err);
      }
      setReading(false);
    }
  }, [bid, wasteOffer, bidStatus, isWasteOfferOwner, userId]);

  const collectPdfData = useCallback(async () => {
    const data = await getOrderData(wasteOffer, bid);
    setOfferData(data);
  }, [bid, wasteOffer]);

  const sendPdfEmail = useCallback(async () => {
    const reader = new FileReader();
    reader.readAsDataURL(pdfBlob);
    reader.onloadend = async () => {
      await sendOfferPO({
        users: hasAccepted ? [wasteOffer?.createdBy, bid.createdBy] : [userId],
        code: wasteOffer.code,
        content: reader.result,
        filename,
      });
    };
  }, [pdfBlob, hasAccepted, bid, userId, filename, wasteOffer]);

  const sendInvoiceEmail = useCallback(
    async (pdf_blob) => {
      const reader = new FileReader();
      reader.readAsDataURL(pdf_blob);
      reader.onloadend = async () => {
        const file = new File([pdf_blob], invoice_filename, {
          lastModified: new Date().getTime(),
        });

        const storageInvoiceFilename = `/offerinvoices/${invoice_filename}/`;

        const fileRef = storageRef(storage, storageInvoiceFilename);

        try {
          await getMetadata(fileRef);
        } catch (err) {
          await uploadBytes(fileRef, file);
        }

        await sendOfferInvoice({
          users: [wasteOffer.createdBy],
          code: wasteOffer.code,
          content: reader.result,
          filename: invoice_filename,
        });
      };
    },
    [wasteOffer, invoice_filename]
  );

  useEffect(() => {
    readingBid();
  }, [inView, readingBid]);
  useEffect(() => {}, [highestBid]);

  useEffect(() => {
    if (bidStatus === BidStatuses.ACCEPTED) {
      collectPdfData();
    }
  }, [bidStatus, collectPdfData]);

  useEffect(() => {
    setOfferStatus(wasteOffer.status === OfferStatuses.OPEN);
  }, [offerStatus, wasteOffer, setOfferStatus]);

  useEffect(() => {
    if (pdfBlob && justAccepted) {
      const uploadPDF = async () => {
        const file = new File([pdfBlob], filename, {
          lastModified: new Date().getTime(),
        });

        const storageFilename = `/purchaseorders/${filename}/`;

        const fileRef = storageRef(storage, storageFilename);

        try {
          await getMetadata(fileRef);
        } catch (err) {
          await uploadBytes(fileRef, file);
        }

        if (!sentEmail) {
          await sendPdfEmail();

          if (company) {
            const blob = await pdf(
              <OfferInvoice offer={wasteOffer} company={company} bid={bid} />
            ).toBlob();

            await sendInvoiceEmail(blob);
          } else {
            console.error("No company found to issue invoice for..");
          }

          setSentEmail(true);
        }
      };

      uploadPDF();
      setJustAccepted(false);
    }
  }, [
    pdfBlob,
    justAccepted,
    filename,
    sentEmail,
    sendPdfEmail,
    company,
    sendInvoiceEmail,
    wasteOffer,
    bid,
  ]);

  const handleAccept = async () => {
    const result = window.confirm(t("Offer.ConfirmAccept"));
    if (!result) {
      return;
    }
    try {
      await updateBid(userId, wasteOffer.id, bid.id, {
        status: BidStatuses.ACCEPTED,
        acceptedAt: new Date(),
      });

      const updatedOffer = () => {
        const updatedOfferObj = { code: wasteOffer.code };

        const offerLocations = wasteOffer.locations;
        const updatedLocations = offerLocations.reduce(
          (acc, location) => {
            const { quantity } = location;
            let locationAmount = location.quantity.amount;
            if (acc.toDeduct > 0 && location.quantity.amount > 0) {
              let deduction = locationAmount - acc.toDeduct;
              locationAmount =
                deduction >= 0 ? Math.floor(deduction * 100) / 100 : 0;
              acc.toDeduct = deduction > 0 ? 0 : Math.abs(deduction);
            }
            acc.newLocationsArray.push({
              ...location,
              quantity: {
                ...quantity,
                amount: locationAmount,
                originalAmount: quantity.originalAmount ?? quantity.amount,
              },
            });
            return acc;
          },
          {
            newLocationsArray: [],
            toDeduct: bidQuantity,
          }
        ).newLocationsArray;

        if (wasteOffer.sharedOffer) {
          return {
            ...updatedOfferObj,
            quantity: {
              ...wasteOffer.quantity,
              amount: updatedLocations[0].quantity.amount,
            },
            locations: updatedLocations,
          };
        }
        return {
          ...updatedOfferObj,
          status: OfferStatuses.CLOSED,
        };
      };

      await updateOffer(userId, wasteOffer.id, updatedOffer());
      setBidStatus(BidStatuses.ACCEPTED);
      setHasAccepted(true);
      setJustAccepted(true);
      setSuccess(t("Offer.AcceptSuccess"));
    } catch (err) {
      console.error(err);
      setError(t("Offer.AcceptFailure"));
    }
  };

  if (!wasteOffer) {
    return null;
  }

  return (
    <GridItem key={bid.id}>
      <Card>
        <CardHeader
          disableTypography
          title={
            <Box display="flex" flexDirection="column">
              <Box display="flex" justifyContent="space-between">
                <Typography>{`${bid.amount}${bid.usePercentage ? "%" : ""} ${
                  bid.currencyCode
                }`}</Typography>
                <Stack direction="row" spacing={1}>
                  {isWasteOfferOwner &&
                  highestBid?.id === bid.id &&
                  !isSharedOffer ? (
                    <Chip
                      variant="filled"
                      color="info"
                      label={t("WasteOfferBid.HighestBid")}
                      icon={<StarIcon />}
                    />
                  ) : null}
                  {isWasteOfferOwner &&
                  bidStatus === BidStatuses.READ ? null : (
                    <Chip
                      {...statusProps}
                      label={t(`WasteOfferBid.Status_${bidStatus}`)}
                    />
                  )}
                </Stack>
              </Box>
              <Stack spacing={1}>
                {isSharedOffer ? (
                  <Typography>{`${t("QuantityAmount")}: ${bidQuantity}${t(
                    units.get(wasteOffer.quantity.unit).label
                  )}`}</Typography>
                ) : null}
              </Stack>
            </Box>
          }
        />
        <CardContent ref={ref}>
          <img src="" alt="" />
          <PaymentDeadline bid={bid} />
          <Typography>{`${bid.contact.fullName}`}</Typography>
          <Typography gutterBottom>{`${bid.comment}`}</Typography>
          <Typography variant="body2">{formattedDate}</Typography>
        </CardContent>
        <CardActions>
          <Box ml={1}>
            <Stack direction="row" spacing={2}>
              {!reading &&
              bidStatus === BidStatuses.READ &&
              isWasteOfferOwner &&
              !hasAccepted &&
              offerStatus &&
              wasteOfferQuantity >= bidQuantity ? (
                <Button
                  onClick={handleAccept}
                  startIcon={<ThumbUpIcon />}
                  color="success"
                >
                  {t("Offer.Accept")}
                </Button>
              ) : null}
              {!hasAccepted && offerStatus && (
                <BidNegotiationForm
                  bidId={bid.id}
                  bidOwnerId={bid.createdBy}
                  offerOwnerId={wasteOffer.createdBy}
                />
              )}
              {offerData && bidStatus === BidStatuses.ACCEPTED && (
                <>
                  <PDFDownloadLink
                    document={
                      <PurchaseOrder data={offerData} company={company} />
                    }
                    fileName={filename}
                    style={{ textDecoration: "none" }}
                  >
                    {({ blob, loading, error }) => {
                      if (blob && !loading) {
                        setPdfBlob(blob);
                      }
                      if (error) {
                        console.log(error);
                      }
                      return (
                        !loading && (
                          <Button
                            startIcon={<GetAppIcon />}
                            color="success"
                            disabled={loading}
                          >
                            {t("Offer.PurchaseOrder")}
                          </Button>
                        )
                      );
                    }}
                  </PDFDownloadLink>
                </>
              )}
              {showInvoice && (
                <PDFDownloadLink
                  document={
                    <OfferInvoice
                      offer={wasteOffer}
                      company={company}
                      bid={bid}
                      commodity={commodity}
                    />
                  }
                  fileName={invoice_filename}
                  style={{ textDecoration: "none" }}
                >
                  {({ loading }) => {
                    return (
                      !loading && (
                        <Button
                          startIcon={<GetAppIcon />}
                          color="success"
                          disabled={loading}
                        >
                          {t("Offer.Invoice")}
                        </Button>
                      )
                    );
                  }}
                </PDFDownloadLink>
              )}
            </Stack>
          </Box>
        </CardActions>
        {bid.comments && <BidCommentSection comments={bid.comments} />}
      </Card>
    </GridItem>
  );
};
