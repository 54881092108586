import { Routes, Route } from "react-router-dom";
import WasteOfferCreate from "./WasteOfferCreate";
import WasteOfferDetails from "./WasteOfferDetails";
import WasteOfferEdit from "./WasteOfferEdit";
import WasteOfferMyArchivedBids from "./WasteOfferMyArchivedBids";
import WasteOfferMyBids from "./WasteOfferMyBids";
import WasteOffersList from "./WasteOffersList";

export const WasteOffersRouter = ({ user, role }) => {
  return (
    <Routes>
      <Route
        path="/"
        element={<WasteOffersList userId={user.uid} role={role} />}
      />
      <Route
        path="/my"
        element={
          <WasteOffersList userId={user.uid} role={role} type="MyList" />
        }
      />
      <Route
        path="/myclosed"
        element={
          <WasteOffersList userId={user.uid} role={role} type="MyClosedList" />
        }
      />
      <Route path="/mybids" element={<WasteOfferMyBids userId={user?.uid} />} />
      <Route
        path="/myarchivedbids"
        element={<WasteOfferMyArchivedBids userId={user?.uid} />}
      />
      <Route path="/create" element={<WasteOfferCreate />} />
      <Route path="/:offerId/edit" element={<WasteOfferEdit />} />
      <Route
        path="/:offerId"
        element={<WasteOfferDetails userId={user?.uid} />}
      />
      <Route
        path="/:offerId/bids/:bidId"
        element={<WasteOfferDetails userId={user?.uid} />}
      />
    </Routes>
  );
};
