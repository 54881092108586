const defaultOptions = {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "default", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "omit", // include, *same-origin, omit
    headers: {
        // "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer-when-downgrade", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
};

const objectToQueryString = (obj) => {
    return Object.keys(obj)
        .map((key) => key + "=" + obj[key])
        .join("&");
};

export const fetchData = async (url = "", query = {}) => {
    let fetchUrl = url;
    if (query && Object.keys(query).length !== -1) {
        fetchUrl += `?${objectToQueryString(query)}`;
    }
    try {
        const response = await fetch(fetchUrl, {
            ...defaultOptions,
            method: "GET",
        });
        if (response.status >= 400 && response.status < 600) {
            throw new Error("Error loading data!");
        }
        const text = await response.text();
        const data = JSON.parse(text);
        return { success: true, data };
    } catch (err) {
        return { success: false, message: err.message || "Fetch data failed" };
    }
};
