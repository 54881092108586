import { Card } from "@mui/material";
import { WasteOfferCarousel } from "./WasteOfferCarousel";
import { WasteOfferOverviewContent } from "./WasteOfferOverviewContent";

export const OfferOverview = ({ wasteOffer }) => {
    return (
        <Card>
            <WasteOfferCarousel
                images={wasteOffer.images}
                wasteOffer={wasteOffer}
                sx={{ height: 230 }}
            />
            <WasteOfferOverviewContent wasteOffer={wasteOffer} />
        </Card>
    );
};
