import * as Yup from "yup";
import { ContactSchema } from "store/schemas/ContactSchema";
import add from "date-fns/add";
import set from "date-fns/set";
import { PriceSchema } from "wastexchange/store/schemas/PriceSchema";
import { LocationWithQuantitySchema } from "./LocationWithQuantitySchema";
import { WASTE_TYPE_FORMS } from "constants/enums";

const today = new Date();

const defaultExpiryDate = set(add(today, { days: 7 }), {
  hours: 21,
  minutes: 0,
  seconds: 0,
  milliseconds: 0,
});

export const OfferStatuses = {
  OPEN: "open",
  CLOSED: "closed",
  ARCHIVED: "archived",
};

const validFileExtensions = { pdf: ["pdf"] };

const isValidFileType = (fileName, fileType) => {
  return (
    fileName &&
    validFileExtensions[fileType].indexOf(fileName.split(".").pop()) > -1
  );
};

export const WasteOfferSchema = Yup.object({
  offerType: Yup.string().required("Validation.RequiredField").default("sell"),
  wasteType: Yup.string().required("Validation.RequiredField").default(""),
  wasteCategory: Yup.string().required("Validation.RequiredField").default(""),
  description: Yup.string().default(""),
  code: Yup.string().default(""),
  contact: ContactSchema,
  company: Yup.string().default(""),
  expiresAt: Yup.string().required().default(defaultExpiryDate),
  startingPrice: PriceSchema(),
  wasteTypes: Yup.array().nullable().default([]),
  cadastre: Yup.string()
    .default("")
    .when(["wasteType", "wasteTypes"], {
      is: (wasteType, wasteTypes = []) => {
        const forestMaterial = (wasteTypes || []).find(
          (v) => v.label === WASTE_TYPE_FORMS.FOREST_MATERIAL
        );
        return forestMaterial?.value === wasteType;
      },
      then: Yup.string().required("Validation.RequiredField"),
    }),
  locations: Yup.array()
    .of(LocationWithQuantitySchema)
    .min(1)
    .max(5)
    .default([LocationWithQuantitySchema.cast()]),
  wastePermit: Yup.mixed().when(["wasteType", "wasteTypes", "offerType"], {
    is: (wasteType, wasteTypes = [], offerType) => {
      const wasteWoodChipSawdust = (wasteTypes || []).find(
        (v) => v.label === WASTE_TYPE_FORMS.WASTE_WOOD_CHIP_SAWDUST
      );
      const wasteWood = (wasteTypes || []).find(
        (v) => v.label === WASTE_TYPE_FORMS.WASTE_WOOD
      );
      return (
        (wasteWoodChipSawdust?.value === wasteType ||
          wasteWood?.value === wasteType) &&
        offerType === "buy"
      );
    },
    then: Yup.mixed()
      .test("is-required", "Validation.RequiredField", (value) => {
        return !!value;
      })
      .test("is-valid-type", "Validation.RequiredField", (value) =>
        isValidFileType(value && value.name.toLowerCase(), "pdf")
      ),
  }),
  sharedOffer: Yup.boolean().default(false),
  autoExtend: Yup.boolean().default(false),
});
