import { useNavigate } from "react-router-dom";
import { httpsCallable } from "firebase/functions";
import { functionsInstance } from "helpers/firebase";
import { useMemo } from "react";

const unSubFromAgent = httpsCallable(
    functionsInstance,
    "wasteoffers-unSubFromAgent"
);

export const UnSubEndPoint = ({ agent, userId }) => {
    const navigate = useNavigate();
    useMemo(() => {
        (async () => {
            await unSubFromAgent({ agent, userId });
            navigate("/");
        })();
    }, [agent, navigate, userId]);

    return (
        <div>
            <h3>Unsubscribing Please Wait!</h3>
        </div>
    );
};
