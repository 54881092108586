import { collection, setDoc, getDoc, doc } from "firebase/firestore";
import { db } from "helpers/firebase";

const purchaseCollection = collection(db, "purchaseorder");
const companiesCollection = collection(db, "companies");
const wasteTypesCollection = collection(db, "wastetypes");

export function getOrder(offerId) {
  return getDoc(doc(purchaseCollection, offerId));
}

export async function createOrder(offer, bid, isSharedOffer = false) {
  const wasteType = await getDoc(
    doc(wasteTypesCollection, offer.wasteType)
  ).then((res) => res.data());

  let offerCompany = null;
  let bidCompany = null;

  if (offer.company) {
    offerCompany = await getDoc(doc(companiesCollection, offer.company)).then(
      (res) => res.data()
    );
  }

  if (bid.company) {
    bidCompany = await getDoc(doc(companiesCollection, bid.company)).then(
      (res) => res.data()
    );
  }
  const refId = isSharedOffer ? `${offer.id}_${bid.id}` : offer.id;

  const purchaseRef = doc(purchaseCollection, refId);

  await setDoc(purchaseRef, {
    offer,
    bid,
    wasteType,
    offerCompany,
    bidCompany,
    code: `PO-${offer.code}`, // TODO: check if code exists before
    createdAt: new Date(),
  });

  return purchaseRef;
}

export const getOrderData = async (offer, bid) => {
  if (offer?.sharedOffer) {
    await createOrder(offer, bid, true);
    
    const refId = `${offer.id}_${bid.id}`;

    const newPurchaseRef = doc(purchaseCollection, refId);

    const result = await getDoc(newPurchaseRef);
    return result.data();
  } else {
    const purchaseRef = doc(purchaseCollection, offer.id);

    let result = await getDoc(purchaseRef);

    if (!result.exists()) {
      await createOrder(offer, bid);
      result = await getDoc(purchaseRef);
    }
    return result.data();
  }
};
