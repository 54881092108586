import { useEffect } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";

// material
import { experimentalStyled as styled } from "@mui/material/styles";
import {
  Box,
  Link,
  List,
  Drawer,
  Typography,
  ListSubheader,
  useMediaQuery,
  Stack,
} from "@mui/material";

import { sidebarConfig } from "./Sidebar.config";
import { SidebarItem } from "./Sidebar.Item";
import MyAvatar from "minimal/components/MyAvatar";
import { Scrollbar } from "minimal/components/ScrollBar";
import Logo from "minimal/components/Logo";
import { useTranslation } from "react-i18next";
import { useUserProfile } from "store/userprofile";

// ----------------------------------------------------------------------

export const DRAWER_WIDTH = 280;

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

const AccountStyle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 2.5),
  margin: theme.spacing(1, 2.5, 5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[500_12],
}));

// ----------------------------------------------------------------------

function reduceChild({ array, item, pathname, t, level }) {
  const key = item.href + level;

  if (item.items) {
    return [
      ...array,
      <SidebarItem
        key={key}
        level={level}
        icon={item.icon}
        info={item.info}
        href={item.href}
        title={t(item.title)}
        open={true}
      >
        {renderSidebarItems({
          pathname,
          level: level + 1,
          items: item.items,
        })}
      </SidebarItem>,
    ];
  }
  return [
    ...array,
    <SidebarItem
      key={key}
      level={level}
      href={item.href}
      icon={item.icon}
      pathname={pathname}
      info={item.info}
      title={t(item.title)}
    />,
  ];
}

function renderSidebarItems({ items, pathname, t, level = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (array, item) => reduceChild({ array, item, pathname, t, level }),
        []
      )}
    </List>
  );
}

export const Sidebar = ({ isOpenSidebar, onCloseSidebar }) => {
  const { pathname } = useLocation();
  const { userProfile } = useUserProfile();
  const { t } = useTranslation();

  useEffect(() => {
    if (isOpenSidebar && onCloseSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar>
      <Box sx={{ px: 2.5, py: 3 }}>
        <Link component={RouterLink} to="/">
          <Stack direction="row" spacing={1} alignItems="center">
            <Logo />
            <Typography variant="subtitle1">Tradey</Typography>
          </Stack>
        </Link>
      </Box>

      {userProfile ? (
        <Link underline="none" component={RouterLink} to="/account">
          <AccountStyle>
            <MyAvatar />
            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: "text.primary" }}>
                {userProfile.fullName}
              </Typography>
            </Box>
          </AccountStyle>
        </Link>
      ) : null}

      {sidebarConfig.map((list) => (
        <List
          disablePadding
          key={list.subheader}
          subheader={
            <ListSubheader
              disableSticky
              disableGutters
              sx={{
                mt: 3,
                mb: 2,
                pl: 5,
                color: "text.primary",
                typography: "overline",
              }}
            >
              {t(list.subheader)}
            </ListSubheader>
          }
        >
          {renderSidebarItems({
            items: list.items,
            pathname,
            t,
          })}
        </List>
      ))}
    </Scrollbar>
  );

  const mdDown = useMediaQuery((theme) => theme.breakpoints.down("md"));

  return (
    <RootStyle>
      {mdDown ? (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: "background.default",
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
};
