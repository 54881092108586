import Select from "components/forms/Select";
import SwitchWithLabel from "components/forms/SwitchWithLabel";
import File from "wastexchange/pages/wasteoffers/components/File";
import Range from "wastexchange/pages/wasteoffers/components/Range";
import TextField from "components/forms/TextField";
import { FIELD_TYPE } from "./enums";

export const INPUT_COMPONENT_MAP = {
  [FIELD_TYPE.SELECT]: Select,
  [FIELD_TYPE.CHECKBOX]: SwitchWithLabel,
  [FIELD_TYPE.RANGE]: Range,
  [FIELD_TYPE.FILE]: File,
  [FIELD_TYPE.TEXT]: TextField,
};
