import { PriceSchema } from "wastexchange/store/schemas/PriceSchema";
import * as Yup from "yup";
import { ContactSchema } from "./ContactSchema";

export const BidStatuses = {
  NEW: "NEW",
  READ: "READ",
  PENDING: "PENDING",
  ACCEPTED: "ACCEPTED",
  REJECTED: "REJECTED",
};

export const WasteOfferBidSchema = (currencyCode) => PriceSchema(currencyCode).shape({
  prePayment: Yup.boolean().default(false),
  paymentDeadline: Yup.object({
    amount: Yup.number().default(14),
    unit: Yup.string().default("days"),
  }),
  comment: Yup.string().default(""),
  contact: ContactSchema,
  company: Yup.string().default(""),
  quantity: Yup.number()
});

export const BidReplySchema = Yup.object({
  reply: Yup.string().required("Validation.RequiredField").default(""),
});

export const OfferArchiveSchema = Yup.object({
  reason: Yup.string().required("Validation.RequiredField").default(""),
  comment: Yup.string().default(""),
});
