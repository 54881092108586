import FormField from "components/forms/FormField";
import { GridItem } from "components/layout/Helpers";
import { INPUT_COMPONENT_MAP } from "constants/inputMap";
import { OFFER_FIELD_MAP } from "constants/map";
import React from "react";
import { useWasteTypes } from "wastexchange/store/wastetypes";
import ConditionalField from "./ConditionalField";

const WasteOfferFormAdditionalFields = (props) => {
  const { wasteType, control, watch, setValue } = props;
  const { wasteTypes } = useWasteTypes();

  const wasteTypeOptions = Array.from(wasteTypes.values());
  const wasteTypeObject = wasteTypeOptions.find((w) => w.id === wasteType);
  const additionalFields = OFFER_FIELD_MAP[wasteTypeObject?.wasteTypeForm];

  if (!additionalFields) {
    return null;
  }

  return (
    <>
      {additionalFields.map((additionalField) => {
        const { conditionalOptions, requiredFor, ...fieldProps } =
          additionalField;

        return (
          <React.Fragment key={additionalField.id}>
            <GridItem>
              <FormField
                {...{
                  ...fieldProps,
                  control,
                  Component: INPUT_COMPONENT_MAP[additionalField.type],
                }}
              />
            </GridItem>
            {additionalField.conditionalOptions.length > 0 && (
              <ConditionalField
                {...{
                  parentFieldName: additionalField.name,
                  options: additionalField.conditionalOptions,
                  control,
                  watch,
                  setValue,
                }}
              />
            )}
          </React.Fragment>
        );
      })}
    </>
  );
};

export default WasteOfferFormAdditionalFields;
