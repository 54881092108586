import { FIELD_TYPE, FORM_FIELD_NAME } from "../enums";
import AdditionalInfoField from "../map";

export const getForestMaterialFields = () => [
  new AdditionalInfoField({
    id: "dTrXOPGiQ3O6bKyTxnX5",
    name: FORM_FIELD_NAME.FOREST_MATERIAL,
    label: "ForestMaterial",
    type: FIELD_TYPE.SELECT,
    defaultValue: "0l6izz5aT7V2jzx2FQi4",
    options: [
      {
        id: "0l6izz5aT7V2jzx2FQi4",
        label: "ForestMaterials.FineWood",
      },
      {
        id: "sbKvH6LXi2qNnWtLQcLc",
        label: "ForestMaterials.Bush",
      },
      {
        id: "ExMrTZ0nCduhWQRNBS4j",
        label: "ForestMaterials.Stumps",
      },
    ],
  }),
  new AdditionalInfoField({
    id: "NuCjVfmlNF4BpSHujoJY",
    name: FORM_FIELD_NAME.CADASTRE,
    label: "WasteOffer.Cadastre",
    type: FIELD_TYPE.TEXT,
  }),
  new AdditionalInfoField({
    id: "XuCjVfmlNF4BpSHujoJY",
    name: FORM_FIELD_NAME.FELLING_PERMIT,
    label: "FellingPermit",
    acceptFiles: "application/pdf",
    type: FIELD_TYPE.FILE,
    defaultValue: "",
  }),
  new AdditionalInfoField({
    id: "AuNn8FcI8L95FKlpRyQp",
    name: FORM_FIELD_NAME.FSC_PFC_CERTIFICATE,
    label: "FSC_PFC_CERTIFICATE",
    acceptFiles: "application/pdf",
    type: FIELD_TYPE.FILE,
    defaultValue: "",
  }),
];
