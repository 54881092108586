import { forwardRef, useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "components/forms/TextField";
import debounce from "lodash/debounce";
import { LocationFieldOption } from "components/forms/LocationFieldOption";
import get from "lodash/get";
import { fetchAddress, getCallback } from "./LocationField.helpers";
import { getNormalAddress } from "helpers/addresses";

const startFetchAtLength = 2;

const LocationField = (
  {
    label,
    translatedLabel,
    onChange,
    value: storedValue,
    t,
    errors = {},
    language = "en",
    ...rest
  },
  ref
) => {
  const [value, setValue] = useState(storedValue);
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    let active = true;

    // to reduce strain on the API and the chance to get banned, debounce calling the api at 600ms
    const throttled = debounce(fetchAddress, 600);

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    if (inputValue.length < startFetchAtLength) {
      return undefined;
    }

    throttled(
      { q: inputValue },
      getCallback({ active, value, setOptions }),
      language
    );

    return () => {
      active = false;
      throttled.cancel();
    };
  }, [language, inputValue, value]);

  return (
    <Autocomplete
      disablePortal
      autoComplete
      includeInputInList
      filterSelectedOptions
      filterOptions={(x) => x}
      freeSolo
      value={value}
      inputValue={inputValue || storedValue?.display_name || ""}
      options={options}
      getOptionLabel={(option) => getNormalAddress(option).label}
      onChange={(_, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        onChange(newValue);
      }}
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderOption={(props, option) => (
        <LocationFieldOption option={option} {...props} key={option.place_id} />
      )}
      renderInput={(params) => {
        const error = get(errors, rest.name);
        const contextError = error?.display_name;

        const inputParams = { ...params };

        return (
          <TextField
            inputRef={ref}
            error={!!contextError}
            helperText={
              contextError
                ? t
                  ? t(contextError.message)
                  : contextError.message
                : null
            }
            label={label}
            translatedLabel={translatedLabel}
            {...inputParams}
          />
        );
      }}
      {...rest}
    />
  );
};

export default forwardRef(LocationField);
