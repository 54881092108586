import { auth } from "helpers/firebase";
import * as firebaseAuth from "firebase/auth";

import { useEffect, useState } from "react";
// import { useNotifications } from "store/notifications";
// import { useTranslation } from "react-i18next";
import { httpsCallable } from "firebase/functions";
import { functionsInstance } from "helpers/firebase";
import { useIsMounted } from "./useIsMounted";

const createUserAccount = httpsCallable(
  functionsInstance,
  "userprofiles-createUserAccount"
);

const sendVerifyEmail = httpsCallable(
  functionsInstance,
  "userprofiles-sendVerifyEmail"
);

export const signUp = async (data) => {
  const result = await createUserAccount(data);
  return result.data;
};

export const verify = async (user) => {
  await sendVerifyEmail(user);
};

export const sendSignInLinkToEmail = (email) => {
  return firebaseAuth.sendSignInLinkToEmail(auth, email, {
    url: `${window.location.origin}/signin`,
    handleCodeInApp: true,
  });
};

export const isSignInWithEmailLink = (link) =>
  firebaseAuth.isSignInWithEmailLink(auth, link);

export const signInWithEmailLink = (email, link) =>
  firebaseAuth.signInWithEmailLink(auth, email, link);

export const signOut = () => firebaseAuth.signOut(auth);

export const signInWithEmailAndPassword = (email, password, rememberMe) => {
  firebaseAuth.setPersistence(
    auth,
    rememberMe
      ? firebaseAuth.indexedDBLocalPersistence
      : firebaseAuth.browserSessionPersistence
  );
  return firebaseAuth.signInWithEmailAndPassword(auth, email, password);
};

export const onAuthStateChanged = (cb) =>
  firebaseAuth.onAuthStateChanged(auth, cb);

export const sendPasswordResetEmail = (email) =>
  firebaseAuth.sendPasswordResetEmail(auth, email, {
    url: window.location.origin,
    handleCodeInApp: true,
  });

export const updateUserPassword = async (user, oldPwd, newPwd) => {
  const cred = firebaseAuth.EmailAuthProvider.credential(user.email, oldPwd);

  try {
    const reAuth = await firebaseAuth.reauthenticateWithCredential(user, cred);
    if (reAuth) {
      await firebaseAuth.updatePassword(user, newPwd);
    }
  } catch (err) {
    return err;
  }
};

export const useAuth = () => {
  const isMounted = useIsMounted();
  const [authUser, setAuthUser] = useState(null);
  const [loadingUser, setLoadingUser] = useState(true);
  const [role, setRole] = useState(null);

  useEffect(() => {
    const readPermissions = async function (user) {
      try {
        const token = await user.getIdTokenResult();
        setAuthUser(user);
        if (token.claims.role) {
          return setRole(token.claims.role);
        }
      } catch (err) {
        console.log("Authentication.Error", {
          message: `FirebaseErrors.${err.code}`,
        });
      } finally {
        setRole("user"); // set default role as user
      }
    };

    const unlisten = firebaseAuth.onAuthStateChanged(auth, async (user) => {
      if (user && isMounted) {
        setLoadingUser(true);
        await readPermissions(user);
        setLoadingUser(false);
      } else {
        setAuthUser(null);
        setRole(null);
        setLoadingUser(false);
      }
    });
    return () => {
      unlisten();
    };
  }, [isMounted]);

  return { user: authUser, role, loadingUser };
};
