import { FIELD_TYPE, FORM_FIELD_NAME } from "../enums";
import AdditionalInfoField from "../map";

export const getForestChipSawDustFields = () => [
  new AdditionalInfoField({
    id: "3CU9j9yic1HfC3HoUUiQ",
    name: FORM_FIELD_NAME.DEGREE_OF_CRUSHING,
    label: "DegreeOfCrushing",
    type: FIELD_TYPE.SELECT,
    defaultValue: "WXiA7JecZHaHu9gYEEht",
    options: [
      {
        id: "WXiA7JecZHaHu9gYEEht",
        label: "DegreeOfCrushings.PreCrushed",
      },
      {
        id: "QF5RKy3NmJFzrzy4DcXG",
        label: "DegreeOfCrushings.FinalFraction",
      },
      {
        id: "OL6rN6JySQ4oDtPcWSvf",
        label: "DegreeOfCrushings.FineFraction",
      },
      {
        id: "CL5rN6JySQ4oDtPcWSvw",
        label: "DegreeOfCrushings.NonCrushed",
      },
    ],
  }),
  new AdditionalInfoField({
    id: "fZ4nXmpuvPcmLd3dTMp7",
    name: FORM_FIELD_NAME.QUALITY_STANDARD,
    label: "QualityStandard",
    type: FIELD_TYPE.SELECT,
    defaultValue: "GzSnJNpNhqdKNTzfqqzg",
    options: [
      {
        id: "GzSnJNpNhqdKNTzfqqzg",
        label: "QualityStandards.SM1",
      },
      {
        id: "zlGKlEwtdogaS98h38Bc",
        label: "QualityStandards.SM2",
      },
      {
        id: "veuu56kQtY4YmGaurgtk",
        label: "QualityStandards.SM3",
      },
    ],
  }),
  new AdditionalInfoField({
    id: "u83jK9FD6THQgkvL1roC",
    name: FORM_FIELD_NAME.HUMIDITY,
    label: "Humidity",
    type: FIELD_TYPE.RANGE,
    defaultValue: 15,
    limit: {
      min: 12,
      max: 55,
      step: 1,
      postFix: "%",
    },
  }),
  new AdditionalInfoField({
    id: "eV3eHHTcIxBBFesSWYOD",
    name: FORM_FIELD_NAME.IMPURITY_FREE,
    label: "ImpurityFree",
    type: FIELD_TYPE.CHECKBOX,
    defaultValue: false,
  }),
  new AdditionalInfoField({
    id: "AwWpgBO2HV4c6tEFmA6e",
    name: FORM_FIELD_NAME.WIHTOUT_LEAVES_THORNS,
    label: "WithoutLeavesThorns",
    type: FIELD_TYPE.CHECKBOX,
    defaultValue: false,
  }),
  new AdditionalInfoField({
    id: "byCuDMi1Y9V2R5scK9XR",
    name: FORM_FIELD_NAME.FELLING_PERMIT,
    label: "FellingPermit",
    acceptFiles: "application/pdf",
    type: FIELD_TYPE.FILE,
    defaultValue: "",
  }),
];
