import { LinearProgress } from "@mui/material";
import { WasteOfferSchema } from "wastexchange/store/schemas/WasteOfferSchema";
import { useUserProfile } from "store/userprofile";
import WasteOfferForm from "./WasteOfferForm";

const defaultValues = WasteOfferSchema.cast();

const WasteOfferCreate = () => {
  const { userProfile } = useUserProfile();

  if (!userProfile) {
    return <LinearProgress />;
  }

  const formDataValues = {
    ...defaultValues,
    contact: {
      email: userProfile.email,
      fullName: userProfile.fullName,
      phoneNumber: userProfile.phoneNumber,
      language: userProfile.language,
    },
    company: userProfile.company,
    startingPrice: {
      currencyCode: "EUR",
      delivery: "DAP",
    },
    buyNowPrice: {
      amount: 0,
      currencyCode: "EUR",
    },
  };

  return <WasteOfferForm formDataValues={formDataValues} />;
};

export default WasteOfferCreate;
