import * as PDFRenderer from "@react-pdf/renderer";
import { quantityUnits } from "helpers/options";
import { useAuth } from "helpers/auth";
import { useTranslation } from "react-i18next";
import round from "lodash/round";
import { PERCENTAGES } from "constants/priceInputFieldSettings";

const { Document, Page, StyleSheet, Text, View } = PDFRenderer;

const EE = "EE";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    padding: "15px",
  },
  col: {
    flexDirection: "column",
  },
  row: {
    flexDirection: "row",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  center: {
    alignItems: "center",
    flexGrow: 1,
  },
  right: {
    alignItems: "flex-end",
    flexGrow: 1,
  },
  date: {
    alignItems: "flex-end",
    flexGrow: 1,
    marginRight: 10,
    fontSize: 12,
  },
  headLine: {
    alignItems: "center",
    fontSize: 18,
  },
  headerBlock: {
    alignItems: "start",
    fontSize: 10,
    marginLeft: 25,
    marginTop: 20,
    display: "flex",
    flexDirection: "row",
  },
  rowBlock: {
    alignItems: "start",
    fontSize: 10,
    marginLeft: 25,
    display: "flex",
    flexDirection: "row",
  },
  textBlock: {
    alignItems: "start",
    fontSize: 10,
    marginLeft: 25,
    marginTop: 10,
    display: "flex",
    flexDirection: "row",
  },
  sumBlock: {
    alignItems: "start",
    fontSize: 12,
    marginLeft: 25,
    marginTop: 20,
    padding: "3px",
    display: "flex",
    borderTop: 1,
    flexDirection: "row",
  },
  col1: {
    flex: 1,
  },
  col2: {
    alignItems: "flex-start",
    flex: 3,
  },
  col3: {
    flex: 4,
  },
  col4: {
    flex: 2,
  },
  divider: {
    margin: 5,
  },
  dividerLine: {
    borderBottom: 1,
    marginLeft: 25,
  },
});

const TableLineDivider = () => (
  <>
    <View style={styles.dividerLine}></View>
    <View style={styles.divider}></View>
  </>
);

const LineDivider = () => (
  <>
    <View style={styles.divider}></View>
  </>
);

const Header = ({ code, acceptedAt }) => (
  <>
    <View style={styles.date}>
      <Text>Nr: {code}</Text>
      <Text>Date: {acceptedAt}</Text>
    </View>
  </>
);

const Footer = ({ data }) => (
  <>
    <LineDivider />
    <View style={styles.textBlock}>
      <View style={styles.col1}>
        <Text>Signature {"&"} Stamp:</Text>
        <LineDivider />
        <Text>BUYER:</Text>
        <View
          style={{
            marginTop: "40px",
            width: "50%",
            borderBottom: 0.5,
          }}
        ></View>
        <Text>BUYER</Text>
      </View>
      <View style={styles.col1}>
        <Text> </Text>
        <LineDivider />
        <Text>SUPPLIER:</Text>
        <View
          style={{
            marginTop: "40px",
            width: "50%",
            borderBottom: 0.5,
          }}
        ></View>
        <Text>SUPPLIER</Text>
      </View>
    </View>
  </>
);

const PurchaseOrder = ({ data, company }) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const sellerLoc = data.offer.location.address;
  const offerUnit = quantityUnits.find(
    (o) => o.value === data.offer.quantity.unit
  ).labelShort;
  const offerType = data.offer?.offerType;
  const isSellOfferType = offerType === "sell";

  const isBuyer = user?.uid === data?.bid?.createdBy || false;

  const showPercentage = data.offer.startingPrice.usePercentage ? "%" : "";

  const currencyCode = data.bid.currencyCode;

  const fullCurrencyCode = !showPercentage
    ? currencyCode
    : PERCENTAGES.find((p) => p.label === currencyCode)?.purchaseOrderLable  ?? currencyCode;

  const totalQuantity = () => {
    if (data.offer.sharedOffer) {
      return data.bid.quantity || "1";
    }
    let total = 0;
    data.offer.locations.forEach(({ quantity }) => {
      total += quantity.amount;
    });
    return total;
  };

  const check = (val) => {
    return val ? val : "";
  };

  const SupplierInfo = (isBuyer = false) => (
    <View style={styles.textBlock}>
      <View style={styles.col1}>
        <Text>{isBuyer ? "Buyer:" : "Supplier:"}</Text>
      </View>
      <View style={styles.col2}>
        <Text>{check(data.offerCompany?.name)}</Text>
        <LineDivider />
        <Text>{`${check(sellerLoc.road)} ${check(
          sellerLoc.house_number
        )}`}</Text>
        <LineDivider />
        <Text>
          {`${check(sellerLoc.city)}, ${check(sellerLoc.postcode)} ${check(
            sellerLoc.country
          )}`}
        </Text>
        <LineDivider />
        <Text>Tel.: {check(data.offer.contact.phoneNumber)}</Text>
        <LineDivider />
        <Text>Reg. nr.: {check(data.offerCompany?.registryCode)}</Text>
        <LineDivider />
        <Text>Contact person: {check(data.offer.contact.fullName)}</Text>
      </View>
      <View style={styles.col3}>
        <Text> </Text>
        <LineDivider />
        <Text> </Text>
        <LineDivider />
        <Text> </Text>
        <LineDivider />
        <Text> </Text>
        <LineDivider />
        <Text>VAT nr.: {check(data.offerCompany?.vatNumber)}</Text>
        <LineDivider />
        <Text>E-mail: {check(data.offer.contact.email)}</Text>
      </View>
    </View>
  );

  const BuyerInfo = (isBuyer = true) => (
    <>
      <View style={styles.textBlock}>
        <View style={styles.col1}>
          <Text>{isBuyer ? "Buyer:" : "Supplier:"}</Text>
        </View>
        <View style={styles.col2}>
          <Text>{check(data.bidCompany?.name)}</Text>
          <LineDivider />
          <Text>Tel.: {check(data.bid.contact.phoneNumber)}</Text>
          <LineDivider />
          <Text>Reg. nr.: {check(data.bidCompany?.registryCode)}</Text>
          <LineDivider />
          <Text>Contact person: {check(data.bid.contact.fullName)}</Text>
        </View>
        <View style={styles.col3}>
          <Text> </Text>
          <LineDivider />
          <Text>VAT nr.: {check(data.bidCompany?.vatNumber)}</Text>
          <LineDivider />
          <Text>E-mail: {check(data.bid.contact.email)}</Text>
        </View>
      </View>
    </>
  );

  const SupplierBuyerInfos = () => {
    const arr = [BuyerInfo(isSellOfferType), SupplierInfo(!isSellOfferType)];
    return isBuyer && isSellOfferType ? arr : arr.reverse();
  };

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.row}>
          <Header
            code={data.code}
            acceptedAt={
              data.bid.acceptedAt.toDate().toISOString().split("T")[0]
            }
          />
        </View>
        <View style={styles.headLine}>
          <Text>PURCHASE ORDER {data.code}</Text>
        </View>
        {SupplierBuyerInfos().map((c, i) => (
          <View key={i}>{c}</View>
        ))}
        <View style={styles.textBlock}>
          <Text>Place of signature:</Text>
        </View>
        <View style={styles.textBlock}>
          <Text>
            The Supplier and Buyer have agreed to sale by sample and execute the
            Purchase Order according to the terms and conditions stipulated as
            below:
          </Text>
        </View>
        <View style={styles.headerBlock}>
          <View style={{ width: "100%", borderBottom: 1 }}>
            <Text>Description of goods:</Text>
          </View>
        </View>
        {/* Item Table*/}
        <View style={styles.headerBlock}>
          <View style={styles.col1}>
            <Text>Commodity</Text>
          </View>
          <View style={styles.col4}>
            <Text> </Text>
          </View>
          <View style={styles.col1}>
            <Text></Text>
          </View>
          <View style={styles.col1}>
            <Text>Quantity</Text>
          </View>
          <View style={styles.col1}>
            <Text>Unit price</Text>
          </View>
          <View style={styles.col1}>
            <Text>Total price</Text>
          </View>
        </View>
        <TableLineDivider />
        {data.offer.locations.map(({ location, quantity }, i) => {
          const unit = quantityUnits.find(
            (o) => o.value === quantity.unit
          ).labelShort;
          const qAmount = round(quantity.amount, 4);
          const quantityAmount = `${qAmount} ${t(unit)}`;

          return (
            <View key={`tableItem-${i}`}>
              <View style={styles.rowBlock}>
                <View style={styles.col3}>
                  <Text>
                    {`${check(data.wasteType.value)} ${check(
                      data.wasteType.en_label
                    )}`}
                  </Text>
                </View>
                <View style={styles.col1}>
                  <Text>{`${totalQuantity()} ${offerUnit}`}</Text>
                </View>
                <View style={styles.col1}>
                  <Text>{`${data.bid.amount}${showPercentage} ${currencyCode}`}</Text>
                </View>
                <View style={styles.col1}>
                  <Text>
                    {data.bid.amount}
                    {showPercentage}
                    {` ${currencyCode}`}
                  </Text>
                </View>
              </View>
              <LineDivider />
            </View>
          );
        })}
        {/* Table Sum */}
        <View style={styles.sumBlock}>
          <View style={styles.col1}>
            <Text>Sum:</Text>
          </View>
          <View style={styles.col4}>
            <Text> </Text>
          </View>
          <View style={styles.col1}>
            <Text> </Text>
          </View>
          <View style={styles.col1}>
            <Text>{`${totalQuantity()} ${offerUnit}`}</Text>
          </View>
          <View style={styles.col1}>
            <Text> </Text>
          </View>
          <View style={styles.col1}>
            <Text>{`${data.bid.amount}${showPercentage} ${fullCurrencyCode}`}</Text>
          </View>
        </View>
        {/* Terms */}
        <View style={styles.textBlock}>
          <View style={styles.col2}>
            <Text>Price terms:</Text>
          </View>
          <View style={styles.col3}>
            {data.offer.locations.map(({ location }, i) => (
              <Text key={`fca-address-${i}`}>
                {`${data.offer.startingPrice.delivery}. ${check(
                  location.address.city
                )}, ${check(location.address.postcode)} ${check(
                  location.address.country
                )}`}
              </Text>
            ))}
          </View>
        </View>
        {company?.countryCode === EE && (
          <View style={styles.textBlock}>
            <View style={styles.col2}>
              <Text>Other terms:</Text>
            </View>
            <View style={styles.col3}>
              <Text>{"The VAT must be added to the price."}</Text>
            </View>
          </View>
        )}
        <View style={styles.textBlock}>
          <View style={styles.col2}>
            <Text>Weight tolerance: </Text>
          </View>
          <View style={styles.col3}>
            <Text>{"+/- 10 %"}</Text>
          </View>
        </View>
        <View style={styles.textBlock}>
          <View style={styles.col2}>
            <Text>Tara weight per packaging unit:</Text>
          </View>
          <View style={styles.col3}>
            <Text>{"0 kg"}</Text>
          </View>
        </View>
        <View style={styles.textBlock}>
          <View style={styles.col2}>
            <Text>Wooden packaging allowed:</Text>
          </View>
          <View style={styles.col3}>
            <Text>{"No"}</Text>
          </View>
        </View>
        <View style={styles.textBlock}>
          <View style={styles.col2}>
            <Text>Payment terms: </Text>
          </View>
          <View style={styles.col3}>
            <Text>{"14 days after loading"}</Text>
          </View>
        </View>
        {/* <View style={styles.textBlock}>
                    <View style={styles.col2}>
                        <Text>Booking arranged by</Text>
                    </View>
                    <View style={styles.col3}>
                        <Text>{"Nores Plastic Ltd."}</Text>
                    </View>
                </View> */}
        {/* Signature */}
        <Footer />
      </Page>
      <Page size="A4" style={styles.page}>
        <View style={styles.row}>
          <Header
            code={data.code}
            acceptedAt={
              data.bid.acceptedAt.toDate().toISOString().split("T")[0]
            }
          />
        </View>
        {/* Terms */}
        <View style={styles.textBlock}>
          <View style={styles.col2}>
            <Text>Place of loading:</Text>
          </View>
          <View style={styles.col3}>
            {data.offer.locations.map(({ location }, i) => (
              <Text key={`address-${i}`}>
                {`${check(location.address.city)}, ${check(
                  location.address.postcode
                )} ${check(location.address.country)}`}
              </Text>
            ))}
          </View>
        </View>
        <View style={styles.textBlock}>
          <View style={styles.col2}>
            <Text>Contact person:</Text>
          </View>
          <View style={styles.col3}>
            <Text>{check(data.offerCompany?.name)}</Text>
          </View>
        </View>
        <View style={styles.textBlock}>
          <Text>{"(If different than Supplier's address)"}</Text>
        </View>
        {/* <View style={styles.textBlock}>
                    <View style={styles.col2}>
                        <Text>Equipment:</Text>
                    </View>
                    <View style={styles.col3}>
                        {data.offer.locations.map(({ location }, i) => (
                            <Text key={`address-${i}`}>
                                {`${check(location.address.city)}, ${check(
                                    location.address.postcode
                                )} ${check(location.address.country)}`}
                            </Text>
                        ))}
                    </View>
                </View> */}
        <View style={styles.textBlock}>
          <View style={styles.col3}>
            <Text>Documents required from Supplier:</Text>
            <LineDivider />
            <Text>1- Purchase Order</Text>
            <Text>2- Invoice</Text>
            <Text>3- Weight ticket</Text>
          </View>
        </View>
        <View style={styles.textBlock}>
          <Text>
            IMPORTANT: PLEASE ALWAYS MARK THE PURCHASE ORDER NUMBER AND THE
            CONTAINER/TRUCK NUMBER ON YOUR INVOICE.
          </Text>
        </View>
        <View style={styles.textBlock}>
          <View style={styles.col1}>
            <Text>Further conditions</Text>
            <LineDivider />
            <Text>
              Supplier is obligated to send copies of above listed required
              documents per fax or email within 5 days of Date of Loading.
            </Text>
          </View>
        </View>

        <View style={styles.textBlock}>
          <View style={styles.col1}>
            <Text> Arbitrage</Text>
            <LineDivider />
            <Text>
              All disputes arisen from execution of or in connection with this
              Purchase Order shall be settled amicably through friendly
              negotiation. In case no settlement can be reached through
              negotiation, the case shall be filed to the Economical and Trade
              Arbitration Commission selected by the party that starts the
              arbitrage procedure. The arbitration award shall be final and
              binding upon both parties.
            </Text>
          </View>
        </View>
        <Footer />
      </Page>
    </Document>
  );
};

export default PurchaseOrder;
