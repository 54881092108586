import { Navigate, useLocation } from "react-router";
import { useAuth } from "./helpers/auth";
import { LoadingScreen } from "./minimal/components/LoadingScreeen";

export const RequireAuth = ({ children }) => {
  const location = useLocation();

  const { user, loadingUser } = useAuth();

  const isVerifiedUser = user && user.emailVerified;

  if (loadingUser) {
    return <LoadingScreen />;
  }

  if (!user && !loadingUser) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (!isVerifiedUser) {
    return <Navigate to="/verify" state={{ from: location }} replace />;
  }

  return children;
};
