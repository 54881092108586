import { alpha, experimentalStyled as styled } from "@mui/material/styles";
import { AppBar as MuiAppBar, Toolbar as MuiToolbar } from "@mui/material";

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

export const AppBar = styled(MuiAppBar)(({ theme }) => ({
    boxShadow: "none",
    backdropFilter: "blur(6px)",
    WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
    backgroundColor: alpha(theme.palette.background.default, 0.72),
    [theme.breakpoints.up("lg")]: {
        width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
    },
}));

export const Toolbar = styled(MuiToolbar)(({ theme }) => ({
    minHeight: APPBAR_MOBILE,
    [theme.breakpoints.up("lg")]: {
        minHeight: APPBAR_DESKTOP,
        padding: theme.spacing(0, 5),
    },
}));
