import { useState, useRef } from "react";
import { Icon } from "@iconify/react";
import homeFill from "@iconify/icons-eva/home-fill";
import roundSpeed from "@iconify/icons-ic/round-speed";
import menu2Fill from "@iconify/icons-eva/menu-2-fill";
import { Link as RouterLink, useLocation } from "react-router-dom";

import { alpha, experimentalStyled as styled } from "@mui/material/styles";
import {
  Box,
  List,
  Link,
  AppBar,
  Toolbar,
  MenuItem,
  Container,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  Stack,
} from "@mui/material";
import useOffSetTop from "../../minimal/hooks/useOffsetTop";
import MenuPopover from "../../minimal/components/MenuPopover";
import Logo from "../../minimal/components/Logo";
import { MIconButton } from "../../minimal/components/@material-extend";
import { LoginLink } from "../../components/LoginLink";
import LanguagePopover from "minimal/components/LanguagePopover";
import { useTranslation } from "react-i18next";

// ----------------------------------------------------------------------

const MENU_LINKS = [
  { title: "Home", icon: homeFill, href: "/" },
  { title: "Offers", icon: roundSpeed, href: "/offers" },
];

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 96;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
  "& .isDesktopActive": {
    color: `${theme.palette.primary.main} !important`,
  },
  "& .isMobileActive": {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    backgroundColor: alpha(
      theme.palette.primary.main,
      theme.palette.action.selectedOpacity
    ),
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: APP_BAR_MOBILE,
  transition: theme.transitions.create(["height", "background-color"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up("md")]: { height: APP_BAR_DESKTOP },
}));

const ToolbarShadowStyle = styled("div")(({ theme }) => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: "auto",
  borderRadius: "50%",
  position: "absolute",
  width: `calc(100% - 48px)`,
  boxShadow: theme.customShadows.z8,
}));

// ----------------------------------------------------------------------

export const LandingNav = () => {
  const anchorRef = useRef(null);
  const { pathname } = useLocation();
  const offset = useOffSetTop(100);
  const [openMenu, setOpenMenu] = useState(false);
  const isHome = pathname === "/";
  const { t } = useTranslation();

  const linkSx = {
    color: "text.primary",
    transition: (theme) =>
      theme.transitions.create("opacity", {
        duration: theme.transitions.duration.shortest,
      }),
    "&:hover": { opacity: 0.48 },
    ...(isHome && { color: "common.white" }),
    ...(offset && { color: "text.primary" }),
  };

  const renderMenuDesktop = (
    <>
      {MENU_LINKS.map((link) => (
        <Link
          to={link.href}
          key={link.title}
          underline="none"
          variant="subtitle2"
          component={RouterLink}
          sx={linkSx}
          px={2}
        >
          {t(link.title)}
        </Link>
      ))}
    </>
  );

  const renderMenuMobile = (
    <MenuPopover
      disablePortal
      open={openMenu}
      anchorEl={anchorRef.current}
      onClose={() => setOpenMenu(false)}
    >
      <List>
        {MENU_LINKS.map((link) => (
          <MenuItem
            to={link.href}
            key={link.title}
            component={RouterLink}
            onClick={() => setOpenMenu(false)}
            sx={{ color: "text.secondary", py: 1 }}
          >
            <ListItemIcon>
              <Icon icon={link.icon} width={20} height={20} />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ typography: "body2" }}>
              {link.title}
            </ListItemText>
          </MenuItem>
        ))}
      </List>
    </MenuPopover>
  );

  const mdUp = useMediaQuery((theme) => theme.breakpoints.up("md"));

  return (
    <RootStyle color="transparent">
      <ToolbarStyle
        disableGutters
        sx={{
          ...(offset && {
            bgcolor: "background.default",
            height: { md: APP_BAR_DESKTOP - 20 },
          }),
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <RouterLink to="/">
            <Logo />
          </RouterLink>

          <Stack
            pl={mdUp ? 2 : 0}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              flexDirection: "row",
            }}
          >
            {mdUp ? renderMenuDesktop : null}
          </Stack>
          <Box sx={{ flexGrow: 1 }} />

          <Stack spacing={2} direction="row">
            <LanguagePopover linkSx={linkSx} />
            <LoginLink linkSx={linkSx} />
          </Stack>

          {!mdUp ? (
            <>
              <MIconButton
                ref={anchorRef}
                onClick={() => setOpenMenu(true)}
                sx={{
                  ml: 1,
                  ...(isHome && { color: "common.white" }),
                  ...(offset && { color: "text.primary" }),
                }}
              >
                <Icon icon={menu2Fill} />
              </MIconButton>
              {renderMenuMobile}
            </>
          ) : null}
        </Container>
      </ToolbarStyle>

      {offset && <ToolbarShadowStyle />}
    </RootStyle>
  );
};
