import { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import { Box, Collapse } from "@mui/material";
import { MBadge } from "minimal/components/@material-extend";
import { getComparator, stableSort } from "helpers/sort";
import { getStatusProps } from "wastexchange/components/wasteofferbids/BidItem";
import { Link as RouterLink } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import BidsTableHead from "./BidsTableHead";

export const bidUrl = (bId, oId) => `/offers/${oId}/bids/${bId}`;

export default function BidsTableRow({ row }) {
  const [open, setOpen] = useState(false);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("created");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <>
      <TableRow hover tabIndex={-1}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" padding="none">
          <RouterLink to={bidUrl(row.id, row.offerId)}>{row.offer}</RouterLink>
        </TableCell>
        <TableCell align="right">{row.created}</TableCell>
        <TableCell align="right">
          <MBadge
            badgeContent={row.status}
            color={getStatusProps(row.status).color}
          ></MBadge>
        </TableCell>
        <TableCell align="right">{row.amount}</TableCell>
        <TableCell align="right">{row.currency}</TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small" aria-label="purchases">
                <BidsTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  expand={false}
                />

                <TableBody>
                  {stableSort(row.children, getComparator(order, orderBy)).map(
                    (childRow) => (
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={childRow.id}
                        component={RouterLink}
                        to={bidUrl(childRow.id, childRow.offerId)}
                      >
                        <TableCell align="right">{childRow.created}</TableCell>
                        <TableCell align="right">
                          <MBadge
                            badgeContent={childRow.status}
                            color={getStatusProps(childRow.status).color}
                          ></MBadge>
                        </TableCell>
                        <TableCell align="right">{childRow.amount}</TableCell>
                        <TableCell align="right">{childRow.currency}</TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
