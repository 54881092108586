import { createWithDevtools } from "../../store/store.helpers";

export const VIEW_TYPES = {
    CARDS: "CARDS",
    TABLE: "TABLE",
};

const wasteViews = (set) => ({
    view: VIEW_TYPES.CARDS,
    setView: (type) => {
        set((draft) => {
            draft.view = type;
        });
    },
});

export const useWasteViews = createWithDevtools(wasteViews);
