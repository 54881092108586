import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import FormField from "components/forms/FormField";
import TextField from "components/forms/TextField";
import { Messages } from "components/messages/Messages";
import ArchiveIcon from "@mui/icons-material/Archive";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNotifications } from "store/notifications";
import { OfferArchiveSchema } from "store/schemas/WasteOfferBidSchema";
import { ArchiveReasons } from "helpers/options";
import { OfferStatuses } from "wastexchange/store/schemas/WasteOfferSchema";
import { updateOffer } from "wastexchange/store/wasteoffers";
import { useNavigate } from "react-router-dom";
import { httpsCallable } from "firebase/functions";
import { functionsInstance } from "helpers/firebase";
import { pdf } from "@react-pdf/renderer";
import OfferInvoice from "components/PDF/OfferInvoice";
import { useCompanies } from "store/companies";
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getMetadata,
} from "firebase/storage";
const sendOfferInvoice = httpsCallable(
  functionsInstance,
  "wasteoffers-sendOfferInvoice"
);

const storage = getStorage();
const defaultValues = OfferArchiveSchema.cast();
const archiveOptions = ArchiveReasons;
const messageContext = "offerArchiveForm";

export const OfferArchiveForm = ({ userId, offerId, offer, bid }) => {
  const { companies, fetchCompany } = useCompanies();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [filename] = useState(`Offer_Invoice_${offer.code}.pdf`);
  const { setError, setSuccess } = useNotifications();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { control, handleSubmit, reset } = useForm({
    defaultValues,
    resolver: yupResolver(OfferArchiveSchema, {
      stripUnknown: true,
      abortEarly: false,
    }),
  });

  useEffect(() => {
    if (offer.company) {
      fetchCompany(offer.company);
    }
  }, [fetchCompany, offer.company]);

  const company = offer.company ? companies.get(offer.company) : null;

  const sendInvoiceEmail = useCallback(
    async (pdfBlob) => {
      const reader = new FileReader();
      reader.readAsDataURL(pdfBlob);
      reader.onloadend = async () => {
        const file = new File([pdfBlob], filename, {
          lastModified: new Date().getTime(),
        });

        const storageFilename = `/offerinvoices/${filename}/`;

        const fileRef = storageRef(storage, storageFilename);

        try {
          await getMetadata(fileRef);
        } catch (err) {
          await uploadBytes(fileRef, file);
        }

        await sendOfferInvoice({
          users: [offer.createdBy],
          code: offer.code,
          content: reader.result,
          filename,
        });
      };
    },
    [offer, filename]
  );

  const setErrorInDialog = (name, data) => {
    setError(t(name, data), { context: messageContext });
  };

  const openDialog = () => {
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };
  const onSubmit = async (data) => {
    setIsSubmitting(true);

    try {
      await updateOffer(userId, offerId, {
        code: offer.code,
        status: OfferStatuses.ARCHIVED,
        isArchived: true,
        archive: {
          comment: data.comment,
          reason: data.reason,
        },
      });

      if (company) {
        const blob = await pdf(
          <OfferInvoice offer={offer} company={company} bid={bid} />
        ).toBlob();

        await sendInvoiceEmail(blob);
      } else {
        console.error("No company found to issue invoice for..");
      }

      reset();
      setSuccess(t("Offer.ArchiveSuccess"));
      navigate("/offers/myclosed");
    } catch (err) {
      setErrorInDialog(err.message);
    }
    setIsSubmitting(false);
    closeDialog();
  };

  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        startIcon={<ArchiveIcon />}
        onClick={openDialog}
      >
        {t("Common.Archive")}
      </Button>
      <Dialog open={dialogOpen} onClose={closeDialog} maxWidth="md" fullWidth>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>
            <Typography>{t("Offer.ArchiveReason")}</Typography>
          </DialogTitle>
          <DialogContent>
            <Messages context={messageContext} />
            <FormField
              name="reason"
              control={control}
              label="Offer.ArchiveReason"
              options={archiveOptions}
              select
              Component={TextField}
            />
            <br />
            <br />
            <FormField
              name="comment"
              control={control}
              label="Offer.ArchiveComment"
              multiline
              minRows={5}
              maxRows={5}
              Component={TextField}
            />
          </DialogContent>
          <DialogActions>
            <LoadingButton
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              {t("Common.Submit")}
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};
