import { Box, Stack, Typography } from "@mui/material";
import { GridContainer, GridItem } from "components/layout/Helpers";
import { NavLink } from "components/NavLink";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useWasteOffers, bidsSelector } from "../store/wasteoffers";
import { BidItem } from "./wasteofferbids/BidItem";
import { useWasteTypes } from "../store/wastetypes";

export const WasteOfferBids = ({ wasteOffer, userId, bidId }) => {
  const { t, i18n } = useTranslation();

  const [hasAccepted, setHasAccepted] = useState(false);

  const bids = useWasteOffers(bidsSelector(wasteOffer?.id));

  const { wasteTypes } = useWasteTypes();

  if (!wasteOffer) {
    return null;
  }

  const wasteType = wasteTypes.get(wasteOffer.wasteType) || {};
  const commodity = wasteType[`${i18n.language}_label`] || wasteOffer.name;

  if (!bids?.size) {
    return null;
  }

  const isWasteOfferOwner = userId === wasteOffer?.createdBy;

  let sortedBidIds = Array.from(bids.keys()).reverse();

  if (bidId) {
    sortedBidIds = sortedBidIds.filter((id) => id === bidId);
  }

  return (
    <GridContainer>
      <GridItem>
        <Box p={2}>
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography variant="h4">
              <strong>
                {t(isWasteOfferOwner ? "Offer.Title" : "WasteOfferBid.MyBids")}
              </strong>
            </Typography>
            {bidId && bids?.size > 1 ? (
              <NavLink to={`/offers/${wasteOffer?.id}`}>
                <Typography>{t("Common.ShowAll")}</Typography>
              </NavLink>
            ) : null}
          </Stack>
        </Box>
        <GridContainer spacing={2}>
          {sortedBidIds.map((bidKey) => {
            const bid = bids.get(bidKey);
            return (
              <BidItem
                key={bidKey}
                bid={bid}
                userId={userId}
                wasteOffer={wasteOffer}
                hasAccepted={hasAccepted}
                setHasAccepted={setHasAccepted}
                commodity={commodity}
              />
            );
          })}
        </GridContainer>
      </GridItem>
    </GridContainer>
  );
};
