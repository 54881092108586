import "./terms.css";

const Terms = () => (
  <div>
    <p className="terms-page-bold">
      <span className="c2">TRADEY O&Uuml;</span>
    </p>
    <p className="terms-page-bold">
      <span className="c2">TERMS OF SERVICE</span>
    </p>
    <p className="terms-page-bold">
      <span>These Terms of Service (&ldquo;</span>
      <span className="c2">Terms</span>
      <span>&rdquo;) govern the contractual relationship between:</span>
    </p>
    <ul>
      <li>
        <span className="c2">Tradey O&Uuml;</span>
        <span>
          , a company governed by the laws of Estonia having its registered
          office at Tartu, Tedre 14, 50404, Estonia (hereinafter referred to as
          &ldquo;
        </span>
        <span className="c2">Tradey</span>
        <span>&rdquo;, &ldquo;</span>
        <span className="c2">we</span>
        <span>&rdquo;, &ldquo;</span>
        <span className="c2">our</span>
        <span>&rdquo;, and &ldquo;</span>
        <span className="c2">us</span>
        <span>&rdquo;) on the one hand,</span>
      </li>
    </ul>
    <p className="terms-page-bold">
      <span>and</span>
    </p>
    <ul>
      <li>
        <span>
          each Buyer (as defined below), Seller (as defined below), individual,
          entity, user or visitor on the other hand (collectively &ldquo;
        </span>
        <span className="c2">you</span>
        <span>&rdquo; or &ldquo;</span>
        <span className="c2">your</span>
        <span>&rdquo;) (i) accessing the Tradey website located at&nbsp;</span>
        <span>
          <a
            className="terms-page-bold"
            href="https://www.google.com/url?q=https://tradey.eu/%25C2%25A0&amp;sa=D&amp;source=editors&amp;ust=1675071276199456&amp;usg=AOvVaw2LuLoO7UT5f9LWyL5svdH2"
          >
            https://tradey.eu/&nbsp;
          </a>
        </span>
        <span>(hereinafter referred to as &ldquo;</span>
        <span className="c2">Website</span>
        <span>
          &rdquo;), and/or (ii) using the Services (as defined under Section 2.2
          of these Terms) via the Website.
        </span>
      </li>
    </ul>
    <p className="terms-page-bold">
      <span>
        You and Tradey shall also hereinafter be referred to together as the
        &ldquo;
      </span>
      <span className="c2">Parties</span>
      <span>&rdquo; and individually as a &ldquo;</span>
      <span className="c2">Party</span>
      <span>&rdquo;.</span>
    </p>
    <p className="terms-page-bold">
      <span>
        Please read these Terms carefully before using the Website. Your access
        to the Website, your use of the Services (as defined below) is
        conditioned upon your acceptance of and compliance with these Terms.
      </span>
    </p>
    <ol>
      <li>
        <span className="terms-page-bold">
          Acceptance &amp; Modification of the Terms
        </span>
      </li>
    </ol>
    <p className="terms-page-bold">
      <span>
        By accessing the Website, and by using the Services (defined below) you
        agree to be bound by these Terms and accept to comply with all
        applicable laws and regulations. Should you disagree to be bound by
        these Terms, in whole or in part, you shall (i) not be
        permitted/authorized to use the Services (as defined below), and (ii)
        refrain from accessing the Website.
      </span>
    </p>
    <p className="terms-page-bold">
      <span>
        Your continued use of the Website and use of the Services on the
        Platform (defined below) shall be deemed to constitute your acceptance
        of these Terms.
      </span>
    </p>
    <p className="terms-page-bold">
      <span>
        Tradey reserves the right, at any time and in its sole discretion, to
        modify or replace these Terms. You shall be solely responsible for
        checking these Terms periodically, to stay abreast of any changes to the
        Terms. Your continued access to the Website, and use of the Services on
        the Platform (as defined below) following the posting of any changes to
        these Terms shall be subject to the newly modified Terms.
      </span>
    </p>
    <ol>
      <li>
        <span className="terms-page-bold">Products &amp; Services</span>
      </li>
    </ol>
    <ol>
      <li>
        <span>
          Tradey provides Buyers and Sellers with a Platform (defined below)
          enabling them to network and communicate for the purposes of trading a
          variety of recycled and recovered waste materials, waste to energy,
          and other secondary or raw materials (&ldquo;
        </span>
        <span className="c2">Product(s)</span>
        <span>
          &rdquo;). For the avoidance of doubt, the purchase and sale
          transaction between the Buyer and Seller (both defined below) shall
          take place outside the Platform, and be strictly between the Buyer and
          Seller (both defined below).
        </span>
      </li>
      <li>
        <span>
          Tradey is at no point responsible for the quality, purity, weight,
          volume and/or heating value of the materials put up for sale.
        </span>
      </li>
      <li>
        <span>
          Tradey is at no point responsible for the validity of the
          environmental and/or waste management permits and/or codes of any
          party operating on the website and/or platform, and this
          responsibility lies solely on the Buyer and Seller.
        </span>
      </li>
    </ol>
    <ol>
      <li className="terms-page-bold">
        <span>Tradey also:</span>
      </li>
    </ol>
    <ol>
      <li>
        <span>provides all Product owners (&ldquo;</span>
        <span className="c2">Seller(s)</span>
        <span>
          &rdquo;) with a marketplace platform (hereinafter referred to as the
          &ldquo;
        </span>
        <span className="c2">Platform</span>
        <span>&rdquo;) for each Seller to sell Seller&rsquo;s Products,</span>
      </li>
      <li>
        <span>
          provides potential/prospective Product buyers with the Platform for
          them to view, network and communicate with Sellers (&ldquo;
        </span>
        <span className="c2">Buyer(s)</span>
        <span>&rdquo;),</span>
      </li>
      <li>
        <span>
          provides you with the Website so as to learn more about our service
          offerings;
        </span>
      </li>
      <li>
        <span>
          acts as a third-party intermediary and as such facilitates the sale
          and purchase of Products between Seller and Buyer on the Platform,
          (collectively the &ldquo;
        </span>
        <span className="c2">Service(s)</span>
        <span>&rdquo;).</span>
      </li>
    </ol>
    <ol>
      <li>
        <span>
          You acknowledge that Tradey may change, suspend, or discontinue any or
          all of the Services, at its sole discretion at any time, without
          notice and for any or no reason.
        </span>
      </li>
    </ol>
    <ol>
      <li>
        <span className="c2">Support Services</span>
        <span>
          . Tradey shall provide you with support services via our email and
          telephone to address any queries and concerns you may have pertaining
          to the Services as well as to any payment/account issue. We shall
          endeavour to resolve any queries and concerns you may have within
          seven (7) working days from the date you first logged the issue with
          us.
        </span>
      </li>
    </ol>
    <ol>
      <li>
        <span className="terms-page-bold">
          Registration, Password and Security
        </span>
      </li>
    </ol>
    <p className="terms-page-bold">
      <span>
        3.1 To be able to use the Services on the Platform, you must register by
        providing all required information which shall include but not be
        limited to your full name, email address, company name, company address,
        company registration number, company vat number, proof of identity (for
        example: national identity, driving license or passport) and contact
        information. Once Tradey has verified your information, an account shall
        thereafter be opened by Tradey for you on the{" "}
      </span>
      <span>Platform</span>
      <span>&nbsp;(&ldquo;</span>
      <span className="c2">User Account</span>
      <span>&rdquo;). You acknowledge that Tradey may use </span>
      <span>official and public sources</span>
      <span>
        &nbsp;to verify the validity of the information you have provided to us
        when registering for your User Account.
      </span>
    </p>
    <p className="terms-page-bold">
      <span>You agree to:</span>
    </p>
    <p className="terms-page-bold">
      <span>
        (i) provide true, accurate, current and complete information about
        yourself as prompted by Tradey&rsquo;s registration process on the{" "}
      </span>
      <span>Platform</span>
      <span>; and</span>
    </p>
    <p className="terms-page-bold">
      <span>
        (ii) maintain and promptly update the information provided during
        registration to keep it true, accurate, current, and complete at all
        times.
      </span>
    </p>
    <p className="terms-page-bold">
      <span>
        3.3 If you provide any information to Tradey that is untrue, inaccurate,
        outdated, or incomplete, or if we have reasonable grounds to suspect
        that such information is untrue, inaccurate, outdated, or incomplete, we
        may close your User Account on the{" "}
      </span>
      <span>Platform</span>
      <span>
        &nbsp;and/or deny you the ability to access and use the Services.
      </span>
    </p>
    <p className="terms-page-bold">
      <span>
        3.4 You shall be solely responsible for (i) maintaining the
        confidentiality of your Login Credentials; and (ii) any and all
        activities which occur under your User Account. You agree to notify
        Tradey immediately of any unauthorized use of your User Account or any
        other breach of security. Tradey shall not be liable for any loss that
        you may incur as a result of any third party using your Login
        Credentials and/or accessing/using your User Account either with or
        without your knowledge. However, you may be held liable for losses
        incurred by Tradey due to any third party using your Login Credentials
        and/or User Account. You acknowledge and agree that you may not use the
        User Account of another Buyer or Seller registered on the{" "}
      </span>
      <span>Platform</span>
      <span>
        &nbsp;without the express permission of such User Account holder.
      </span>
    </p>
    <ol>
      <li>
        <span className="terms-page-bold">
          Purchasing Products on the Platform
        </span>
      </li>
    </ol>
    <ol>
      <li>
        <span>
          To purchase a Product on Tradey&rsquo;s Platform a Buyer may either
          (i) make an offer to purchase the Product for a price acceptable to
          the Seller, or (ii) purchase a specific Product listed on the Platform
          at Seller&rsquo;s selling/asking price.
        </span>
      </li>
    </ol>
    <ol>
      <li>
        <span>The Buyer acknowledges and agrees that:</span>
      </li>
    </ol>
    <ol>
      <li>
        <span>
          Tradey facilitates the networking opportunities between Buyer and
          Seller only and strictly as a third-party intermediary. As such, Buyer
          acknowledges and agrees that any corresponding Product purchase and
          sale agreement shall be solely and exclusively entered into by and
          between Buyer and Seller, and shall take place outside the Platform;
        </span>
      </li>
      <li>
        <span>
          Seller shall be solely and exclusively responsible for setting the
          price for each of Seller&rsquo;s Products listed on the Platform;
        </span>
      </li>
      <li>
        <span>
          all Product purchases made by Buyer outside the Platform are strictly
          between Buyer and Seller;
        </span>
      </li>
      <li>
        <span>
          Buyer shall be solely responsible for conducting Buyer&rsquo;s own due
          diligence prior to purchasing the Product(s) and completing the
          sale/purchase transaction with the Seller;
        </span>
      </li>
      <li>
        <span>
          all updates, information, materials and technical support related to
          the Product(s) shall be provided to Buyer by Seller only; and
        </span>
      </li>
      <li>
        <span>
          all payments or portions thereof made by Buyer, to purchase any
          Product shall be strictly between the Buyer and Seller.
        </span>
      </li>
    </ol>
    <ol>
      <li>
        <span className="c2">Selling Products on the Platform</span>
      </li>
    </ol>
    <ol>
      <li>
        <span>
          Seller may freely list Seller&rsquo;s Product(s) on the Platform
          provided that Seller&rsquo;s Product(s) meet(s) Tradey&rsquo;s Product
          requirements.
        </span>
      </li>
    </ol>
    <ol>
      <li>
        <span>
          Once Seller has listed Seller&rsquo;s Product(s) on the Platform,
          Seller shall indicate the indicative price Seller would accept for the
          Product, relevant information/description pertaining to the Product
          and upload up-to-date and actual photos of the Product listed on the
          Platform. As such, Seller agrees to not upload generic or stock
          photographs of the Product(s) Seller lists on the Platform.
        </span>
      </li>
    </ol>
    <ol>
      <li>
        <span>The Seller acknowledges and agrees that:</span>
      </li>
    </ol>
    <ol>
      <li>
        <span>
          Seller shall be solely and exclusively responsible for
          setting/determining the price for any and all Seller&rsquo;s Products
          listed for sale on the Platform;
        </span>
      </li>
      <li>
        <span>
          Seller shall be solely responsible for monitoring the availability of
          any Seller&rsquo;s Product(s) listed on the Platform to ensure that
          all Seller&rsquo;s Products listed on the Platform truly exist, are
          valid and are owned by Seller;
        </span>
      </li>
      <li>
        <span>
          Seller may not and shall not list any Seller&rsquo;s Product more than
          once on the Platform;
        </span>
      </li>
      <li>
        <span>
          Seller shall be solely responsible for any and all Products Seller
          lists on the Platform,
        </span>
      </li>
      <li>
        <span>
          Seller shall be solely responsible for complying with (a) applicable
          laws and regulations in relation to any Products which Seller has
          listed and classNameified as hazardous or non-hazardous, (b) the Basel
          convention (where applicable), and (c) any other relevant local laws,
          agencies, and governmental authorities; and
        </span>
      </li>
      <li>
        <span>
          any sale of Seller&rsquo;s Product shall take place outside the
          Platform and the purchase sale/transaction shall be strictly between
          Buyer and Seller.
        </span>
      </li>
    </ol>
    <ol className="c4 lst-kix_list_28-0 start" start="6">
      <li>
        <span className="c2">Fees and Taxes</span>
      </li>
    </ol>
    <p>
      <span>6.1. </span>
      <span className="c2">Fees</span>
      <span>.</span>
      <span>&nbsp;</span>
      <span>When you subscribe to </span>
      <span>Tradey platform</span>
      <span>
        , you shall provide Tradey with your full name, business name, address
      </span>
      <span>&nbsp;and</span>
      <span>
        &nbsp;contact information. By submitting such information, you
        automatically authorize Tradey{" "}
      </span>
      <span>to send bills for the services.</span>
    </p>
    <p>
      <span>6.2. </span>
      <span>
        You shall be responsible for providing us with true, current, complete,
        and accurate billing and contact information and for notifying us of any
        changes to such information.
      </span>
    </p>
    <p>
      <span>6.3. </span>
      <span>You warrant that you are authorized to </span>
      <span>pay the bill</span>
      <span>.</span>
    </p>
    <p>
      <span>
        6.4. Tradey reserves the right to present an invoice when the Buyer or
        Seller removes their offer that has at least one bid of 80% or more of
        the indicated price value.
      </span>
    </p>
    <p>
      <span>6.4. </span>
      <span>
        You acknowledge and agree that the fees shall be (i) quoted and payable
        in{" "}
      </span>
      <span>euros</span>
      <span>&nbsp;(</span>
      <span>EUR</span>
      <span>); (ii) payable on time; and (iii) strictly non-refundable.</span>
    </p>
    <p>
      <span>6.5. </span>
      <span>
        If Tradey does not receive payment from you on the due date, you shall
        immediately pay all amounts due to Tradey upon request. Should Tradey be
        unable to collect all such outstanding amounts, Tradey may in its sole
        discretion close and cancel your User Account and access to the Services
        without any notice to you.
      </span>
    </p>
    <p>
      <span>6.6. </span>
      <span>
        Tradey may in its sole discretion and at any time, modify the fees.
        Tradey shall provide you with reasonable prior notice of at least thirty
        (30) days prior to any change in fees to give you the opportunity to
        terminate your{" "}
      </span>
      <span>account</span>
      <span>
        &nbsp;before such change becomes effective. Your continued use of the
        Services after the fee change has come into effect shall constitute your
        agreement to pay the modified fee amount.
      </span>
    </p>
    <p>
      <span>6.7. </span>
      <span>
        You agree to notify Tradey of any billing queries and errors within
        thirty (30) days after receipt of your respective invoice. Should you
        not notify Tradey of any billing queries and/or errors, this absence of
        notification on your part shall be deemed to constitute your waiver of
        your right to dispute such queries and errors at a later date.
      </span>
    </p>
    <p>
      <span>6.8. </span>
      <span>
        You acknowledge and agree that Tradey may, from time to time, add
        additional features or functionalities to the Services, as such your
        access to and use of any additional features and functionalities to the
        Services may require the payment of additional fees by you.
      </span>
    </p>
    <p>
      <span>7. </span>
      <span className="c2">Taxes</span>
      <span>.</span>
    </p>
    <p>
      <span>7.1. </span>
      <span>Under these Terms, the term &ldquo;</span>
      <span className="c2">Taxes</span>
      <span>
        &rdquo; shall mean taxes, charges, duties, fees, levies, and other
        charges of a governmental authority, including income, withholding,
        social security, social contribution, transfer, sales, use, value-added
        and all other taxes of any kind for which a Party may have any liability
        imposed by any governmental authority, whether disputed or not, any
        related charges, interest or penalties imposed by any governmental
        authority, and any liability for any other person as a transferee or
        successor by Law, contract or otherwise.&nbsp;
      </span>
    </p>
    <p>
      <span>7.2. </span>
      <span>
        All fees for the Products and/or Services, as illustrated on
        Tradey&rsquo;s Website and Platform are exclusive of any and all Taxes.
      </span>
    </p>
    <p>
      <span>7.3. </span>
      <span>
        You shall be responsible for the payment of all Taxes associated with
        the purchase or sale of the Products via the Platform and as part of
        your use of the Services. You shall not deduct from the payments due to
        Tradey, any Taxes, except as is required by applicable law.
      </span>
    </p>
    <p>
      <span>8. </span>
      <span className="c2">Acceptable Use of the Website and the Platform</span>
    </p>
    <p>
      <span>8.1. </span>
      <span>
        You may not use the Website and the Platform to transmit, distribute,
        store or destroy any material or information (i) in violation of any
        applicable law or regulation; (ii) in a manner that infringes
        Tradey&rsquo;s Intellectual Property Rights (defined below) or any third
        party&rsquo;s intellectual property rights; (iii) in a manner that
        violates the privacy or other personal rights of third parties; and/or
        (iv) that is defamatory, obscene, threatening, discriminatory, abusive
        or hateful.
      </span>
    </p>
    <p>
      <span>8.2. </span>
      <span>
        When accessing the Website and the Platform, you shall be prohibited
        from:
      </span>
    </p>
    <ol>
      <li>
        <span>
          using any robot, spider, other automatic device or manual process to
          monitor or copy any part of the Website and the Platform;
        </span>
      </li>
      <li>
        <span>
          using any device, software or the like to interfere or attempt to
          interfere with the proper functioning of the Website and the Platform;
        </span>
      </li>
      <li>
        <span>
          taking any action that imposes an unreasonable or disproportionately
          large data load on the Website and the Platform
          infrastructu&otilde;re;
        </span>
      </li>
      <li>
        <span>
          copying, reproducing, altering, modifying, creating derivative works,
          or publicly displaying any content from the Website and the Platform
          without Tradey&rsquo;ss prior written consent;
        </span>
      </li>
      <li>
        <span>
          reverse-assembling, reverse-engineering or otherwise attempting to
          discover any source code relating to the Website (including the
          Platform) or any tool therein, except to the extent that such activity
          is expressly permitted by applicable law notwithstanding this
          limitation;
        </span>
      </li>
      <li>
        <span>
          attempting to access any area of the Website (including the Platform)
          to which access is not authorized;
        </span>
      </li>
      <li>
        <span>
          conducting any systematic or automated data collection activities on
          or in relation to the Website (including the Platform), including but
          not limited to data scraping, mining, extraction, harvesting, framing
          and article spinning, without Tradey&rsquo;s prior written consent;
        </span>
      </li>
      <li>
        <span>
          using the Website (including the Platform) to transmit or send
          unsolicited commercial and marketing communications for any purpose,
          without Tradey&rsquo;s prior written consent;
        </span>
      </li>
      <li>
        <span>
          using manual or automated software, devices, or other processes to
          &ldquo;crawl,&rdquo; &ldquo;scrape,&rdquo; or &ldquo;spider&rdquo; any
          page of the Website (including the Platform);
        </span>
      </li>
      <li>
        <span>
          probing, scanning, or testing the vulnerability of any of
          Tradey&rsquo;s system or network or breach or circumvent any of
          Tradey&rsquo;s security or authentication measures;&nbsp;
        </span>
      </li>
      <li>
        <span>
          accessing, searching or attempting to access or search the Website
          (including the Platform) and/or Services by any means other than
          through Tradey&rsquo;s currently available, published interfaces that
          are provided by Tradey, unless you have been specifically allowed to
          do so in a separate agreement with Tradey;
        </span>
      </li>
      <li>
        <span>
          uploading, posting, emailing, transmitting, or otherwise offering any
          unsolicited or{" "}
        </span>
        <span>unauthorised</span>
        <span>
          &nbsp;advertising, promotional flyers, &ldquo;junk mail,&rdquo;
          &ldquo;spam,&rdquo; or any other form of solicitation, except in any
          such areas that may have been designated for such purpose;&nbsp;
        </span>
      </li>
      <li>
        <span>
          disrupting or interfering with the access of any user, host or
          network, including, without limitation, sending a virus, overloading,
          flooding, spamming, mail-bombing the Website;
        </span>
      </li>
      <li>
        <span>
          forging captions, headings or titles; or uploading, posting, emailing,
          transmitting, or otherwise offering any content that you personally
          have no right to pursuant to any law nor having any contractual or
          fiduciary relationship;&nbsp;
        </span>
      </li>
      <li>
        <span>
          uploading, posting, emailing, transmitting, or otherwise offering any
          source that may contain a software virus or other computer code, any
          files and/or programs which have been designed to interfere, destroy
          and/or limit the operation of any computer software, hardware, or
          telecommunication equipment;&nbsp;
        </span>
      </li>
      <li>
        <span>
          disrupting the normal flow of communication, or otherwise acting in
          any manner that would negatively affect other users&rsquo; ability to
          participate in any real-time interactions with viewers on the Website
          (including the Platform);
        </span>
      </li>
      <li>
        <span>
          intentionally or unintentionally violating any local, state, federal,
          national or international law, in addition to any rules of any nation
          or other securities exchange, and any regulations having the force of
          law;
        </span>
      </li>
      <li>
        <span>
          impersonating any other person or entity, sell your profile, provide
          false or misleading identification, payment or address information, or
          invade the privacy, or violate the personal or proprietary right, of
          any person or entity; and/or,
        </span>
      </li>
      <li>
        <span>
          collecting or storing of any personal data relating to any other user
          in connection with the prohibited conduct and/or activities which have
          been set forth in the aforementioned paragraphs.
        </span>
      </li>
    </ol>
    <ol className="c4 lst-kix_list_44-0 start" start="9">
      <li>
        <span className="c2 terms-page-bold">User Content</span>
      </li>
    </ol>
    <ol className="c4 lst-kix_list_43-0 start" start="1">
      <li className="terms-page-bold">
        <span>
          Tradey allows you to make available certain information, text,
          graphics, videos, images, personal picture, personal statement(s), or
          any other material (&ldquo;
        </span>
        <span className="terms-page-bold">User Content</span>
        <span>
          &rdquo;) on the Platform. You shall be responsible for the User
          Content that you post to the Platform, including for its legality,
          reliability, and appropriateness. By posting User Content on the
          Platform, you grant Tradey the right and (sub-)licence to use, modify,
          perform, display, reproduce, and distribute such User Content on and
          through the Platform. You shall retain any and all of your rights to
          any User Content you submit, post or display on or through the
          Platform, and you shall be solely responsible for protecting those
          rights.
        </span>
      </li>
      <li className="terms-page-bold">
        <span>You represent and warrant that:</span>
      </li>
      <li className="terms-page-bold">
        <span>
          the User Content belongs to you (i.e. you own it) or that you have the
          right to use it (e.g. as licensee) and grant Tradey the rights and
          (sub-)licence as granted by you under Section 10.1 of these Terms, and
        </span>
      </li>
      <li className="terms-page-bold">
        <span>
          the posting of User Content on or through the Platform does not
          violate (a) Tradey&rsquo;s Intellectual Property Rights (defined
          below) or any third party&rsquo;s intellectual property rights, (b)
          privacy rights, publicity rights, copyrights, contract rights or any
          other rights of Tradey or of any third party.
        </span>
      </li>
      <li className="terms-page-bold">
        <span>
          Tradey reserves the right to block or remove User Content that Tradey
          determines to be: (i) abusive, defamatory, or obscene; (ii)
          fraudulent, deceptive, or misleading; (iii) in violation of
          Tradey&rsquo;s Intellectual Property Rights (defined below) or of any
          third party&rsquo;s intellectual property rights or; (iv) offensive or
          otherwise unacceptable to Tradey in its sole and exclusive discretion.
          Tradey acknowledges that, by providing you with the ability to view
          and distribute user-generated content on the Platform, Tradey is
          merely acting as a passive conduit for such distribution and is not
          assuming any obligation or liability relating to any contents or
          activities on the Platform.
        </span>
      </li>
    </ol>
    <ol>
      <li>
        <span>
          You acknowledge and agree that you shall be solely and exclusively
          responsible for maintaining, updating, safeguarding and protecting all
          of your User Content on the Platform. As such, Tradey will not be
          liable for any loss, damage or corruption of your User Content, or for
          any costs or expenses associated with backing up or restoring any of
          your User Content. You further acknowledge and agree that you remain
          responsible for the transmission of your User Content, data or files
          to Tradey, and as such it is your responsibility to use a secure
          encrypted connection to communicate with Tradey.
        </span>
      </li>
    </ol>
    <ol>
      <li>
        <span className="terms-page-bold">Feedback. </span>
      </li>
    </ol>
    <p>
      <span>
        By sending Tradey any ideas, suggestions, comments, documents or
        proposals (&ldquo;
      </span>
      <span className="terms-page-bold">Feedback</span>
      <span>
        &rdquo;), you (i) warrant that your Feedback does not contain any
        confidential or proprietary information belonging to any
        third-party(ies), (ii) agree that we are under no obligation of
        confidentiality, express or implied, with respect to the Feedback, (iii)
        agree that we may have feedback which is similar to your Feedback,
        already under consideration or in development, and (iv) hereby grant us
        an irrevocable, non-exclusive, royalty-free, perpetual, worldwide
        licence to use, modify, prepare derivative works, publish, distribute
        and sublicense the Feedback, and you irrevocably waive, and cause to be
        waived, against Tradey and its vendors and users any claims and
        assertions of any intellectual property rights and/or moral rights
        contained in such Feedback.
      </span>
    </p>
    <ol className="c4 lst-kix_list_44-0" start="11">
      <li>
        <span className="terms-page-bold">
          Buyer&rsquo;s and Seller&rsquo;s Representations and Warranties
        </span>
      </li>
    </ol>
    <p className="terms-page-bold">
      <span>
        Each Buyer or Seller hereby represents and warrants that such
        Buyer&rsquo;s/Seller&rsquo;s access to the Platform, and use of the
        Services will:
      </span>
    </p>
    <ol className="c4 lst-kix_list_42-0 start" start="1">
      <li>
        <span>be in accordance with these Terms;</span>
      </li>
      <li>
        <span>
          comply with all applicable laws and regulations (including, without
          limitation, all applicable laws regarding online conduct and
          acceptable content, privacy, data protection, and environme
        </span>
        <span>ntal protection</span>
        <span>);</span>
      </li>
      <li>
        <span>not be for any unlawful purposes;</span>
      </li>
      <li>
        <span>
          not entail, imply or give rise to the publication of any illegal
          content;
        </span>
      </li>
      <li>
        <span>not further any illegal activities;</span>
      </li>
      <li>
        <span>
          not infringe upon or misappropriate any of Tradey&rsquo;s Intellectual
          Property Rights (defined below) or any third party&rsquo;s
          intellectual property rights; and
        </span>
      </li>
      <li>
        <span>
          not be used to create, distribute, facilitate, or operate in
          conjunction with malware, spyware, adware, or other malicious programs
          or code.
        </span>
      </li>
    </ol>
    <ol>
      <li>
        <span className="terms-page-bold">Disclaimer of Warranties</span>
      </li>
    </ol>
    <ol>
      <li className="terms-page-bold">
        <span>
          Unless otherwise provided under these Terms, the Services&nbsp;shall
          be&nbsp;provided by Tradey to you &ldquo;
        </span>
        <span className="c7 ">as is</span>
        <span>,&rdquo; and &ldquo;</span>
        <span className="c7 ">as available</span>
        <span>&rdquo; with all faults, defects, bugs, and errors.</span>
      </li>
      <li className="terms-page-bold">
        <span>
          TRADEY HEREBY (I) DISCLAIMS ALL WARRANTIES AND CONDITIONS WITH REGARD
          TO YOUR USE OF THE SERVICES AND/OR TO YOUR SALE/PURCHASE (OUTSIDE THE
          PLATFORM) AND USE OF ANY PRODUCTS; AND (II) MAKES NO WARRANTIES OR
          GUARANTEES OF ANY KIND WITH RESPECT TO THE USER CONTENT, INCLUDING ALL
          WARRANTIES AND CONDITIONS OF MERCHANTABILITY, WHETHER EXPRESS, IMPLIED
          OR STATUTORY, FITNESS FOR A PARTICULAR PURPOSE, TITLE,
          NON-INFRINGEMENT, QUIET ENJOYMENT OR INTEGRATION.
        </span>
      </li>
      <li className="terms-page-bold">
        <span>
          TRADEY AND/OR ITS RESPECTIVE AFFILIATES (IF ANY), LICENSORS AND
          VENDORS MAKE NO REPRESENTATIONS ABOUT THE SUITABILITY, ACCURACY,
          RELIABILITY OR COMPLETENESS OF (i) THE INFORMATION CONTAINED ON THE
          WEBSITE, PLATFORM AND SERVICES, AND (ii) RELATED GRAPHICS PUBLISHED ON
          THE WEBSITE, PLATFORM AND SERVICES FOR ANY PURPOSE. THE WEBSITE,
          PLATFORM, SERVICES AND/OR RELATED GRAPHICS ARE PROVIDED TO YOU BY
          TRADEY &ldquo;AS IS&rdquo; WITHOUT WARRANTY OF ANY KIND. TRADEY AND/OR
          ITS RESPECTIVE AFFILIATES (IF ANY) HEREBY DISCLAIM ALL WARRANTIES AND
          CONDITIONS WITH REGARD TO THIS INFORMATION, INCLUDING ALL WARRANTIES
          AND CONDITIONS OF MERCHANTABILITY, WHETHER EXPRESS, IMPLIED OR
          STATUTORY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND
          NON-INFRINGEMENT.
        </span>
      </li>
      <li className="terms-page-bold">
        <span>
          YOU ACKNOWLEDGE THAT THE INFORMATION AND RELATED GRAPHICS PUBLISHED ON
          THE WEBSITE, PLATFORM AND/OR SERVICES COULD INCLUDE TECHNICAL
          INACCURACIES OR TYPOGRAPHICAL ERRORS AND CHANGES, WHICH ERRORS AND
          CHANGES MAY BE PERIODICALLY MADE TO THE WEBSITE, PLATFORM AND/OR
          SERVICES HEREIN.
        </span>
      </li>
      <li className="terms-page-bold">
        <span>
          NEITHER TRADEY NOR ITS AFFILIATES (IF ANY), LICENSORS, AND VENDORS
          MAKE ANY REPRESENTATION, WARRANTY OR GUARANTEE THAT THE INFORMATION
          THAT MAY BE AVAILABLE ON THE WEBSITE, PLATFORM AND SERVICES IS FREE OF
          INFECTION FROM ANY VIRUSES OR OTHER CODE OR COMPUTER PROGRAMMING
          ROUTINES THAT CONTAIN CONTAMINATING OR DESTRUCTIVE ELEMENTS OR THAT
          ARE INTENDED TO DAMAGE, SURREPTITIOUSLY INTERCEPT OR EXPROPRIATE YOUR
          COMPUTER SYSTEM, DATA OR PERSONAL INFORMATION.
        </span>
      </li>
      <li className="terms-page-bold">
        <span>
          TRADEY PROVIDES YOU WITH THE SERVICES ON THE WEBSITE IN GOOD FAITH AND
          MAKES NO WARRANTY THAT (I) THE SERVICES WILL MEET YOUR REQUIREMENTS OR
          EXPECTATIONS, (II) THAT YOUR ACCESS TO OR USE OF THE WEBSITE, PLATFORM
          AND SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE OR ERROR FREE,
          (III) THAT ANY DEFECTS IN THE WEBSITE, PLATFORM AND/OR SERVICES WILL
          BE CORRECTED, OR (IV) THAT THE WEBSITE, PLATFORM AND/OR SERVICES OR
          ANY SERVER THROUGH WHICH YOU ACCESS THE SERVICES ARE FREE FROM VIRUSES
          OR OTHER HARMFUL COMPONENTS.
        </span>
      </li>
      <li className="terms-page-bold">
        <span>
          ANY MATERIAL/INFORMATION DOWNLOADED, CAPTURED, SUBMITTED, CREATED OR
          OTHERWISE OBTAINED THROUGH THE USE OF THE WEBSITE, PLATFORM AND
          SERVICES ARE ACCESSED AT YOUR OWN RISK, AND YOU WILL BE SOLELY LIABLE
          FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS
          FROM THE DOWNLOAD, CAPTURING, SUBMISSION OR CREATION BY YOU OF ANY
          SUCH MATERIAL/INFORMATION.
        </span>
      </li>
      <li className="terms-page-bold">
        <span>
          THE INFORMATION FOUND ON THE WEBSITE, PLATFORM AND WITHIN THE SERVICES
          IS ONLY FOR INFORMATION PURPOSES ONLY. TRADEY WILL NOT BE HELD
          RESPONSIBLE FOR ANY DAMAGES THAT MAY BE INCURRED BY YOU AS A RESULT OF
          SUCH INFORMATION. ALL INFORMATION AND CONTENT ON THE WEBSITE, PLATFORM
          AND SERVICES IS COPYRIGHTED, AND MAY NOT BE REPUBLISHED, COPIED, SOLD
          OR POSTED ANYWHERE ONLINE OR IN PRINT. DOING SO WILL BE AGAINST THE
          LAW AND VIOLATORS WILL BE PROSECUTED ACCORDINGLY.&nbsp;
        </span>
      </li>
    </ol>
    <ol className="c4 lst-kix_list_44-0" start="13">
      <li>
        <span className="terms-page-bold">Intellectual Property</span>
      </li>
    </ol>
    <ol className="c4 lst-kix_list_40-0 start" start="1">
      <li className="terms-page-bold">
        <span>
          All content, trademarks, data, information or information contained in
          any materials, or documents used in relation to the Website, Platform
          and Services, including but not limited to any and all copyrighted
          works, databases, text, graphics, icons, designs, logos, graphics,
          hyperlinks, domain names, codes, information and agreements (&ldquo;
        </span>
        <span className="c2">Materials</span>
        <span>
          &rdquo;), are the sole and exclusive property of or are licensed to
          Tradey. You may not reproduce, distribute or copy the Materials by any
          means, whether electronically or not, without Tradey&rsquo;s prior
          written permission.
        </span>
      </li>
      <li className="terms-page-bold">
        <span>
          Any and all intellectual property rights in the Materials, Website,
          Platform and Services, including all patents, rights in inventions,
          rights in designs, trademarks, trade and business names and all
          associated goodwill, rights to sue for passing off or for unlawful
          competition, copyright, moral rights and related rights, rights in
          databases, topography rights, domain names, rights in information
          (including know how and trade secrets) and all other similar or
          equivalent rights existing in the Materials, and Services, now or in
          the future in any part of the world, in each case whether registered
          or unregistered and including all applications for, and renewals or
          extensions of, such rights vests solely and exclusively in Tradey, its
          affiliates (if any), its licensors, the respective Seller or vendors,
          as the case may be. All rights not expressly granted by Tradey to you
          are reserved by Tradey. Save as expressly set out herein, you shall
          not acquire any right, title or interest in Tradey&rsquo;s
          Intellectual Property Rights.
        </span>
      </li>
      <li className="terms-page-bold">
        <span>
          The Seller shall be the sole and exclusive owner of all Seller&rsquo;s
          intellectual property rights in the Products which the Seller lists on
          the Platform, User Content and Your Personality Attributes. Tradey may
          not use any of Seller&rsquo;s Intellectual Property Rights for any
          other purpose, other than mentioned under these Terms and not without
          the Seller&rsquo;s prior written consent. The Seller hereby grants
          Tradey the non-exclusive right to use Seller&rsquo;s Intellectual
          Property Rights, free of charge, in connection with providing Seller
          and Buyer with networking opportunities on the Platform, to the extent
          necessary for Tradey to (i) exercise Tradey&rsquo;s rights under these
          Terms, and (ii) perform Tradey&rsquo;s obligations under these Terms.
        </span>
      </li>
    </ol>
    <ol className="c4 lst-kix_list_44-0" start="14">
      <li>
        <span className="terms-page-bold">Indemnification</span>
      </li>
    </ol>
    <ol className="c4 lst-kix_list_29-0 start" start="1">
      <li>
        <span>
          You agree to indemnify and hold harmless Tradey from and against any
          liability, demand, damages, cost, claim or expense arising from a
          third party claim arising from: (i) your violation of these Terms;
          (ii) your use or misuse of the Service(s) and/or Product(s); (iii)
          your infringement of Tradey&rsquo;s Intellectual Property Rights; (iv)
          from Seller&rsquo;s violation/breach of any existing agreement (e.g.
          sale/purchase agreement) between Seller and Buyer or any such third
          party in regard to any Product(s) sold by Seller to Buyer outside the
          Platform (in breach of such existing agreement); and/or (v) any
          activities conducted by you through/on any third party website. Tradey
          shall indemnify and hold you harmless from and against any liability
          or expense arising from a third party claim based on any Negligence of
          Tradey. &ldquo;
        </span>
        <span className="c7 ">Negligence</span>
        <span>
          &rdquo; shall mean gross negligence or intentional misconduct.
        </span>
      </li>
    </ol>
    <ol>
      <li>
        <span>
          In the event of a claim subject to indemnification hereunder, the
          indemnified Party shall: (i) promptly notify the indemnifying Party of
          the claim, (ii) provide the indemnifying Party with reasonable
          cooperation and assistance, at the indemnifying Party&rsquo;s expense,
          to defend such claim; and (iii) allow the indemnifying Party the
          opportunity to assume the control of the{" "}
        </span>
        <span>defence</span>
        <span>
          &nbsp;and settlement of such claim. The indemnified Party shall be
          entitled to participate in the{" "}
        </span>
        <span>defence</span>
        <span>
          &nbsp;of such claim and to employ counsel at its own expense to assist
          in the handling of such claim. The indemnifying Party must obtain the
          prior written approval from a duly{" "}
        </span>
        <span>authorised</span>
        <span>
          &nbsp;signatory of the indemnified Party prior to entering into any
          settlement affecting the indemnified Party&rsquo;s rights.
        </span>
      </li>
    </ol>
    <ol>
      <li>
        <span className="terms-page-bold">Limitation of Liability</span>
      </li>
    </ol>
    <ol>
      <li>
        <span>
          TRADEY, ITS AFFILIATES (IF ANY), ITS LICENSORS, VENDORS, AND THEIR
          RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AND AGENTS SHALL IN NO
          EVENT BE RESPONSIBLE OR LIABLE TO YOU OR TO ANY THIRD PARTY, WHETHER
          IN CONTRACT, WARRANTY, TORT (INCLUDING NEGLIGENCE) OR OTHERWISE, FOR
          ANY INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, EXEMPLARY,
          LIQUIDATED OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO LOSS OF
          PROFIT, REVENUE OR BUSINESS, ARISING IN WHOLE OR IN PART FROM YOUR
          ACCESS TO THE WEBSITE AND THE PLATFORM, FROM YOUR USE OF THE SERVICES
          ON THE PLATFORM, EVEN IF TRADEY HAS BEEN ADVISED OF THE POSSIBILITY OF
          SUCH DAMAGES.
        </span>
      </li>
    </ol>
    <ol className="c4 lst-kix_list_32-0 start" start="1">
      <li>
        <span>
          WHILE TRADEY TAKES PRECAUTIONS AGAINST SECURITY BREACHES, NO WEBSITE,
          MOBILE APP OR INTERNET TRANSMISSION IS COMPLETELY SECURE, AND AS SUCH,
          TRADEY AND ITS AFFILIATES (IF ANY), LICENSORS, AND VENDORS SHALL NOT
          BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL,
          EXEMPLARY OR CONSEQUENTIAL DAMAGES THAT MAY RESULT FROM{" "}
        </span>
        <span>UNAUTHORISED</span>
        <span>
          &nbsp;ACCESS, HACKING, DATA LOSS, OR OTHER BREACHES THAT MAY OCCUR ON
          THE WEBSITE
        </span>
        <span>&nbsp;</span>
        <span>THROUGH YOUR USE OF THE SERVICES ON THE PLATFORM.</span>
      </li>
    </ol>
    <ol>
      <li>
        <span>
          NOTWITHSTANDING ANYTHING TO THE CONTRARY IN THESE TERMS,
          TRADEY&rsquo;S (INCLUDING ITS AFFILIATES (IF ANY), ITS LICENSORS,
          VENDORS AND THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AND
          AGENTS) LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF
          THE FORM OF ACTION, SHALL AT ALL TIMES BE LIMITED TO THE TOTAL AMOUNT
          OF FEE PAID BY YOU FOR{" "}
        </span>
        <span>the last deal or monthly subscription</span>
        <span>.</span>
      </li>
    </ol>
    <ol>
      <li>
        <span className="c2">Privacy Policy</span>
      </li>
    </ol>
    <p className="terms-page-bold">
      <span>Tradey&rsquo;s Privacy Policy, available at </span>
      <span className="c7 c8 c11">[insert link]</span>
      <span>
        , governs the use, storage, and processing of the personal information
        you may provide to Tradey through your (i) access to the Website and
        Platform, (ii) use of the Services, and/or (iii) Product
        purchase(s)/sale(s) on the Platform. Your election to use the Services
        and the Platform, and to purchase/sell Products shall be deemed to
        constitute your acceptance of the terms of Tradey&rsquo;s Privacy
        Policy.
      </span>
    </p>
    <ol>
      <li>
        <span className="terms-page-bold">
          Tradey&rsquo;s&nbsp;Monitoring of the Website, Platform and Services
        </span>
      </li>
    </ol>
    <p className="terms-page-bold">
      <span>15.1 You acknowledge and agree that Tradey may:</span>
    </p>
    <p className="terms-page-bold">
      <span>
        (i) monitor or review the Website, Platform, Services, User Content to
        verify your compliance with these Terms and only to the extent described
        in Tradey&rsquo;s Privacy Policy;
      </span>
    </p>
    <p className="terms-page-bold">
      <span>
        (ii) report your actions to law enforcement authorities and/or take
        legal action against any user who/which violates these Terms;
      </span>
    </p>
    <p className="terms-page-bold">
      <span>
        (iii) refuse, deny, disable (to the extent technologically feasible) and
        restrict your access to any of your User Content which may be (a) in
        breach of these Terms, (b) in breach of any applicable laws, or (c)
        excessive in size or burdensome, without prior notice to you; or
      </span>
    </p>
    <p className="terms-page-bold">
      <span>
        (iv) manage the Website in a manner which (a) protects our rights, (b)
        protects third parties&rsquo; rights and property, or (c) facilitates
        the proper functioning of the Website, Platform and Services.
      </span>
    </p>
    <p className="terms-page-bold">
      <span>
        15.2 You acknowledge that there are potential risks, which include but
        are not limited to the risk of physical harm and the risk associated
        with communicating with any third party, including persons who may be
        acting under false pretences. As such, Tradey recommends that you
        carefully select the information you post on the Platform, and that you
        share with viewers.
      </span>
    </p>
    <ol className="c4 lst-kix_list_2-0 start" start="16">
      <li>
        <span className="terms-page-bold">Third Party Links</span>
      </li>
    </ol>
    <p className="terms-page-bold">
      <span>
        The Website, Platform and/or Services may contain links to third-party
        websites or resources, as well as links to the respective Seller&rsquo;s
        website. You acknowledge and agree that Tradey shall not be responsible
        or liable for: (i) the availability or accuracy of such websites or
        resources; or (ii) the content, products or services on or available
        from such websites or resources. Links and ads to such websites or
        resources do not imply any endorsement by Tradey of such websites or
        resources or the content, products, or services available from such
        websites or resources.
      </span>
    </p>
    <p className="terms-page-bold">
      <span>
        Your communications, interactions or business transactions/dealings with
        any third party found on or through the Website, Platform and/or the
        Services, including any such third party&rsquo;s terms, conditions,
        warranties or representations associated with such communications,
        interactions or business transactions/dealings, shall be solely between
        you and such third party. You acknowledge and agree that Tradey shall
        not be liable for any loss or damage incurred by you as the result of
        any communication, interaction, business transaction or other dealings
        you may have with any third party found through (i) the Website,
        Platform and/or (ii) your use of the Services.
      </span>
    </p>
    <ol className="c4 lst-kix_list_3-0 start" start="17">
      <li>
        <span className="terms-page-bold">Age Restriction</span>
      </li>
    </ol>
    <p className="terms-page-bold">
      <span>
        The Products and Services are not intended to persons younger than
        eighteen (18) years of age. By accessing and using this Website, and by
        agreeing to these Terms, you warrant and represent that you are at least
        eighteen (18) years of age.
      </span>
    </p>
    <ol>
      <li>
        <span className="c2">
          Suspension, Termination and Cancellation of Services
        </span>
      </li>
    </ol>
    <ol>
      <li>
        <span>
          Tradey may, in its sole discretion, suspend or temporarily disable
          access to all or part of the Website, Platform, User Account, or
          Services if (i) Tradey suspects you of partaking in any illegal
          activity; (ii) Tradey reasonably believes that you have violated these
          Terms; or (iii) applicable law enforcement or other government
          agencies have requested Tradey to suspend or temporarily disable your
          access to the Website, Platform, and/or Services.
        </span>
      </li>
    </ol>
    <ol>
      <li>
        <span>
          If Tradey breaches any of its obligations under these Terms, you may
          terminate these Terms and close your User Account. Upon, your
          termination of these Terms and closing of your User Account, Tradey
          may (i) block your access to all of the Website and Platform, (ii)
          block your use of the Services, (iii) delete all of your information
          associated with your User Account, including but not limited to your
          full name, address, contact information, email address and password,
          and (iv) delete all of your data in your User Account.
        </span>
      </li>
    </ol>
    <ol>
      <li>
        <span className="terms-page-bold">Compliance with Laws</span>
      </li>
    </ol>
    <p className="terms-page-bold">
      <span>
        Each Party&nbsp;shall comply with all applicable laws&nbsp;relating to
        the Services, keep records evidencing its compliance, on the other
        Party&rsquo;s reasonable request, provide these&nbsp;records of
        compliance&nbsp;to&nbsp;the other Party, and notify the other
        Party&nbsp;if it becomes aware of any non-compliance in connection with
        these Terms.
      </span>
    </p>
    <ol>
      <li>
        <span className="terms-page-bold">Dispute Resolution</span>
      </li>
    </ol>
    <p className="terms-page-bold">
      <span>
        20.1 If any dispute or difference shall arise between the Parties as to
        the meaning or application of these Terms, the rights or liabilities of
        the Parties or otherwise in relation to these Terms, then (without
        prejudice to any other express or implied rights or powers) the dispute
        or difference (&ldquo;
      </span>
      <span className="c2">Dispute</span>
      <span>&ldquo;) shall be determined as follows:</span>
    </p>
    <ol>
      <li>
        <span>
          A Party must not commence arbitration or court proceedings (except
          proceedings seeking interlocutory relief) relating to a Dispute
          arising under these Terms unless it has complied with this Section 20.
        </span>
      </li>
      <li>
        <span>
          A Party claiming that a Dispute has arisen under these Terms must give
          the other Party written notice of the particulars of the Dispute.
        </span>
      </li>
      <li>
        <span>
          In the event of a Dispute, difference or claim between the Parties
          hereto, arising out of the use of the Website, Platform and/or
          Services; or in any way relating to these Terms, or any term,
          condition or provision herein mentioned or the construction or
          interpretation thereof or otherwise in relation hereto, the Parties
          shall first endeavour to settle such difference, dispute, claim or
          question by mutual discussion, failing which the same shall be
          referred to The Court of Arbitration of the Estonian Chamber of
          Commerce and Industry, or any statutory modification or re-enactment
          thereof for the time being in force.
        </span>
      </li>
    </ol>
    <p className="terms-page-bold">
      <span>
        20.2 You acknowledge and agree that if any dispute or difference shall
        arise between you or another user or third-party, you shall be solely
        responsible for your conduct. You agree that Tradey shall not be liable
        for any dispute or difference that arises between you and any other user
        or third-party.
      </span>
    </p>
    <ol>
      <li>
        <span className="terms-page-bold">General Provisions</span>
      </li>
    </ol>
    <ol>
      <li>
        <span className="c2">Non-Exclusivity.&nbsp;</span>
        <span>
          The Parties&rsquo; respective obligations under these Terms are
          non-exclusive and nothing herein is intended to restrict you from
          accessing, purchasing, selling or using any other third party&rsquo;s
          products or services, even if such products or services are similar to
          the (i) Products listed by Sellers on the Platform, and (ii) Services
          provided by Tradey on the Website and the Platform.
        </span>
      </li>
    </ol>
    <p className="terms-page-bold">
      <span>
        Nothing herein is intended to limit Tradey&rsquo;s right to provide the
        Services to other registered users and to facilitate networking
        opportunities between other registered users as a third party
        intermediary.
      </span>
    </p>
    <ol>
      <li className="terms-page-bold">
        <span className="c2">Severability.&nbsp;</span>
        <span>
          If any of the provisions or portions of these Terms are held to be
          invalid under any applicable statute or rule of law, they are to that
          extent deemed to be omitted from these Terms without in any way
          invalidating or impairing the other provisions of these Terms.
        </span>
      </li>
      <li className="terms-page-bold">
        <span className="c2">No Waiver.&nbsp;</span>
        <span>
          Save for Section 6.10, A Party&rsquo;s failure or delay in enforcing
          any provision of these Terms will not operate as a waiver of the right
          to enforce that provision or any other provision of these Terms at any
          time. A waiver of any provision of these Terms shall be in writing,
          specify the provision to be waived and signed/executed by the Party
          agreeing to the waiver.
        </span>
      </li>
      <li className="terms-page-bold">
        <span className="c2">Notices.&nbsp;</span>
        <span>
          Except as otherwise provided under these Terms, any notice required or
          permitted to be given will be effective only if it is in writing and
          sent by certified mail, registered mail, courier or to Tartu, Tedre
          14, 50404, Estonia.
        </span>
      </li>
      <li className="terms-page-bold">
        <span className="c2">Entire Agreement.&nbsp;</span>
        <span>
          These Terms represent the entire agreement between the Parties
          relating to the subject matter hereof. These Terms alone fully and
          completely expresses the agreement of the Parties relating to the
          subject matter hereof. There are no other courses of dealing,
          understanding, agreements, representations or warranties, written or
          oral, except as set forth herein.
        </span>
      </li>
      <li className="terms-page-bold">
        <span className="c2">Governing Law &amp; Jurisdiction.&nbsp;</span>
        <span>
          These Terms, and any Dispute or claim arising out of or in connection
          with it or its subject matter or formation (including non-contractual
          disputes or claims), shall be governed by, and construed in accordance
          with the laws of{" "}
        </span>
        <span>Estonia</span>
        <span>
          . You agree that in respect of any Dispute arising upon, over or in
          respect of any of the terms and conditions of these Terms, The Court
          of Arbitration of the Estonian Chamber of Commerce and Industry and
          the Estonian Court system shall have jurisdiction to try and
          adjudicate such Dispute.
        </span>
      </li>
    </ol>
    <ol>
      <li>
        <span className="c2">Contact Information</span>
      </li>
    </ol>
    <p>
      <span>
        Should you have any questions concerning these Terms and/or any issues
        or concerns about the WEBSITE, PLATFORM and/or Services you may contact
        Tradey at&nbsp;
      </span>
      <span>
        <a className="terms-page-bold" href="mailto:info@tradey.eu">
          info@tradey.eu
        </a>
      </span>
      <span>.</span>
    </p>
    <p className="c19">
      <span></span>
    </p>
  </div>
);

export default Terms;
