import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Grid,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Button,
  Typography,
  Divider,
  Link,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { UploadAvatar } from "wastexchange/components/UploadAvatar";
import FormField from "components/forms/FormField";
import Select from "components/forms/Select";
import TextField from "components/forms/TextField";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { languages } from "helpers/locale";
import { useNotifications } from "store/notifications";
import { useTranslation } from "react-i18next";
import {
  getReferredCompanies,
  unSubscribeAllSearches,
  unSubscribeSearch,
  useUserProfile,
} from "store/userprofile";
import { useCompanies } from "store/companies";
import { updateUserPassword, useAuth } from "helpers/auth";
import { UserProfileSchema } from "store/schemas/UserProfileSchema";
import { CompanyAccountSchema } from "store/schemas/CompanyAccountSchema";
import { CountrySelect } from "components/forms/CountrySelect";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import Geohash from "latlon-geohash";
import { PasswordChangeSchema } from "store/schemas/PasswordChangeSchema";

const defaultProfileValues = UserProfileSchema.cast();
const defaultCompanyValues = CompanyAccountSchema.cast();

export const ChangeProfileForm = ({ userProfile, company }) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { updateProfile } = useUserProfile();
  const { updateCompany } = useCompanies();
  const [owner, setOwner] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [referCompanies, setReferCompanies] = useState([]);
  const { t } = useTranslation();
  const { setSuccess, setError } = useNotifications();

  useEffect(() => {
    if (!owner && company) {
      setOwner(company.owner === userProfile.id);
    }
  }, [company, userProfile, owner]);

  const { control: cControl, handleSubmit: companyHandle } = useForm({
    defaultValues: { ...defaultCompanyValues, ...company },

    resolver: yupResolver(CompanyAccountSchema, {
      abortEarly: false,
    }),
  });

  const {
    control: pControl,
    handleSubmit: profileHandle,
    setValue,
    watch,
  } = useForm({
    defaultValues: { ...defaultProfileValues, ...userProfile },
    resolver: yupResolver(UserProfileSchema, {
      stripUnknown: true,
      abortEarly: false,
    }),
  });

  const {
    control: pwdControl,
    handleSubmit: pwdHandle,
    reset: pwdReset,
  } = useForm({
    resolver: yupResolver(PasswordChangeSchema, {
      stripUnknown: true,
      abortEarly: false,
    }),
  });

  useEffect(() => {
    (async () => {
      const companies = await getReferredCompanies(userProfile.referCode);
      setReferCompanies(companies);
    })();
  }, [userProfile.referCode]);

  if (!userProfile) {
    return null;
  }

  const handleImageChange = (value) => {
    setValue("pictures", { src: value });
  };

  const onProfileSubmit = async (data) => {
    setIsSubmitting(true);

    try {
      if (!user) {
        throw new Error("No user!");
      }
      await updateProfile(user.uid, data);
      setSuccess(t("Profile.SaveSuccess"));
    } catch (err) {
      console.error(err);
      setError("Profile.SaveError");
    } finally {
      setIsSubmitting(false);
    }
  };

  const onCompanySubmit = async (data) => {
    if (owner) {
      setIsSubmitting(true);

      try {
        if (!user) {
          throw new Error("No user!");
        }
        await updateCompany(company?.id, data);
        setSuccess(t("Profile.SaveSuccess"));
      } catch (err) {
        console.error(err);
        setError(t("Profile.SaveError"));
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const onPwdSubmit = async (data) => {
    setIsSubmitting(true);

    if (user) {
      const updateRes = await updateUserPassword(
        user,
        data.oldPassword,
        data.newPassword
      );

      if (updateRes) {
        setError(t("Password.ChangeError"));
      } else {
        setSuccess(t("Password.ChangeSuccess"));
      }
    } else {
      setError(t("Profile.SaveError"));
    }

    pwdReset({
      oldPassword: "",
      newPassword: "",
      newPasswordConfirm: "",
    });

    setIsSubmitting(false);
  };

  const removeSavedSearch = async ({
    wasteType,
    geoHash,
    location,
    wasteTypeId,
  }) => {
    const result = window.confirm(t("Common.ConfirmDeleteSearch"));
    if (result) {
      const newSavedSearches = userProfile.savedsearches.filter(
        (obj) =>
          JSON.stringify({
            wasteType: obj.wasteType,
            geoHash: obj.geoHash,
            wasteTypeId: obj.wasteTypeId,
          }) !==
          JSON.stringify({
            wasteType,
            geoHash,
            wasteTypeId,
          })
      );
      await updateProfile(user.uid, {
        ...userProfile,
        savedsearches: newSavedSearches,
      });
      await unSubscribeSearch(user.uid, {
        wasteType,
        location,
        wasteTypeId,
      });
    }
  };

  const removeAllSavedSearch = async () => {
    const result = window.confirm(t("Common.ConfirmSaveSearch"));
    if (result && userProfile.savedsearches.length) {
      await unSubscribeAllSearches(user.uid, userProfile.savedsearches);

      await updateProfile(user.uid, {
        ...userProfile,
        savedsearches: [],
      });
    }
  };

  const goToSearch = ({ geoHash, wasteTypeId, radius }) => {
    try {
      let baseUrl = "/offers?";

      if (wasteTypeId) {
        baseUrl += `t=${wasteTypeId}`;
      }

      if (geoHash) {
        const location = Geohash.decode(geoHash);
        baseUrl += `&l=${location.lat},${location.lon}&r=${radius}`;
      }

      navigate(baseUrl);
    } catch (err) {
      console.error(err);
    }
  };

  const pictures = watch("pictures");

  return (
    <>
      <IconButton
        aria-label="close"
        size="large"
        onClick={() => navigate("/offers")}
      >
        <CloseIcon />
      </IconButton>
      <Grid container spacing={3} paddingLeft="50px" paddingRight="50px">
        <Grid item xs={12}>
          <form
            autoComplete="off"
            noValidate
            onSubmit={profileHandle(onProfileSubmit)}
          >
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <Card>
                  <Box
                    sx={{
                      my: 10,
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <UploadAvatar
                      url={pictures?.src}
                      onChange={handleImageChange}
                    />
                  </Box>
                </Card>
              </Grid>
              <Grid item xs={12} md={8}>
                <Card>
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <FormField
                          name="fullName"
                          control={pControl}
                          label="Contact.Name"
                          Component={TextField}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormField
                          name="email"
                          control={pControl}
                          label="Contact.Email"
                          Component={TextField}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormField
                          name="phoneNumber"
                          control={pControl}
                          label="Contact.Phone"
                          Component={TextField}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormField
                          name="language"
                          control={pControl}
                          label="Contact.PreferredLanguage"
                          options={languages}
                          Component={Select}
                        />
                      </Grid>
                    </Grid>
                    <Box
                      sx={{
                        mt: 3,
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <LoadingButton
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                      >
                        Save Changes
                      </LoadingButton>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </form>
        </Grid>
        <Grid item xs={12}>
          <form autoComplete="off" noValidate onSubmit={pwdHandle(onPwdSubmit)}>
            <Card>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormField
                      name="oldPassword"
                      control={pwdControl}
                      label="Password.OldPassword"
                      Component={TextField}
                      type="password"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormField
                      name="newPassword"
                      control={pwdControl}
                      label="Password.NewPassword"
                      Component={TextField}
                      type="password"
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormField
                      name="newPasswordConfirm"
                      control={pwdControl}
                      label="Password.NewPasswordConfirm"
                      Component={TextField}
                      type="password"
                    />
                  </Grid>
                </Grid>
                <Box
                  sx={{
                    mt: 3,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                  >
                    Update Password
                  </LoadingButton>
                </Box>
              </CardContent>
            </Card>
          </form>
        </Grid>
        <Grid item xs={12}>
          <form
            autoComplete="off"
            noValidate
            onSubmit={companyHandle(onCompanySubmit)}
          >
            <Card>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <FormField
                      name="name"
                      control={cControl}
                      label="Company.Name"
                      Component={TextField}
                      disabled={!owner}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormField
                      name="registryCode"
                      control={cControl}
                      label="Company.RegistryCode"
                      Component={TextField}
                      disabled={!owner}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CountrySelect
                      name="countryCode"
                      control={cControl}
                      disabled={!owner}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormField
                      name="vatNumber"
                      control={cControl}
                      label="Company.VatNumber"
                      Component={TextField}
                      disabled={!owner}
                    />
                  </Grid>
                </Grid>
                {owner && (
                  <Box
                    sx={{
                      mt: 3,
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      loading={isSubmitting}
                    >
                      Update Company
                    </LoadingButton>
                  </Box>
                )}
              </CardContent>
            </Card>
          </form>
        </Grid>

        <Grid item xs={12}>
          <form
            autoComplete="off"
            noValidate
            onSubmit={companyHandle(onCompanySubmit)}
          >
            <Card>
              <CardContent>
                <Typography color="gray">
                  {t("Common.SavedSearches")}
                </Typography>
                <List dense={false}>
                  {userProfile.savedsearches?.map(
                    (
                      { wasteType, location, radius, wasteTypeId, geoHash },
                      i
                    ) => (
                      <div key={`savedsearch-${i}`}>
                        <ListItem>
                          <ListItemText
                            primary={
                              <Link
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  goToSearch({
                                    location,
                                    radius,
                                    wasteTypeId,
                                    geoHash,
                                  })
                                }
                              >
                                <Typography>
                                  <Box
                                    display="inline"
                                    fontWeight="fontWeightBold"
                                  >
                                    {`Type: `}
                                  </Box>
                                  {wasteType ? wasteType : "Anything"}
                                  <Box
                                    display="inline"
                                    fontWeight="fontWeightBold"
                                  >
                                    {`, Location: `}
                                  </Box>
                                  {location
                                    ? location.display_name
                                    : "Anywhere"}
                                  {location && (
                                    <>
                                      <Box
                                        display="inline"
                                        fontWeight="fontWeightBold"
                                      >
                                        {`, Within: `}
                                      </Box>
                                      {`${radius} km`}
                                    </>
                                  )}
                                </Typography>
                              </Link>
                            }
                          />
                          <ListItemSecondaryAction>
                            <IconButton edge="end" aria-label="delete">
                              <DeleteIcon
                                onClick={() =>
                                  removeSavedSearch({
                                    location,
                                    wasteType,
                                    radius,
                                    wasteTypeId,
                                    geoHash,
                                  })
                                }
                              />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                        {i !== userProfile.savedsearches.length - 1 && (
                          <Divider />
                        )}
                      </div>
                    )
                  )}
                </List>
                {userProfile.savedsearches?.length ? (
                  <Box
                    sx={{
                      mt: 3,
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => removeAllSavedSearch()}
                    >
                      {t("Common.RemoveAll")}
                    </Button>
                  </Box>
                ) : (
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Typography>{t("Common.NoSearches")}</Typography>
                  </Box>
                )}
              </CardContent>
            </Card>
          </form>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography color="gray" display="inline">
                {`${t("Common.ReferCode")}: ${userProfile?.referCode}`}
              </Typography>
              <Typography sx={{ mt: 1 }} color="gray">
                {t("Common.Refers")}:{" "}
                <Link
                  style={{
                    cursor: "pointer",
                  }}
                >
                  {window.location.hostname}?ref=
                  {userProfile?.referCode}
                </Link>
              </Typography>
              <Typography sx={{ mt: 1 }} color="gray">
                {t("Common.YourRefers")}:
              </Typography>
              <List dense={false}>
                {referCompanies.map(({ name, email, company }, i) => (
                  <ListItem key={`referComp-${i}`}>
                    <ListItemText
                      primary={`${name}, ${email}, ${company}`}
                    ></ListItemText>
                  </ListItem>
                ))}
              </List>
              {!referCompanies.length && (
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Typography>{t("Common.NoRefers")}</Typography>
                </Box>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
