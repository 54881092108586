import { produce, enableMapSet } from "immer";
import create from "zustand";
import { devtools } from "store/store.devtools";

enableMapSet();

const pipe =
  (...fns) =>
  (x) =>
    fns.reduce((v, f) => f(v), x);

export const log = (config) => (set, get, api) => {
  return config(
    (args) => {
      console.log("  applying", args);
      set(args);
      console.log("  new state", get());
    },
    get,
    api
  );
};

export const immer = (config) => (set, get, api) => {
  return config((args) => set(produce(args)), get, api);
};

export const createStore = pipe(immer, create);

export const createWithDevtools = (store, prefix) =>
  createStore(devtools(store, prefix));
