import { useRef, useState } from "react";
import { Box, MenuItem, ListItemIcon, ListItemText } from "@mui/material";
import { MIconButton } from "minimal/components/@material-extend";
import MenuPopover from "minimal/components/MenuPopover";
import { languages } from "helpers/locale";
import { useTranslation } from "react-i18next";
import LanguageIcon from "@mui/icons-material/Language";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { grey } from "@mui/material/colors";

export default function LanguagePopover({ linkSx = { color: grey[800] } }) {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { i18n } = useTranslation();

  const handleChange = (value) => {
    i18n.changeLanguage(value);
  };

  const currentLang =
    languages.find((l) => l.value === i18n.language) || languages[0];

  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={() => setOpen(true)}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && { bgcolor: "action.selected" }),
          ...linkSx,
        }}
      >
        <LanguageIcon />
      </MIconButton>

      <MenuPopover
        open={open}
        onClose={() => setOpen(false)}
        anchorEl={anchorRef.current}
      >
        <Box sx={{ py: 1 }}>
          {languages.map((option) => (
            <MenuItem
              key={option.value}
              selected={option.value === currentLang.value}
              onClick={() => {
                handleChange(option.value);
              }}
              sx={{ py: 1, px: 2.5 }}
            >
              <ListItemIcon>
                <ArrowForwardIosIcon />
              </ListItemIcon>
              <ListItemText primaryTypographyProps={{ variant: "body2" }}>
                {option.label}
              </ListItemText>
            </MenuItem>
          ))}
        </Box>
      </MenuPopover>
    </>
  );
}
