import * as Yup from "yup";

export const PasswordChangeSchema = Yup.object().shape({
    oldPassword: Yup.string()
        .required("Validation.RequiredField")
        .min(8, "Password is too short - should be 8 characters minimum.")
        .matches(
            /^(?=.*\d)(?=.*[a-z])(?=.*[a-zA-Z]).{8,}$/,
            "Password should contain at least one letter and one number"
        ),
    newPassword: Yup.string()
        .required("Validation.RequiredField")
        .min(8, "Password is too short - should be 8 characters minimum.")
        .matches(
            /^(?=.*\d)(?=.*[a-z])(?=.*[a-zA-Z]).{8,}$/,
            "Password should contain at least one letter and one number"
        ),
    newPasswordConfirm: Yup.string()
        .required("Validation.RequiredField")
        .test("passwords-match", "Passwords must match", function (value) {
            return this.parent.newPassword === value;
        }),
});
