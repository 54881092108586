export const saveToLocalStorage = (key, data) => {
    try {
        const stringified = JSON.stringify(data);
        localStorage.setItem(key, stringified);
    } catch (err) {
        console.error(err);
    }
};

export const readFromLocalStorage = (key) => {
    try {
        const data = localStorage.getItem(key);
        if (data) {
            return JSON.parse(data);
        }
    } catch (err) {
        console.error(err);
    }
    return null;
};

export const removeFromLocalStorage = (key) => {
    try {
        localStorage.removeItem(key);
        return true;
    } catch (err) {
        console.error(err);
    }
    return false;
};
