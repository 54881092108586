import { Box } from "@mui/material";
import { LandingNav } from "./components/LandingNav";
import { experimentalStyled as styled } from "@mui/material/styles";
import Page from "./components/Page";
import LandingContent from "./pages/landing/LandingContent";

const RootStyle = styled(Page)({
    height: "100%",
});

export default function Landing() {
    return (
        <Box sx={{ height: "100%" }}>
            <LandingNav />
            <Box sx={{ height: "100%" }}>
                <RootStyle title="Tradey" id="move_top">
                    <LandingContent />
                </RootStyle>
            </Box>
        </Box>
    );
}
