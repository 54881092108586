import { GridContainer, GridItem } from "../../components/layout/Helpers";

export const UploadedImages = ({ images }) => {
    return (
        <GridContainer spacing={2}>
            {images.map((url) => {
                return (
                    <GridItem key={url} sm={6} md={4} lg={3} xl={2}>
                        <img src={url} alt="" />
                    </GridItem>
                );
            })}
        </GridContainer>
    );
};
