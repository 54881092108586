import { paymentDeadlineUnits } from "helpers/options";
import { useTranslation } from "react-i18next";
import { GridContainer, GridItem } from "../layout/Helpers";
import FormField from "./FormField";
import TextField from "./TextField";

export const PaymentDeadlineInput = ({
    control,
    amountValue,
    context = "paymentDeadline",
    labelContext = "PaymentDeadline",
}) => {
    const { t } = useTranslation();
    const fieldContext = context ? `${context}.` : "";

    const units = paymentDeadlineUnits.map((opt) => ({
        ...opt,
        label: t(`WasteOfferBid.Deadline_${opt.value}`, {
            count: Number(amountValue),
        }),
    }));

    return (
        <GridContainer spacing={1}>
            <GridItem xs={6}>
                <FormField
                    name={`${fieldContext}amount`}
                    control={control}
                    label={`${labelContext}.Amount`}
                    Component={TextField}
                    isNumeric
                />
            </GridItem>
            <GridItem xs={6}>
                <FormField
                    name={`${fieldContext}unit`}
                    control={control}
                    label={`${labelContext}.Unit`}
                    options={units}
                    select
                    Component={TextField}
                />
            </GridItem>
        </GridContainer>
    );
};
