import { fetchData } from "helpers/requests";

const LOCATION_IQ_API_KEY = "pk.bdb9f94f41b3a1f8fb692fa4c5dd432b";
const LOCATION_IQ_ENDPOINT_URL = "https://eu1.locationiq.com/v1/search.php";
const LOCATION_IQ_REVERSE_ENDPOINT_URL =
    "https://eu1.locationiq.com/v1/reverse.php";

const defaultParams = {
    format: "json",
    addressdetails: 1,
    key: LOCATION_IQ_API_KEY,
    dedupe: 1,
    normalizecity: 1,
};

export const fetchLocation = async ({ lat, lon }) => {
    const response = await fetchData(LOCATION_IQ_REVERSE_ENDPOINT_URL, {
        ...defaultParams,
        lat,
        lon,
    });

    return response;
};

export const fetchAddress = async (request, callback, language) => {
    const response = await fetchData(LOCATION_IQ_ENDPOINT_URL, {
        ...defaultParams,
        ...request,
        "accept-language": language,
    });
    if (response.success && response.data) {
        callback(response.data);
    } else {
        console.error("error: ", response.message);
    }
};

export const getCallback =
    ({ active, value, setOptions }) =>
    (results) => {
        if (active) {
            // put results in a Map by display_name because API returns sometimes duplicate values
            // and that will make React complain about duplicate keys
            const newOptions = new Map();

            if (value) {
                newOptions.set(value.display_name, value);
            }

            if (results) {
                for (const result of results) {
                    if (!newOptions.has(result.display_name)) {
                        newOptions.set(result.display_name, result);
                    }
                }
            }

            setOptions(Array.from(newOptions.values()));
        }
    };
