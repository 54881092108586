import { Box } from "@mui/material";
import { getNormalAddress, getShortAddress } from "helpers/addresses";
import { Quantity } from "./Quantity";

export const Locations = ({ wasteOffer, normal = false }) => {
    const getAddressFn = !normal ? getShortAddress : getNormalAddress;
    const locations = wasteOffer.locations || [
        { location: wasteOffer.location, quantity: wasteOffer.quantity },
    ];
    return locations.map(({ location, quantity }, i) => {
        return (
            <Box
                key={`location.${i}`}
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <Box>{getAddressFn(location).label}</Box>
                <Quantity {...quantity} />
            </Box>
        );
    });
};
