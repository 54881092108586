import * as Yup from "yup";

export const AddressSchema = Yup.object({
    display_name: Yup.string().required("Validation.RequiredField").default(""),
    lat: Yup.string().required().default("0.0"),
    lon: Yup.string().required().default("0.0"),
    address: Yup.mixed(),
    class: Yup.string().default(""),
    type: Yup.string().default(""),
}).required();
