import FormField from "components/forms/FormField";
import { GridItem } from "components/layout/Helpers";
import { INPUT_COMPONENT_MAP } from "constants/inputMap";
import React, { useCallback, useEffect } from "react";

const ConditionalField = (props) => {
  const { parentFieldName, options, control, watch, setValue } = props;

  const parentFieldValue = watch(parentFieldName);
  const field = options.find((o) => o.optionId === parentFieldValue);

  const setFieldValue = useCallback(
    (field) => {
      if (field) {
        setValue(field.name, field.defaultValue);
      }
    },
    [setValue]
  );

  useEffect(() => {
    setFieldValue(field);
  }, [field, parentFieldValue, setFieldValue]);

  if (parentFieldValue !== options[0])
    if (!field) {
      return null;
    }

  const { optionId, conditionalOptions, ...fieldProps } = field;

  return (
    <>
      <GridItem>
        <FormField
          {...{
            ...fieldProps,
            name: field.name,
            control,
            Component: INPUT_COMPONENT_MAP[field.type],
          }}
        />
      </GridItem>
      {conditionalOptions && (
        <ConditionalField
          {...{
            parentFieldName: field.name,
            options: field.conditionalOptions,
            control,
            watch,
            setValue,
          }}
        />
      )}
    </>
  );
};

export default ConditionalField;
