import { Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { BidComment } from "./BidComment";

export const BidCommentSection = ({ comments }) => {
    const { t } = useTranslation();
    return (
        <Paper style={{ margin: "15px", padding: "5px" }}>
            <Typography>{t("Offer.Replies")}</Typography>
            {comments.map((comment, i) => (
                <BidComment
                    sender={comment.sender}
                    comment={comment.message}
                    time={comment.time}
                    key={`comment-${i}-${new Date(comment.time)}`}
                />
            ))}
        </Paper>
    );
};
