import {
  Box,
  Button,
  Card,
  CardActions,
  Container,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { WasteOfferBids } from "wastexchange/components/WasteOfferBids";
import { WasteOfferBidDialog } from "wastexchange/components/wasteofferbids/WasteOfferBidDialog";
import { WasteOfferCarousel } from "wastexchange/components/WasteOfferCarousel";
import { WasteOfferMainContents } from "wastexchange/components/WasteOfferMainContents";
import { WasteOfferTitle } from "wastexchange/components/WasteOfferTitle";
import { OfferStatuses } from "wastexchange/store/schemas/WasteOfferSchema";
import { isDateABeforeDateB } from "helpers/isDateABeforeDateB";
import {
  deleteOffer,
  useBidsListener,
  useWasteOffers,
  useWasteOfferListener,
} from "wastexchange/store/wasteoffers";
import { GridContainer, GridItem } from "components/layout/Helpers";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNotifications } from "store/notifications";
import Page404 from "../404";
import { OfferArchiveForm } from "wastexchange/components/OfferArchiveForm";
import WasteOfferAdditionalContents from "wastexchange/components/WasteOfferAdditionalContents";
import { startCaseCapitalLetter } from "helpers/startCaseCapitalLetter";

const WasteOfferDetails = ({ userId }) => {
  const offerId = useParams().offerId;
  const bidId = useParams().bidId;
  useWasteOfferListener(offerId);
  const { wasteOffer, highestBid, offerLoading } = useWasteOffers();
  useBidsListener(wasteOffer);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setInfo, setError } = useNotifications();
  const { pathname } = useLocation();

  const wasteOfferQuantity = {
    quantity:
      (wasteOffer?.locations || []).reduce(
        (acc, l) => acc + l.quantity.amount,
        0
      ) || 0,
    unit: (wasteOffer?.locations || [])[0]?.quantity.unit || "",
  };
  const isOfferExpired = useMemo(() => {
    if (wasteOffer) {
      return !isDateABeforeDateB(new Date(), wasteOffer.expiresAt);
    }
    return true;
  }, [wasteOffer]);

  const showEditDeleteControls =
    wasteOffer?.createdBy && userId === wasteOffer.createdBy;

  const showCreateBidControls =
    wasteOffer?.createdBy && userId !== wasteOffer.createdBy && !isOfferExpired;

  const handleEdit = () => {
    navigate(`/offers/${wasteOffer?.id}/edit`);
  };

  const handleDelete = async () => {
    const result = window.confirm(t("ConfirmDelete"));
    if (result) {
      try {
        if (!userId || !wasteOffer?.id) {
          throw new Error("Required parameters missing!");
        }
        deleteOffer(userId, wasteOffer.id).then(() => {
          setInfo(t("DeleteSuccess"));
          if (pathname.includes("/offers/my")) {
            document.location.href = pathname;
          } else {
            navigate("/offers/my");
          }
        });
      } catch (err) {
        setError(t("DeleteError"));
        console.error(err);
      }
    }
  };

  if (!wasteOffer && offerLoading) {
    return null;
  } else if (!wasteOffer && !offerLoading) {
    return <Page404 />;
  }
  const actions = (
    <CardActions>
      <Box
        p={2}
        sx={{
          display: "flex",
          height: 70,
          flex: 1,
          justifyContent: showCreateBidControls ? "flex-start" : "center",
        }}
      >
        {!highestBid && showEditDeleteControls ? (
          <Stack direction="row" spacing={2}>
            <Button onClick={handleEdit} variant="contained">
              {t("Common.Edit")}
            </Button>
            <Button onClick={handleDelete} variant="text" color="error">
              {t("Common.Delete")}
            </Button>
          </Stack>
        ) : (
          showEditDeleteControls &&
          !wasteOffer?.isArchived &&
          wasteOffer.status === OfferStatuses.OPEN && (
            <OfferArchiveForm
              offerId={offerId}
              userId={userId}
              offer={wasteOffer}
              bid={highestBid}
            />
          )
        )}
        {showCreateBidControls &&
        wasteOffer.status === OfferStatuses.OPEN &&
        wasteOfferQuantity.quantity > 0 ? (
          <WasteOfferBidDialog
            wasteOfferId={wasteOffer.id}
            wasteOfferQuantity={wasteOfferQuantity}
            wasteOfferSharedOffer={wasteOffer.sharedOffer}
            expiresAt={wasteOffer?.expiresAt}
            usePercentage={wasteOffer.startingPrice.usePercentage}
            startingPrice={wasteOffer.startingPrice}
          />
        ) : null}
      </Box>
    </CardActions>
  );

  return (
    <Container maxWidth="lg">
      <Card sx={{ marginTop: "2em" }}>
        <Box p={2}>
          <GridContainer
            spacing={4}
            direction={{ xs: "column-reverse", sm: "row" }}
          >
            <GridItem sm={6}>
              <Box>
                <WasteOfferTitle wasteOffer={wasteOffer} variant="h4" />
                <GridItem>
                  <Typography>
                    {`${t(
                      `OfferTypes.${startCaseCapitalLetter(
                        wasteOffer.offerType || "sell"
                      )}`
                    )}, ${t(
                      `WasteCategories.${startCaseCapitalLetter(
                        wasteOffer.wasteCategory
                      )}`
                    )}`}
                  </Typography>
                </GridItem>
                {wasteOffer.code ? (
                  <GridItem>
                    <Typography>
                      {t("Code")}: <strong>{wasteOffer.code}</strong>
                    </Typography>
                  </GridItem>
                ) : null}

                <Box py={2}>
                  <Divider />
                </Box>
                <WasteOfferMainContents wasteOffer={wasteOffer} />
              </Box>
            </GridItem>
            <GridItem sm={6}>
              <WasteOfferCarousel
                images={wasteOffer.images}
                wasteOffer={wasteOffer}
                sx={{
                  height: [200, 500],
                  borderRadius: 2,
                }}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem my={2}>
              <WasteOfferAdditionalContents wasteOffer={wasteOffer} />
            </GridItem>
            <GridItem>
              <Box my={2}>
                <Divider />
              </Box>
              {actions}
            </GridItem>
          </GridContainer>
        </Box>
      </Card>
      <WasteOfferBids wasteOffer={wasteOffer} userId={userId} bidId={bidId} />
    </Container>
  );
};

export default WasteOfferDetails;
