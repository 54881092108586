import OffersTable from "components/OffersTable/OffersTable";
import { GridContainer, GridItem } from "../../components/layout/Helpers";
import { useWasteOffers } from "../store/wasteoffers";
import { useWasteViews, VIEW_TYPES } from "../store/wasteviews";
import { OfferOverview } from "./WasteOfferOverview";

export const WasteOffersLoader = ({ dataReader }) => {
  const { offersList: items } = useWasteOffers();
  const { view } = useWasteViews();

  dataReader();

  if (!items) {
    return null;
  }

  return view === VIEW_TYPES.CARDS ? (
    <GridContainer spacing={2}>
      {items.map((item) => {
        return (
          <GridItem key={item.id} sm={6} lg={4} lgb={3} xl={2}>
            <OfferOverview wasteOffer={item} />
          </GridItem>
        );
      })}
    </GridContainer>
  ) : (
    <OffersTable offers={items} />
  );
};
