import { forwardRef } from "react";
import MTextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import get from "lodash/get";

function TextField(
  {
    name,
    label,
    translatedLabel,
    placeholder,
    translatedPlaceholder,
    helperText,
    errors = {},
    variant = "outlined",
    fullWidth = true,
    isNumeric = false,
    inputPropsAdditional = {},
    ...rest
  },
  ref
) {
  const { t } = useTranslation();
  const tLabel = translatedLabel ? translatedLabel : label ? t(label) : null;
  const tPlaceholder = translatedPlaceholder
    ? translatedPlaceholder
    : placeholder
    ? t(placeholder)
    : null;
  const contextError = get(errors, name);

  if (isNumeric) {
    return (
      <MTextField
        name={name}
        inputRef={ref}
        label={tLabel}
        placeholder={tPlaceholder}
        error={!!contextError}
        helperText={contextError ? t(contextError.message) : helperText}
        variant={variant}
        fullWidth={fullWidth}
        {...rest}
        type={"number"}
        inputProps={{ inputMode: "numeric", pattern: "[0-9]*", ...inputPropsAdditional }}
      />
    );
  } else {
    return (
      <MTextField
        name={name}
        inputRef={ref}
        label={tLabel}
        placeholder={tPlaceholder}
        error={!!contextError}
        helperText={contextError ? t(contextError.message) : helperText}
        variant={variant}
        fullWidth={fullWidth}
        {...rest}
      />
    );
  }
}

export default forwardRef(TextField);
