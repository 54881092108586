import { LinearProgress } from "@mui/material";
import { WasteOfferSchema } from "wastexchange/store/schemas/WasteOfferSchema";
import { useWasteOffers } from "wastexchange/store/wasteoffers";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import WasteOfferForm from "./WasteOfferForm";
import cloneDeep from "lodash/cloneDeep";

const defaultValues = WasteOfferSchema.cast();

const WasteOfferEdit = () => {
  const offerId = useParams().offerId;
  const { wasteOffer, fetchById } = useWasteOffers();

  useEffect(() => {
    if (offerId) {
      fetchById(offerId);
    }
  }, [fetchById, offerId]);

  if (!wasteOffer) {
    return <LinearProgress />;
  }

  const formDataValues = cloneDeep({
    ...defaultValues,
    ...wasteOffer,
    locations: wasteOffer.locations || [
      { location: wasteOffer.location, quantity: wasteOffer.quantity },
    ],
  });

  return (
    <WasteOfferForm isEdit offerId={offerId} formDataValues={formDataValues} />
  );
};

export default WasteOfferEdit;
