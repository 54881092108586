// @ts-check

import { collection, addDoc, setDoc, getDoc, doc } from "firebase/firestore";
import { db } from "helpers/firebase";
import { createWithDevtools } from "./store.helpers";

const companiesCollection = collection(db, "companies");

export function getCompany(companyId) {
    return getDoc(doc(companiesCollection, companyId));
}

export async function createCompany(userId, data) {
    if (!userId || !data) {
        throw new Error("Cannot create company: invalid params.");
    }
    const result = addDoc(companiesCollection, {
        ...data,
        createdBy: userId,
        owner: userId,
        createdAt: new Date(),
    });
    return result;
}

const updateCompanyData = (companyId, data) => {
    const companyRef = doc(db, "companies", companyId);
    return setDoc(
        companyRef,
        { ...data, updatedAt: new Date() },
        { merge: true }
    );
};

const companies = (set, get) => {
    return {
        companies: new Map(),
        fetchCompany: async (companyId) => {
            const companies = get().companies;
            if (!companies.has(companyId)) {
                const result = await getCompany(companyId);
                const data = result.data();
                set(function addCompany(draft) {
                    draft.companies.set(companyId, {
                        id: result.id,
                        value: result.id,
                        label: data.name,
                        ...data,
                    });
                });
            }
        },
        updateCompany: async (companyId, data) => {
            await updateCompanyData(companyId, data);
            set(function setCompany(draft) {
                draft.companies.set(companyId, data);
            });
        },
    };
};

export const useCompanies = createWithDevtools(companies);
