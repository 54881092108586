import { FIELD_TYPE, FORM_FIELD_NAME } from "constants/enums";
import { OFFER_FIELD_MAP } from "constants/map";

const getConditionalField = (
  additionalFieldsTemplate,
  offerField,
  offerFieldValue
) => {
  for (const parentField of additionalFieldsTemplate) {
    if (parentField.conditionalOptions.length === 0) {
      continue;
    }

    const childField = parentField.conditionalOptions.find(
      (f) => f.name === offerField
    );

    if (!childField) {
      for (const conditionalOptions of parentField.conditionalOptions) {
        const field = conditionalOptions.conditionalOptions?.find((f) =>
          f.options.find((o) => o.id === offerFieldValue)
        );
        if (field) {
          return field;
        }
      }
    }

    return childField;
  }
};

const getField = (additionalFieldsTemplate, offerField, offerFieldValue) => {
  const parentField = additionalFieldsTemplate.find(
    (field) => field.name === offerField
  );

  if (parentField) {
    return parentField;
  }

  const conditionalField = getConditionalField(
    additionalFieldsTemplate,
    offerField,
    offerFieldValue
  );

  return conditionalField;
};

export const getMappedAdditionalFields = (offer, wasteType) => {
  const additionalFields = [];
  const additionalFieldsTemplate = OFFER_FIELD_MAP[wasteType.wasteTypeForm];

  if (!additionalFieldsTemplate) {
    return additionalFields;
  }

  for (const offerField in offer) {
    if (!Object.values(FORM_FIELD_NAME).includes(offerField)) {
      continue;
    }

    const offerFieldValue = offer[offerField];
    const field = getField(
      additionalFieldsTemplate,
      offerField,
      offerFieldValue
    );

    if (!field) {
      continue;
    }

    let fieldValue =
      field.type === FIELD_TYPE.SELECT
        ? field.options.find((o) => o.id === offerFieldValue).label
        : offerFieldValue;

    if (field.type === FIELD_TYPE.RANGE) {
      fieldValue += field.limit.postFix;
    }

    additionalFields.push({
      type: field.type,
      name: field.label,
      value: fieldValue,
    });
  }

  return additionalFields;
};
