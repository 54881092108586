import { WASTE_TYPE_FORMS } from "./enums";
import { getForestChipSawDustFields } from "./offerForm/forestChipSawDust";
import { getForestMaterialFields } from "./offerForm/forestMaterial";
import { getWasteWoodFields } from "./offerForm/wasteWood";
import { getWasteWoodChipSawDust } from "./offerForm/wasteWoodChipSawDust";

/**
 * @typedef SelectOption
 * @property {String} id
 * @property {String} label
 */

/**
 * @typedef ConditionalSelect
 * @property {String} optionId
 * @property {String} name
 * @property {String} label
 * @property {String} type
 * @property {String} defaultValue
 * @property {SelectOption[]} options
 * @property {ConditionalSelect[]} [conditionalOptions]
 */

/**
 * @typedef Limit
 * @property {Number} min
 * @property {Number} max
 * @property {Number} step
 * @property {String} [postFix]
 */
export default class AdditionalInfoField {
  /**
   *
   * @param {Object} field
   * @param {String} field.id
   * @param {String} field.name
   * @param {String} field.type
   * @param {String} field.label
   * @param {SelectOption[]} [field.options]
   * @param {ConditionalSelect[]} [field.conditionalOptions]
   * @param {String} [field.acceptFiles]
   * @param {Limit} [field.limit]
   * @param {Boolean} [field.required]
   * @param {String} [field.requiredFor]
   * @param {any} [field.defaultValue]
   */
  constructor(field = {}) {
    this.id = field.id;
    this.name = field.name;
    this.type = field.type;
    this.label = field.label;
    this.options = field.options || [];
    this.conditionalOptions = field.conditionalOptions || [];
    this.limit = field.limit || null;
    this.required = field.required || false;
    this.requiredFor = field.requiredFor || "";
    this.defaultValue = field.defaultValue;
    this.acceptFiles = field.acceptFiles || "";
  }
}

export const OFFER_FIELD_MAP = {
  [WASTE_TYPE_FORMS.FOREST_CHIP_SAWDUST]: getForestChipSawDustFields(),
  [WASTE_TYPE_FORMS.WASTE_WOOD_CHIP_SAWDUST]: getWasteWoodChipSawDust(),
  [WASTE_TYPE_FORMS.FOREST_MATERIAL]: getForestMaterialFields(),
  [WASTE_TYPE_FORMS.WASTE_WOOD]: getWasteWoodFields(),
};
