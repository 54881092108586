import { InputLabel } from "@mui/material";
import { FormControl, MenuItem, Select as MSelect } from "@mui/material";
import { forwardRef } from "react";
import { useTranslation } from "react-i18next";

function Select(
  { label = null, options, variant = "outlined", fullWidth = true, ...rest },
  ref
) {
  const { t } = useTranslation();
  const { name } = rest;
  const labelId = `${name}_label`;
  const tLabel = label ? t(label) : null;
  return (
    <FormControl variant={variant} fullWidth={fullWidth}>
      {tLabel ? (
        <>
          <InputLabel id={labelId} sx={{ pt: 1 }}>
            {tLabel}
          </InputLabel>
          <MSelect
            labelId={labelId}
            inputRef={ref}
            label={tLabel}
            displayEmpty
            defaultValue=""
            {...rest}
          >
            {options.map((option) => (
              <MenuItem
                key={option.id || option.value}
                value={option.id || option.value}
              >
                {t(option.label)}
              </MenuItem>
            ))}
          </MSelect>
        </>
      ) : (
        <MSelect inputRef={ref} displayEmpty {...rest} defaultValue="">
          {options.map((option) => (
            <MenuItem
              key={option.id || option.value}
              value={option.id || option.value}
            >
              {option.label}
            </MenuItem>
          ))}
        </MSelect>
      )}
    </FormControl>
  );
}

export default forwardRef(Select);
