import { initializeApp } from "firebase/app";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getStorage, ref, connectStorageEmulator } from "firebase/storage";
import { getConfig, isLocal } from "./firebase.config";

export const config = getConfig();

export const app = initializeApp(config);

export const auth = getAuth(app);

export const db = getFirestore(app);

const storage = getStorage();

export const storageRoot = ref(storage);

export const functionsInstance = getFunctions(app, "europe-west1");

// connect emulators if running locally
if (isLocal) {
    connectAuthEmulator(auth, "http://localhost:9099");
    connectFunctionsEmulator(functionsInstance, "localhost", 5001);
    connectFirestoreEmulator(db, "localhost", 8080);
    connectStorageEmulator(storage, "localhost", 9199);
}
