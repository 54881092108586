import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { createBid } from "wastexchange/store/wasteoffers";
import FormField from "components/forms/FormField";
import { PaymentDeadlineInput } from "components/forms/PaymentDeadlineInput";
import { PriceInputField } from "components/forms/PriceInputField";
import SwitchWithLabel from "components/forms/SwitchWithLabel";
import TextField from "components/forms/TextField";
import { GridContainer, GridItem } from "components/layout/Helpers";
import { Messages } from "components/messages/Messages";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNotifications } from "store/notifications";
import { WasteOfferBidSchema } from "store/schemas/WasteOfferBidSchema";

const messagesContext = "wasteOfferBid";

export const WasteOfferBidForm = (props) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    formValues,
    userId,
    wasteOfferId,
    handleClose,
    expiresAt,
    usePercentage,
    startingPrice,
  } = props;
  const { control, handleSubmit, watch, setValue } = useForm({
    defaultValues: formValues,
    resolver: yupResolver(WasteOfferBidSchema(startingPrice?.currencyCode), {
      stripUnknown: true,
      abortEarly: false,
    }),
  });
  useEffect(() => {
    if (formValues.quantity.quantity) {
      setValue("quantity", formValues.quantity.quantity);
    }
  }, [formValues, setValue]);

  useEffect(() => {
    setValue("currencyCode", "");
    if (usePercentage) {
      setValue("currencyValue", startingPrice.currencyValue);
    }
  }, [usePercentage, setValue, startingPrice]);

  const { t } = useTranslation();
  const { setSuccess, setError } = useNotifications();
  const handleError = (message) => {
    setError(t("Offer.Error", { message }), {
      context: messagesContext,
    });
  };

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    try {
      if (!userId) {
        setIsSubmitting(false);
        throw new Error("User id missing!");
      }
      if (!wasteOfferId) {
        setIsSubmitting(false);
        throw new Error("Offer id missing!");
      }
      if (data.prePayment === false && !data.paymentDeadline.amount) {
        setIsSubmitting(false);
        throw new Error(t("WasteOfferBid.PaymentAmountMissing"));
      }
      await createBid(userId, wasteOfferId, data, expiresAt);
      setSuccess(t("WasteOfferBid.PostSuccess"));
      handleClose();
    } catch (err) {
      console.error(err);
      handleError(err.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const prePayment = watch("prePayment");
  const paymentDeadlineAmount = watch("paymentDeadline.amount");

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Messages context={messagesContext} />
      <GridContainer spacing={1}>
        <GridItem sm={6}>
          <PriceInputField
            control={control}
            noDelivery
            sharedOffer={formValues.sharedOffer}
            quantity={formValues.quantity}
            usePercentage={usePercentage}
            startingPrice={startingPrice}
          />
        </GridItem>
        <GridItem sm={6}>
          <Stack
            direction="row"
            display="flex"
            flex={1}
            alignItems="center"
            sx={{ marginLeft: 1 }}
          >
            <Box sx={{ marginTop: 2 }}>
              <FormField
                name="prePayment"
                control={control}
                label="WasteOfferBid.PrePayment"
                Component={SwitchWithLabel}
              />
            </Box>
            {!prePayment ? (
              <PaymentDeadlineInput
                control={control}
                amountValue={paymentDeadlineAmount}
              />
            ) : null}
          </Stack>
        </GridItem>
        <GridItem marginTop={0}>
          <FormField
            name="comment"
            control={control}
            label="Offer.Comment"
            multiline
            minRows={3}
            maxRows={3}
            Component={TextField}
          />
        </GridItem>
      </GridContainer>
      <GridContainer
        spacing={1}
        style={{
          display: "felx",
          justifyContent: "flex-end",
          marginRight: "5px",
          marginTop: "5px",
        }}
      >
        <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
          {t("Common.Submit")}
        </LoadingButton>
      </GridContainer>
    </form>
  );
};
