import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import SignInDialog from "components/signin/SignInDialog";
import useLogout from "helpers/useLogout";
import { useAuth } from "helpers/auth";

export const LoginLink = ({ linkSx = { color: "common.white" } }) => {
  const { user } = useAuth();
  const { handleLogout } = useLogout();
  const { t } = useTranslation();

  const theme = useTheme();

  if (!user) {
    return <SignInDialog linkSx={linkSx} />;
  }

  return (
    <>
      <Box display="flex" alignItems="center">
        <Typography style={{ color: theme.palette.primary.main }}>
          {user.displayName || user.email}
        </Typography>
        <Button variant="text" onClick={handleLogout} sx={linkSx}>
          {t("SignIn.Logout")}
        </Button>
      </Box>
    </>
  );
};
