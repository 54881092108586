module.exports = {
  OFFER_TYPE: {
    BUY: "buy",
    SELL: "sell",
  },

  WASTE_CATEGORY: {
    WOOD: "wood",
    PAPER_CARDBOARD: "paper/cardboard",
    PLASTIC: "plastic",
    METAL: "metal",
  },

  WASTE_TYPE_FORMS: {
    FOREST_CHIP_SAWDUST: "Forest chip/sawdust",
    WASTE_WOOD_CHIP_SAWDUST: "Waste wood chip/sawdust",
    FOREST_MATERIAL: "Forest material",
    WASTE_WOOD: "Waste wood",
  },

  FORM_FIELD_NAME: {
    OFFER_TYPE: "offerType",
    WASTE_CATEGORY: "wasteCategory",
    WASTE_TYPE: "wasteType",
    LOCATIONS: "locations",
    DEGREE_OF_CRUSHING: "degreeOfCrushing",
    QUALITY_STANDARD: "qualityStandard",
    HUMIDITY: "humidity",
    IMPURITY_FREE: "impurityFree",
    WIHTOUT_LEAVES_THORNS: "withoutLeavesThorns",
    FELLING_PERMIT: "fellingPermit",
    WASTE_PERMIT: "wastePermit",
    LABORATORY_TEST: "laboratoryTest",
    WASTE_WOOD_CLASS: "wasteWoodClass",
    WASTE_WOOD_CODE: "wasteWoodCode",
    FINISHED_WITH_MAGNETS: "finishedWithMagnets",
    FINISED_WITH_NONFERROUS_METAL_SEPARATOR:
      "finishedWithNonFerrousMetalSeparator",
    PLASTIC_FREE: "plasticFree",
    FOREST_MATERIAL: "forestMaterial",
    WASTE_WOOD_SORTING_TYPE: "wasteWoodSortingType",
    CADASTRE: "cadastre",
    FSC_PFC_CERTIFICATE: "fscOrPfcCertificate",
  },

  FIELD_TYPE: {
    TEXT: "text",
    SELECT: "select",
    NUMBER: "number",
    RANGE: "range",
    FILE: "file",
    CHECKBOX: "checkbox",
  },
};
