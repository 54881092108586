import { motion } from "framer-motion";
// material
import { experimentalStyled as styled } from "@mui/material/styles";
import { Box, Container, Typography } from "@mui/material";
import {
  varFadeIn,
  varFadeInRight,
  varWrapEnter,
} from "../../../minimal/components/animate";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";
import { useAuth, verify } from "helpers/auth";
// routes
//

// ----------------------------------------------------------------------

const RootStyle = styled(motion.div)(({ theme }) => ({
  position: "relative",
  backgroundColor: "#F2F3F5",
  [theme.breakpoints.up("md")]: {
    top: 0,
    left: 0,
    width: "100%",
    height: "100vh",
    display: "flex",
    position: "fixed",
    alignItems: "center",
  },
}));

const ContentStyle = styled("div")(({ theme }) => ({
  zIndex: 10,
  maxWidth: 520,
  margin: "auto",
  textAlign: "center",
  position: "relative",
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(15),
  [theme.breakpoints.up("md")]: {
    margin: "unset",
    textAlign: "left",
  },
}));

const HeroOverlayStyle = styled(motion.img)({
  zIndex: 9,
  width: "100%",
  height: "100%",
  objectFit: "cover",
  position: "absolute",
});

// ----------------------------------------------------------------------

export default function LandingVerify() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { user } = useAuth();

  const resendEmail = async () => {
    setIsSubmitting(true);
    try {
      if (!user) {
        throw new Error("User not present!");
      }
      await verify({ id: user.uid, email: user.email });
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <RootStyle initial="initial" animate="animate" variants={varWrapEnter}>
        <HeroOverlayStyle
          alt="overlay"
          src="/static/home/overlay.svg"
          variants={varFadeIn}
        />

        <Container maxWidth="lg">
          <ContentStyle>
            <motion.div variants={varFadeInRight}>
              <Typography variant="h3" sx={{ color: "common.white" }}>
                Please verify your email address!
              </Typography>
              <LoadingButton
                onClick={() => resendEmail()}
                loading={isSubmitting}
                variant="contained"
              >
                Resend link
              </LoadingButton>
            </motion.div>
          </ContentStyle>
        </Container>
      </RootStyle>
      <Box sx={{ height: { md: "100vh" } }} />
    </>
  );
}
