import countryCodesList from "country-codes-list";

export const getTranslatedOptions = (options, t) =>
    options.map((p) => ({
        ...p,
        label: t(p.label),
    }));

export const getTranslatedOptionsMap = (options, t) => {
    const results = new Map();
    options.forEach((option) => {
        results.set(option.value, { ...option, label: t(option.label) });
    });
    return results;
};

export const getOptionsMap = (options) => {
    const results = new Map();
    options.forEach((option) => {
        results.set(option.value, option);
    });
    return results;
};

export const travelPurposes = [
    {
        value: "business",
        label: "QuoteForm.Business",
    },
    {
        value: "leisure",
        label: "QuoteForm.Leisure",
    },
];

export const ArchiveReasons = [
    {
        value: "Incorrect data by mistake",
        label: "Incorrect data by mistake",
    },
    {
        value: "Sold elseWhere",
        label: "Sold elseWhere",
    },
];
export const contactTypeOptions = [
    {
        value: "address",
        label: "Address",
        options: { multiline: true },
    },
    {
        value: "phone",
        label: "Phone",
    },
    {
        value: "email",
        label: "Email",
    },
    {
        value: "url",
        label: "Url",
    },
    {
        value: "hours",
        label: "Hours",
    },
];

// keep it in sync with /functions/constants:MATERIAL_UNITS
export const quantityUnits = [
    {
        value: "metricton",
        label: "Quantity.MetricTon",
        labelShort: "t",
    },
    {
        value: "cubicmeter",
        label: "Quantity.CubicMeter",
        labelShort: "m3",
    },
    {
        value: "energyamount",
        label: "Quantity.EnergyAmount",
        labelShort: "MWh",
    },
];

export const quantityUnitsShort = quantityUnits.map((u) => ({
    value: u.value,
    label: u.labelShort,
}));

export const locationFilterRadiuses = [
    {
        value: 50,
        label: "Radiuses.50km",
    },
    {
        value: 100,
        label: "Radiuses.100km",
    },
    {
        value: 500,
        label: "Radiuses.500km",
    },
    {
        value: 1000,
        label: "Radiuses.1000km",
    },
    {
        value: 2000,
        label: "Radiuses.2000km",
    },
];

export const countries = countryCodesList.customArray({
    value: "{countryCode}",
    label: "{countryNameLocal} ({countryCode})",
});

export const paymentDeadlineUnits = [
    { value: "days", label: "Days" },
    { value: "weeks", label: "Weeks" },
];

// Notification template name ('name' property of 'notificationtemplate' document)
// is a unique combination of template prefix and locale, for example 'wastexchange_footer_en'

// NB! When you add a prefix or locale here, update also /functions/helpers/constants accordingly
// 'fields' are the keys of data, that template expects
export const templatePrefixes = {
    wastexchange_footer: {
        partial: true,
        fields: ["host"],
    },
    onCreateWasteOffer: {
        fields: ["host", "url", "code", "name"],
    },
    onCreateWasteOfferBid: {
        fields: [
            "host",
            "url",
            "code",
            "offerQuantity",
            "offerPrice",
            "materialType",
            "location",
            "bidAmount",
            "name",
        ],
    },
    onOverBid: {
        fields: [
            "host",
            "url",
            "code",
            "offerQuantity",
            "yourPrice",
            "materialType",
            "location",
            "bidAmount",
            "name",
        ],
    },
    onAcceptBid: {
        fields: [
            "host",
            "url",
            "code",
            "offerQuantity",
            "materialType",
            "location",
            "bidAmount",
            "offerCode",
            "name",
        ],
    },
    onHighestBidNotAccepted: {
        fields: [
            "host",
            "url",
            "offerQuantity",
            "materialType",
            "location",
            "bidAmount",
            "offerCode",
            "name",
        ],
    },
    onFoundInterest: {
        fields: [
            "host",
            "url",
            "offerQuantity",
            "materialType",
            "location",
            "offerPrice",
            "offerCode",
            "name",
            "unSubLink",
        ],
    },
    onSignUpVerification: {
        fields: ["url"],
    },
    onBidComment: {
        fields: ["url", "host", "from", "comment", "name"],
    },
    onUploadPDF: {
        fields: ["offerCode"],
    },
    onArchiveBiderEmail: {
        fields: ["url", "reason", "host", "name"],
    },
    OfferExpirationReminder: {
        fields: [
            "host",
            "url",
            "offerQuantity",
            "materialType",
            "location",
            "offerPrice",
            "offerCode",
            "name",
        ],
    },
};

export const templateLocales = ["en", "et", "ru", "fi"];

export const templates = Object.entries(templatePrefixes).reduce(
    (acc, [prefix, data]) => {
        for (const locale of templateLocales) {
            acc.push({
                value: `${prefix}_${locale}`,
                label: `${prefix}_${locale}${data.partial ? " (partial)" : ""}`,
            });
        }
        return acc;
    },
    []
);
