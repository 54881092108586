import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useAuth } from "helpers/auth";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { WasteOfferBidSchema } from "store/schemas/WasteOfferBidSchema";
import { useUserProfile } from "store/userprofile";
import { WasteOfferBidForm } from "./WasteOfferBidForm";

export const WasteOfferBidDialog = ({
  wasteOfferId,
  wasteOfferBidId,
  wasteOfferQuantity,
  wasteOfferSharedOffer,
  expiresAt,
  usePercentage,
  startingPrice,
}) => {
  const { t } = useTranslation();
  const [dialogOpen, setDialogOpen] = useState(false);
  const { user } = useAuth();
  const { userProfile } = useUserProfile();

  const handleOpen = () => setDialogOpen(true);
  const handleClose = () => setDialogOpen(false);

  const btnAndTitleLabel = t("CreateBid");

  let formValues = {
    ...WasteOfferBidSchema(startingPrice?.currencyCode).cast(),
    quantity: {
      quantity: wasteOfferQuantity?.quantity || 0,
      unit: wasteOfferQuantity?.unit || "",
    },
    sharedOffer: wasteOfferSharedOffer || false,
    usePercentage,
  };

  if (!wasteOfferId || !user?.uid || !userProfile) {
    return null;
  }

  const isEdit = !!wasteOfferBidId;

  if (!isEdit) {
    formValues = {
      ...formValues,
      contact: {
        email: userProfile.email,
        fullName: userProfile.fullName,
        phoneNumber: userProfile.phoneNumber,
      },
      company: userProfile.company,
    };
  }

  return (
    <>
      <Button onClick={handleOpen} variant="contained">
        {btnAndTitleLabel}
      </Button>
      <Dialog open={dialogOpen} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>
          <Typography>{btnAndTitleLabel}</Typography>
        </DialogTitle>
        <DialogContent style={{ paddingTop: 5 }}>
          <WasteOfferBidForm
            formValues={formValues}
            userId={user.uid}
            wasteOfferId={wasteOfferId}
            wasteOfferBidId={wasteOfferBidId}
            expiresAt={expiresAt}
            handleClose={handleClose}
            usePercentage={usePercentage}
            startingPrice={startingPrice}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};
