import { Grid, Typography } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { getNormalAddress } from "helpers/addresses";

export const LocationFieldOption = ({ option, ...rest }) => {
  const { title, label } = getNormalAddress(option);
  return (
    <li {...rest}>
      <Grid container alignItems="center">
        <Grid item>
          <LocationOnIcon />
        </Grid>
        <Grid item xs>
          {title ? (
            <Typography>
              <strong>{title}</strong>
            </Typography>
          ) : null}
          <Typography>{label}</Typography>
        </Grid>
      </Grid>
    </li>
  );
};
