import * as PDFRenderer from "@react-pdf/renderer";
import Kameron from "../../fonts/Kameron-Regular.ttf";
import KameronBold from "../../fonts/Kameron-Bold.ttf";
import moment from "moment";

const { Document, Page, StyleSheet, Text, View, Font } = PDFRenderer;

Font.register({
  family: "Kameron",
  fonts: [
    {
      src: Kameron,
    },
    {
      src: KameronBold,
      fontWeight: 700,
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    padding: "40px",
    fontFamily: "Kameron",
    fontSize: 11,
  },
  row: {
    flexDirection: "row",
  },
  th10: {
    backgroundColor: "#132E57",
    flexGrow: 10,
    height: 20,
    color: "#FFF",
    justifyContent: "center",
  },
  th1: {
    backgroundColor: "#132E57",
    flexGrow: 1,
    height: 20,
    color: "#FFF",
    justifyContent: "center",
  },
  th3: {
    backgroundColor: "#132E57",
    flexGrow: 3,
    height: 20,
    color: "#FFF",
    justifyContent: "center",
  },
  tr10: {
    backgroundColor: "#E0E0E0",
    flexGrow: 10,
    height: 20,
    justifyContent: "center",
  },
  tr20: {
    backgroundColor: "#E0E0E0",
    flexGrow: 10,
    height: 40,
    justifyContent: "center",
  },
  tr1: {
    backgroundColor: "#E0E0E0",
    flexGrow: 1,
    height: 20,
    justifyContent: "center",
  },
  tr23: {
    backgroundColor: "#E0E0E0",
    flexGrow: 1,
    height: 40,
    justifyContent: "center",
  },
  tr3: {
    backgroundColor: "#E0E0E0",
    flexGrow: 3,
    height: 20,
    justifyContent: "center",
  },
  tr33: {
    backgroundColor: "#E0E0E0",
    flexGrow: 3,
    height: 40,
    justifyContent: "center",
  },
  tr10w: {
    backgroundColor: "#FFF",
    flexGrow: 10,
    height: 20,
    justifyContent: "center",
  },
  tr1w: {
    backgroundColor: "#FFF",
    flexGrow: 1,
    height: 20,
    justifyContent: "center",
  },
  tr3w: {
    backgroundColor: "#FFF",
    flexGrow: 3,
    height: 20,
    justifyContent: "center",
  },
  tbr: {
    borderRight: "1px solid #000",
  },
  header: {
    fontSize: 11,
    color: "#FFF",
    marginRight: "auto",
    position: "absolute",
    paddingLeft: 5,
  },
  text: {
    fontSize: 11,
    color: "#000",
    paddingLeft: 5,
    position: "absolute",
  },
  comment: {
    padding: 5,
  },
});

const OfferInvoice = ({ offer, company, bid, commodity }) => {
  if (!offer || !bid) {
    return;
  }

  const includeVAT = company?.countryCode === "EE";
  const currency = offer.startingPrice.usePercentage
    ? "EUR"
    : offer?.startingPrice.currencyCode;

  const currencyValue = offer?.startingPrice?.currencyValue;

  const quantity = (bid.quantity ?? offer?.quantity?.amount) || 1;
  const quantityMetric = offer?.quantity?.unit ?? "";

  // const showPercentage = offer.startingPrice.usePercentage ? "%" : "";

  // const currencyCode = bid.currencyCode;

  const check = (val) => {
    return val ? val : "";
  };

  // const fullCurrencyCode = !showPercentage
  //   ? currencyCode
  //   : PERCENTAGES.find((p) => p.label === currencyCode)?.purchaseOrderLable ??
  //     currencyCode;

  const totalAmount = () => {
    let fee = 0;

    let multiplier = offer?.wasteCategory !== "wood" ? 0.03 : 0.05;

    if (
      company.name.toLocaleLowerCase() ===
      "Orkos Estonia OÜ".toLocaleLowerCase()
    ) {
      multiplier = 0.01;
    }

    if (offer.startingPrice.usePercentage) {
      multiplier = 0.03;
      fee = parseFloat((quantity * currencyValue * multiplier).toFixed(4));
      return fee;
    }

    const totalPrice = Math.abs(quantity * bid.amount);

    fee = totalPrice * multiplier;

    return Math.max(fee, 20);
  };

  const vatValue = () => {
    return includeVAT ? 0.22 * totalAmount() : 0;
  };

  const precise = (val) => parseFloat(val.toFixed(2));

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: "20px",
          }}
        >
          <View style={styles.row}>
            <Text
              style={{
                fontSize: 25,
                fontWeight: 700,
              }}
            >
              Tradey OÜ
            </Text>
          </View>
          <View>
            <Text
              style={{
                fontSize: 25,
                fontWeight: "700",
              }}
            >
              Invoice
            </Text>
          </View>
        </View>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <View>
            <Text>Tartu, Tedre 14, 50404</Text>
            <Text>martin@tradey.eu</Text>
            <Text>https://tradey.eu/</Text>
            <Text>Registry Code: 16216423</Text>
            <Text>VAT number: EE102386839</Text>
          </View>
          <View
            style={{
              ...styles.row,
              fontSize: 10,
            }}
          >
            <View
              style={{
                alignItems: "flex-end",
              }}
            >
              <Text>Date</Text>
              <Text>Inovice #</Text>
              <Text>Due Date</Text>
            </View>
            <View
              style={{
                marginLeft: 10,
                alignItems: "flex-end",
              }}
            >
              <Text>{moment().format("YYYY-MM-DD")}</Text>
              <Text>{`${moment().format("YYYYMMDD")}-${check(
                offer?.code
              )}`}</Text>
              <Text>{moment().add(14, "d").format("YYYY-MM-DD")}</Text>
            </View>
          </View>
        </View>
        <View
          style={{
            margin: "20px 0",
            width: "50%",
            border: "1px solid #000",
          }}
        >
          <View style={styles.row}>
            <View style={styles.th1}>
              <Text style={styles.header}>BILL TO</Text>
            </View>
          </View>
          <Text style={styles.comment}>{check(offer?.contact?.fullName)}</Text>
          <Text style={styles.comment}>{check(company?.name)}</Text>
          {company?.registryCode && (
            <Text style={styles.comment}>
              Registry Code: {check(company?.registryCode)}
            </Text>
          )}
          {company?.vatNumber && (
            <Text style={styles.comment}>
              VAT Number: {check(company?.vatNumber)}
            </Text>
          )}
        </View>
        <View style={{ border: "1px #000 solid" }}>
          <View style={styles.row}>
            <View style={styles.th10}>
              <Text style={styles.header}>Description</Text>
            </View>
            <View style={styles.tbr} />
            <View style={styles.th1}>
              <Text style={styles.header}>Qty</Text>
            </View>
            <View style={styles.tbr} />
            <View style={styles.th3}>
              <Text style={styles.header}>Unit Price</Text>
            </View>
            <View style={styles.tbr} />
            <View style={styles.th3}>
              <Text style={styles.header}>Amount</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.tr20}>
              <Text style={styles.text}>
                {`Tradey platform fee for ${check(
                  offer?.code
                )} (${quantity} ${quantityMetric} x ${commodity})`}
              </Text>
            </View>
            <View style={styles.tbr} />
            <View style={styles.tr23}>
              <Text style={styles.text}>{"1"}</Text>
            </View>
            <View style={styles.tbr} />
            <View style={styles.tr33}>
              <Text style={styles.text}>
                {`${precise(totalAmount())}` || "1"}
              </Text>
            </View>
            <View style={styles.tbr} />
            <View style={styles.tr33}>
              <Text style={styles.text}>
                {`${precise(totalAmount())}`} {currency}
              </Text>
            </View>
          </View>
        </View>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            margin: "20px 0",
          }}
        >
          <View
            style={{
              width: "50%",
              border: "1px solid #000",
            }}
          >
            <View style={styles.row}>
              <View style={styles.th1}>
                <Text style={styles.header}>COMMENTS</Text>
              </View>
            </View>
            <View>
              <Text style={styles.comment}>{"1. Payment due in 14 days"}</Text>
            </View>
            <View>
              <Text style={styles.comment}>
                {"2. Please note the invoice number in your payment method"}
              </Text>
            </View>
            <View>
              <Text style={styles.comment}>
                {
                  "Banking and wire transfer information may also be included here"
                }
              </Text>
            </View>
          </View>
          <View>
            <View
              style={{
                ...styles.row,
                justifyContent: "flex-end",
              }}
            >
              <View
                style={{
                  alignItems: "flex-end",
                }}
              >
                {includeVAT && (
                  <>
                    <Text>Subtotal</Text>
                    <Text>VAT 22%</Text>
                  </>
                )}
                <Text>Total</Text>
              </View>
              <View
                style={{
                  marginLeft: 10,
                  alignItems: "flex-end",
                }}
              >
                {includeVAT && (
                  <>
                    <Text>
                      {`${precise(totalAmount())}`} {currency}
                    </Text>
                    <Text>
                      {`${precise(vatValue())}`} {currency}
                    </Text>
                  </>
                )}
                <Text>
                  {`${precise(totalAmount() + vatValue())}`} {currency}
                </Text>
              </View>
            </View>
            <View style={{ alignItems: "flex-end", marginTop: "20px" }}>
              <Text>Swedbank AS</Text>
              <Text>EE262200221076616100</Text>
              <Text>SWIFT/BIC: HABAEE2X</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default OfferInvoice;
