import { Box, Divider, Stack, Typography } from "@mui/material";
import { GridContainer, GridItem } from "components/layout/Helpers";
import { formatLongDate } from "helpers/locale";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useCompanies } from "store/companies";
import { Locations } from "./Locations";

export const WasteOfferMainContents = ({ wasteOffer }) => {
  const { companies, fetchCompany } = useCompanies();

  const { t, i18n } = useTranslation();

  const formattedDate = formatLongDate(
    wasteOffer.expiresAt,
    i18n.language,
    "PPp"
  );

  useEffect(() => {
    if (wasteOffer.company) {
      fetchCompany(wasteOffer.company);
    }
  }, [fetchCompany, wasteOffer.company]);

  const company = wasteOffer.company ? companies.get(wasteOffer.company) : null;

  if (!wasteOffer) {
    return null;
  }

  const { highestBid, bidsCount, startingPrice } = wasteOffer;

  const { usePercentage } = startingPrice;

  const { amount, currencyCode } = highestBid
    ? highestBid
    : wasteOffer.startingPrice;

  const priceAmountWithCurrency = `${amount}${
    usePercentage ? "%" : ""
  } ${currencyCode}`;

  return (
    <GridContainer>
      <GridItem>
        <Typography variant="body1">
          <strong>{t("SharedOffer")}</strong>
          <br />
          {(wasteOffer?.sharedOffer || false).toString()}
        </Typography>
        <Box my={1}>
          <Divider />
        </Box>
      </GridItem>
      <GridItem>
        <Typography variant="body1">
          <strong>{t("Description")}</strong>
          <br />
          {wasteOffer.description}
        </Typography>
        <Box my={1}>
          <Divider />
        </Box>
      </GridItem>
      <GridItem>
        <Typography variant="body1" component="div">
          <strong>
            {t(
              wasteOffer.locations?.length > 1
                ? "LocationsAndQuantities"
                : "Common.Location"
            )}
          </strong>
          <br />
          <Locations wasteOffer={wasteOffer} normal />
        </Typography>
        <Box my={1}>
          <Divider />
        </Box>
      </GridItem>
      <GridItem>
        <Stack direction="row" display="flex" justifyContent="space-between">
          <Typography variant="body1">
            <strong>{t("ExpiresAt")}</strong>
            <br />
            {formattedDate}
          </Typography>
          <Typography variant="body1" align="right">
            <strong>
              {t(highestBid ? "CurrentPrice" : "StartingPrice.Amount")}
            </strong>
            <br />
            {priceAmountWithCurrency}
          </Typography>
        </Stack>
        <Box my={1}>
          <Divider />
        </Box>
      </GridItem>
      <GridItem>
        <Stack direction="row" display="flex" justifyContent="space-between">
          <Typography variant="body1">
            <strong>{t("Contact.Title")}</strong>
            <br />
            {wasteOffer.contact.fullName}
            <br />
            {wasteOffer.contact.email}
            <br />
            {wasteOffer.contact.phoneNumber}
            <br />
          </Typography>
          {company ? (
            <Typography variant="body1" align="right">
              <strong>{t("Company.Title")}</strong>
              <br />
              {company.name} <br /> {company.registryCode}
            </Typography>
          ) : null}
        </Stack>
        {bidsCount > 0 ? (
          <Box my={1}>
            <Divider />
          </Box>
        ) : null}
      </GridItem>
      {wasteOffer?.startingPrice?.delivery && (
        <GridItem>
          <Stack direction="row" display="flex" justifyContent="space-between">
            <Typography variant="body1">
              <strong>{t("StartingPrice.Delivery")}</strong>
              <br />
              {wasteOffer?.startingPrice?.delivery}
            </Typography>
          </Stack>
          <Box my={1}>
            <Divider />
          </Box>
        </GridItem>
      )}
      {bidsCount > 0 ? (
        <GridItem>
          <Typography>{t("BidsCount", { count: bidsCount })}</Typography>
        </GridItem>
      ) : null}
    </GridContainer>
  );
};
